import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import axios from ".././../../../../axiosinstance";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import swal from "sweetalert";

export default function ProfileEditPopup({ onClose, updateName }) {
  const [editedName, setEditedName] = useState(
    localStorage.getItem("mmu-auth")
      ? JSON.parse(localStorage.getItem("mmu-auth")).name
      : ""
  );

  const auth = JSON.parse(localStorage.getItem("mmu-auth"));
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    onClose();
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      ...auth,
      name: editedName,
    };
    try {
      const response = await axios.put(
        "login-service-mongo/api/loginservice/v2/update",
        data
      );
      updateName(editedName);
      setIsLoading(false);
      handleClose();
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      swal("","Something went wrong.","error");
      console.error("API error:", error);
    }
  }

  return (
    <div>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>Edit Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" size="small" onClick={handleClose}>
            Cancel
          </MDButton>

          <MDButton
            variant="gradient"
            color="dark"
            size="small"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? <MoonLoader color="#f2fefa" size={16} /> : "Submit"}
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
