import * as Yup from "yup";

import checkout from "layouts/pages/slotcreation/new slotcreation/schemas/form";
const {
  formField: {
    count,
    slot,
   
    
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [count.name]: Yup.string().required(count.errorMsg),
    [slot.name]: Yup.string().required(slot.errorMsg),
   //  [date.name]: Yup.string().required(date.errorMsg),
    // [startTime.name]: Yup.string().required(startTime.errorMsg),
     //[endTime.name]: Yup.string().required(endTime.errorMsg),
    // [mapware.name]: Yup.string().required(mapware.errorMsg),
  }),
];

export default validations;
