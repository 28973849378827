import { createSlice } from "@reduxjs/toolkit";
import { createAddress, deleteAddress, getAddress, updateAddress } from "../service/Address";




const initialState = {

  address: [],
  loading: false,
  error: null,
};

export const addressCrud = createSlice({
  name: "addressCrud",
  initialState,
  extraReducers: {
    [createAddress.pending]: (state) => {
      state.loading = true;
    },
    [createAddress.fulfilled]: (state, action) => {
      console.log("state",state.address)
      state.loading = false;
      state.address.push(action.payload.data.dto);
    },
    [createAddress.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getAddress.pending]: (state) => {
      state.loading = true;
    },
    [getAddress.fulfilled]: (state, action) => {
      state.loading = false;
      state.address = action.payload.data.dtoList;
    },
    [getAddress.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteAddress.pending]: (state) => {
      state.loading = true;
    },
    [deleteAddress.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.address = state.address.filter((address) => address.id !== id);
      }
    },
    [deleteAddress.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateAddress.pending]: (state) => {
      state.loading = true;
    },
    [updateAddress.fulfilled]: (state, action) => {
      state.loading = false;
    
      const index = state.address.findIndex(address => address.id === action.payload.data.dto.id)
      state.address[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateAddress.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    
  },
});

export default addressCrud.reducer;
