import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: {
    serialNumber,
    brand,
    modal,
    product,
  
   //puchase detail
  dateofPurchase,
  purchaseofRetailer,
    document,

   //personal Deatil
   title,
   firstname,
   //contact Detail
   email,
   mobilenumber,
   alternatephonenumber
  },
} = checkout;

const validations = [
  
  Yup.object().shape({
    [serialNumber.name]: Yup.string().required(serialNumber.errorMsg),
    // brand: listSize === 0 ? Yup.string().required("Brand is required") : Yup.string(),
    // product: listSize === 0 ? Yup.string().required("Product is required") : Yup.string(),
    // modal: listSize === 0 ? Yup.string().required("Modal is required") : Yup.string(),
    [brand.name]: Yup.string().required(brand.errorMsg),
    [product.name]: Yup.string().required(product.errorMsg),
    [modal.name]: Yup.string().required(modal.errorMsg),
 
   
    
  }),
  //purchase detail
  Yup.object().shape({
  
   
    [dateofPurchase.name]: Yup.string()
    .required(dateofPurchase.errorMsg)
    .test(
      "is-future-date",
      "Date cannot be in the future",
      function (value) {
        // Parse the input date string into a Date object
        const inputDate = new Date(value);
        
        // Get the current date
        const currentDate = new Date();
        
        // Compare the input date with the current date
        return inputDate <= currentDate;
      }
    ),
    [purchaseofRetailer.name]: Yup.string().required(purchaseofRetailer.errorMsg),

    [document.name]: Yup.mixed()
    .required(document.errorMsg)
    .test("fileType", "Invalid file type", (value) => {
      if (!value) return true; // Skip validation if no file is selected
      return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
    })
    .test("fileSize", "File is too large", (value) => {
      if (!value) return true; // Skip validation if no file is selected
      return value.size <= 5242880; // 5MB maximum file size
    }),
    

  }),
  //personal detail
  Yup.object().shape({
    [title.name]: Yup.string().required(title.errorMsg),
    [firstname.name]: Yup.string().required(firstname.errorMsg),
  }),
  //contact detail
  Yup.object().shape({
  [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
  [mobilenumber.name]: Yup.string()
  .required("Mobile number is required")
  .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
  
  [alternatephonenumber.name]: Yup.string()
  .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
  }),
  //  Yup.object().shape({
  //   [address.name]: Yup.string().required(address.errorMsg),
   
  // }),
  
];

export default validations;
    
  

   
