
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create delar


export const getDelar = createAsyncThunk("getDelar", async (data, {rejectWithValue}) => {
 

  try {
    const response = await axios.get(
      `dealer-service/api/Dealer/v2/get`,
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});





