// ProductDetail.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import { BarLoader } from "react-spinners";
import { useFormikContext } from "formik";
import axios from "axios";
import FormField from "../FormField";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { getBrand } from "app/createBrand/service/Brand";
import { getProduct } from "app/productMaster/service/Product";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getModal } from "app/modalMaster/service/Modal";
import MDButton from "components/MDButton";
import { getserailnumber } from "app/productRegistration/serialreducer/serialapi";

import { MoonLoader } from "react-spinners";
function ProductDetail({ formData }) {
  const { setFieldValue, setFieldError } = useFormikContext();
  const [color] = useState("#344767");
  const dispatch = useDispatch();
  const { brand, loading } = useSelector((state) => state.brand);
  const { product } = useSelector((state) => state.product);
  const { modal } = useSelector((state) => state.modal);

  const { formField, values, errors, touched } = formData;
  const { serialNumber } = formField;

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productOptions, setProductOptions] = useState([]);
  const [modalOptions, setModalOptions] = useState([]);
  const [serialvalue, setSerialvalue] = useState("");
  const [listSize, setListsize] = useState(
    values.listSize === 1 ? values.listSize : 0
  );
  const [serialdata, setSerialData] = useState(
    values.serialdata && Object.keys(values.serialdata).length > 0 ? values.serialdata : {}
  );  
  const [isloading, setLoading] = useState(false);
  const [showHide, setShowHide] = useState(false || values.hide);
  const handleChangeBrand = (event) => {
    values.product = "";
    values.modal = "";
    const { value } = event.target;
    const selectedBrand = JSON.parse(value);

    setFieldValue("brand", value);

    // Filter product options based on the selected brand
    const result = product.filter(
      (productItem) =>
        productItem.parenthierarchicalcode === selectedBrand.hierarchicalcode
    );
    setProductOptions(result);
  };

  const handleChangeProduct = (event) => {
    values.modal = "";
    const { value } = event.target;
    const selectedProduct = JSON.parse(value);

    setFieldValue("product", value);

    const result = modal.filter(
      (modalItem) =>
        modalItem.parenthierarchicalcode === selectedProduct.hierarchicalcode
    );
    setModalOptions(result);
  };

  const productFilter = () => {
    if (values.brand !== "") {
      const branddata = JSON.parse(values.brand);
      const result = product.filter(
        (product) =>
          product.parenthierarchicalcode === branddata.hierarchicalcode
      );
      setProductOptions(result);
    }
  };

  const modalfilter = () => {
    if (values.product !== "") {
      const productData = JSON.parse(values.product);
      const result = modal.filter(
        (modal) => modal.parenthierarchicalcode === productData.hierarchicalcode
      );
      setModalOptions(result);
    }
  };

  const handleSerialNumberChange = (event) => {
    const { value } = event.target;
    setSerialvalue(value);
    setFieldValue("serialNumber", value);
  };
  async function getData(item) {
    setLoading(true); // Show loader
    const response = await dispatch(getserailnumber(item));

    if (response.payload.data.listSize === 1) {
      setListsize(response.payload?.data?.listSize);

      setSerialData(response.payload?.data.dtoList[0]);
      values.serialdata = response.payload?.data.dtoList[0];
      values.brand = JSON.stringify(response.payload?.data.dtoList[0].brand);
      values.listSize = 1;
      values.recstatus = "APPROVED";

      values.product = JSON.stringify(
        response.payload?.data.dtoList[0].product
      );
      values.modal = JSON.stringify(response.payload?.data.dtoList[0].model);
      setLoading(false); 
    } else {
      swal("Oops!", "This Serial Number is not Registered.", "warning");
      setListsize(0);
      setShowHide(true)
      values.hide=true

      setSerialData("");
      values.serialdata = "";
      values.brand = "";
      values.listSize = 0;
      values.recstatus = "";

      values.product = "";
      values.modal = "";
      setLoading(false);
    }
   
  }

 
  useEffect(() => {
    dispatch(getBrand());
    dispatch(getProduct());
    dispatch(getModal());

    if (values.product !== "") {
      productFilter();
    }
    if (values.modal !== "") {
      modalfilter();
    }
  }, []);

  // ... (previous code)

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Product Detail</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
            <div style={{width: window.innerWidth <= 700 ? "65%" : "auto"}}>
              <FormField
                type={serialNumber.type}
                label=<span>{serialNumber.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={serialNumber.name}
                value={values.serialNumber}
                placeholder={serialNumber.placeholder}
                onChange={handleSerialNumberChange}
                error={errors.serialNumber && touched.serialNumber}
                success={values.serialNumber.length > 0 && !errors.serialNumber}
              />
              </div>
            </Grid>
            <Grid item xs={12} sm={2}>
            <div style={{ 
                position: window.innerWidth <= 700 ? "absolute" : "absolute", 
                top: window.innerWidth <= 700 ? "10.1rem" : "9.3rem", 
                left: window.innerWidth <= 700 ? "67%" : "34%"
              }}>
              {/* <MDButton   
             variant="gradient"
              color="info" 
              size="small"
              onClick={()=>getData(serialvalue)}>SERACH</MDButton> */}
              <MDButton
                size="small"
                variant="gradient"
                color="dark"
                onClick={() => {
                  getData(serialvalue);
                }}
              >
                {isloading ? (
                  <MoonLoader color="#f2fefa" size={16} />
                ) : (
                  "SEARCH"
                )}
              </MDButton>
              </div>
            </Grid>
            {listSize === 1 && ( // Conditionally render this section
              <>
                <Grid item xs={12} sm={6}>
                <div style={{marginTop: window.innerWidth <= 700 ? "-35px" : "auto"}}>
                  <FormField
                    label="Brand"
                    value={serialdata?.brand?.name || ""}
                    name="x"
                  />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                <div style={{marginTop: window.innerWidth <= 700 ? "-10px" : "auto"}}>
                  <FormField
                    label="Product"
                    value={serialdata?.product?.name || ""}
                    name="y"
                  />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                <div style={{marginTop: window.innerWidth <= 700 ? "-10px" : "auto"}}>
                  <FormField
                    label="Model"
                    value={serialdata?.model?.name || ""}
                    name="z"
                  />
                  </div>
                </Grid>
              </>
            )}

            {showHide && listSize === 0 && ( // Conditionally render this section
             <>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={!!errors.brand && touched.brand}
                  >
                    <InputLabel htmlFor="brand" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Select Brand <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}>*</sup></InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="brand"
                      id="brand"
                      value={values.brand}
                      onChange={handleChangeBrand}
                    >
                    
                     <option value="" disabled></option>
                      {brand.map((obj) => (
                        <option key={obj.id} value={JSON.stringify(obj)}>
                          {obj.name}
                        </option>
                      ))}
                    </Select>
                    {errors.brand && touched.brand && (
                      <FormHelperText error style={{marginLeft:"0px"}}>{errors.brand}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={!!errors.product && touched.product}
                  >
                    <InputLabel htmlFor="product" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Select Product <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}>*</sup></InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="product"
                      id="product"
                      value={values.product}
                      onChange={handleChangeProduct}
                    >
                      <option value=""></option>
                      {productOptions.map((obj) => (
                        <option key={obj.id} value={JSON.stringify(obj)}>
                          {obj.name}
                        </option>
                      ))}
                    </Select>
                    {errors.product && touched.product && (
                      <FormHelperText error style={{marginLeft:"0px"}}>{errors.product}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={!!errors.modal && touched.modal}
                  >
                    <InputLabel htmlFor="brand" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Select Model <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}>*</sup></InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="modal"
                      id="modal"
                      value={values.modal}
                      onChange={(event) => {
                        const { value } = event.target;
                        setFieldValue("modal", value);
                      }}
                    >
                      <option value=""></option>
                      {modalOptions.map((obj) => (
                        <option key={obj.id} value={JSON.stringify(obj)}>
                          {obj.name}
                        </option>
                      ))}
                    </Select>
                    {errors.modal && touched.modal && (
                      <FormHelperText error style={{marginLeft:"0px"}}>{errors.modal}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

ProductDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ProductDetail;
