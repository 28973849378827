// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// otpinfo page components

import FormField from "../FormField";

import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";
import { Link } from "react-router-dom";
function OtpInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  

  const { formField, values, errors, touched } = formData;

  const {
otp
    
  } = formField;
  var {
    
    otp: otpV,
    
  } = values;


  const [counter, setCounter] = React.useState(20);
  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

    function Reload() {

    alert("otp will be send on your updated mobile num")
    setCounter(20);
   


  }
  return (
    <MDBox>
      
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
         
      
           
        <h5 style={{ marginTop: "-12px" }}>Please Enter the verification code </h5>
           <FormField
              type={otp.type}
              label={otp.label}
              name={otp.name}
              value={otpV}
              placeholder={otp.placeholder}
              error={errors.otp && touched.otp}
              success={otpV.length > 0 && !errors.otp}
            />
           
         
           <MDBox mx={9} >
                <MDTypography

                  variant="button"
                  color="error"
                  fontWeight="medium"
                  textGradient
                >
                  Resend code in <span>00:{counter}</span>
                </MDTypography>
              </MDBox>
         
              <MDBox mx={14} >
                <Link to={{}}>
                  <MDTypography
                    onClick={Reload}
                    
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Resend Otp
                  </MDTypography>
                </Link>





              </MDBox>
        
            
           
           
         
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for OtpInfo
OtpInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default OtpInfo;
