// prop-type is a library for typechecking of props
import PropTypes, { func } from "prop-types";
import * as React from "react";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import DataTable from "examples/Tables/DataTable";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components

import FormField from "layouts/pages/slotcreation/new slotcreation/componets/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useState, useEffect } from "react";

import { BarLoader } from "react-spinners";
import { FaRegTimesCircle } from "react-icons/fa";

import { Link, useNavigate } from "react-router-dom";
import axios from "../../../../../../axiosinstance"

function SlotInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");


  const [AssignEquipmentvalue, setAssignEquipmentvalue] = React.useState(null);


  const [quantity, setQuantity] = useState("0");
  const [assetNo, setAssetNo] = useState("");
  let [QuantityId, setQuantityId] = useState("0");
  const [QTY, setQTY] = useState("");
  const [asset, setAsset] = useState("");


  const [addresslist, setAddresslist] = useState("");
  const [slotvalue, setSlotvalue] = React.useState(null);
  const [slotList, setSlotList] = React.useState("");
  const [addressvalue, setaddressvalue] = useState(null);

  const [open, setOpen] = React.useState(false);

  const [inputData, setInputData] = useState({
    sapcode: "",
    name: "",
    inputQty: "",
    inputSerialNo: "",
    action: "",
  });
  const mmuObj = {
    sapcode: "",
    name: "",
    inputQty: "",
    inputSerialNo: "",
    action: "",
  };
  const equipmentModel = {
    assetno: "",
    item: "",
    quantity: "",
  };

  const [equipment, setEquipment] = useState();
  const [equipmentList, setEquipmentList] = useState([]);
  const dataObj = {
    columns: [
      { Header: "start date", accessor: "fstartedate", width: "25%" },
      { Header: "end date", accessor: "fenddate", width: "25%" },
      // { Header: "Start time", accessor: "starttime", width: "30%" },
      // { Header: "end time", accessor: "endtime", width: "12%" },
      { Header: "action", accessor: "action", width: "25%" },

    ],
    rows: [],
  };

  const [tableData, setTableData] = useState(dataObj);

  const [givenQty, setGivenQty] = useState();
  const [select, setSelect] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);
  const [profileId, setProfileId] = useState(JSON.parse(localStorage.getItem("mmu-auth")).profileid)
  const [err, setErr] = useState("");
  const { formField, values, errors, touched } = formData;
  // ADRESSS


  const defaultPropsAddress = {
    options: !addresslist ? [{ name: "Loading...", id: 0 }] : addresslist,
    getOptionLabel: (option) => option.city + " " + option.district + " " + option.country,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  // slot

  const defaultPropsslot = {
    options: !slotList
      ? [
        { name: "15 min", code: "X" },
        { name: "20 min", code: "y" },
        { name: "30 min", code: "z" },
        { name: "45 min", code: "a" },
        { name: "60 min", code: "b" },
      ]
      : slotList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };
  function addDateWithTime(date, time) {
    // let time = '05:17 pm';
    let splittedTime = time.split(' ');
    let [hours, minutes] = splittedTime[0].split(':');
    let isAm = splittedTime[1] === 'am'; // in europe we don't use am, the hours goes from 0 to 24

    // var date = new Date(); // now
    console.log('before ' + date);

    let hourToAdd = 0;
    if (+hours === 12) {
      if (isAm) {
        hourToAdd = - 12; // at midnight we dont add +12 nor +0, we want 12am to be 0 hours
      } else {
        hourToAdd = 0;
      }
    } else {
      hourToAdd = isAm ? 0 : 12;
    }

    // use setHours to set time
    date.setHours(+hours + hourToAdd, +minutes, 0, 0);
    console.log('after ' + date);
    return date;
  }
  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }


  // var updateTime=tConvert("17:20")
  // console.log("156",updateTime)
  //  var date2=addDateWithTime(new Date(),updateTime)
  //   console.log("137",date2)
  const {
    address,
    slot,
    count,
    date,
    startTime,
    endTime
  } = formField;
  var {
    count: countV,
    slot: slotV,
    date: dateV,
    address: addressV,


    startTime: startTimeV,
    endTime: endTimeV,
  } = values;
  // values.slotperpatient=""
  const removeItem = function (id) {

    const objWithIdIndex = tableData.rows.findIndex((obj) => obj.addressobject.id === id);
    tableData.rows.splice(objWithIdIndex, 1);
    setTableData(tableData);
    values.EquipmentList = tableData.rows;
  };
  function dateFormater(date) {
    return new Date(date).toLocaleDateString() + " " + tConvert(new Date(date).toLocaleTimeString());

  }
  const addData = (data) => {
    console.log("336", data);
    var updateStartTime = tConvert(data.startTime)
    console.log("156", updateStartTime)
    var startDate = addDateWithTime(new Date(data.date), updateStartTime)
    var updateEndTime = tConvert(data.endTime)
    var endDate = addDateWithTime(new Date(data.date), updateEndTime)
    console.log("137", startDate)
    console.log("189", endDate)
    const slotData = {

      fstartedate: dateFormater(startDate),
      fenddate: dateFormater(endDate),
      actualnoofattendees: "",

      addressobject: data.address,

      averagetotalnoofattendees: "",
      averageusercount: data.count,
      classname: "",

      createdby: "ANKIT",


      duration: "",
      durationuom: "",
      enddate: endDate,
      id: null,





      profileid: profileId,
      recstatus: "OPEN",
      requiredattendees: "",
      resourcecode: "",
      slotsize: data.slot,
      slotuom: "",
      startdate: startDate,
      status: "ACTIVE",
      syncstatus: "synced",
      tempid: "",
      title: "",
      totalnoofattendees: ""
    };
    console.log("368", slotData);

    slotData.action = (
      <span>

        <Link to={{}}>
          <FaRegTimesCircle
            style={{
              color: "#f44336",
              width: "25px",
              height: "25px",
              cursor: "pointer",

            }}
            onClick={() => removeItem(slotData.addressobject.id)}
          />
        </Link>

      </span>
    );



    let filter = tableData.rows.filter(
      (a) => a.addressobject.id == slotData.addressobject.id
    );

    if (filter.length < 1) {

      tableData.rows.push(slotData);
      dataObj.rows = tableData.rows;

      setTableData(dataObj);
      values.slotDataList = tableData.rows;
    }
    console.log("150", tableData)



  };

  async function getData(profileId) {
    await axios
      .get(
        `address-service-mongo/api/address/v2/get?profileId=${profileId}`
      )
      .then((response) => {
        setAddresslist(response.data.data.dtoList);

        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });



  }

  useEffect(() => {
    getData(profileId);
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Slot Creation</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsAddress}
                value={addressvalue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setaddressvalue(newValue);



                  console.log("294", newValue)
                  values.address = newValue;
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}

                    variant="standard"
                    label={address.label}
                  />
                )}
              />


            </Grid>

            <Grid item xs={12} sm={6}>
              {/* <Autocomplete
                {...defaultPropsslot}
                value={slotvalue}
                onChange={(event, newValue) => {
                  // handleChange(event);
                  console.log("errortouch",touched.slot)
                  console.log("error",errors.slot)
               console.log("282",errors.slot && touched.slot)  
               console.log("283",slotV.length > 0 && !errors.slot) 
                  setSlotvalue(newValue);
                  if(newValue==null){
                    values.slot = "";
                  values.slotperpatient="";
                  }
                  else{
                    values.slot = newValue.name;
                  values.slotperpatient=newValue.name
                  }
                  console.log("295", values)
                 
                }}
                renderInput={(params) => (
                  <FormField
                    {...params}
                   
                    type={slot.type}
                    label={slot.label}
                    name={slot.name}
                    value={slotV}
                    placeholder={slot.placeholder}
                    error={errors.slot && touched.slot}
                     success={slotV.length > 0 && !errors.slot}
                   
                   
                  />
                )}
                    
              /> */}
              <Autocomplete
                {...defaultPropsslot}
                value={slotvalue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setSlotvalue(newValue);



                  console.log("294", newValue)
                  values.slot = newValue?.name;
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}

                    variant="standard"
                    label={slot.label}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={count.type}
                label={count.label}
                name={count.name}
                value={countV}
                placeholder={count.placeholder}
                error={errors.count && touched.count}
                success={countV.length > 0 && !errors.count}
              />
            </Grid>

          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <MDTypography variant="button" color="text">
                Start Date
              </MDTypography>
              <FormField
                style={{ marginTop: "-9px" }}
                type={date.type}
                label={date.label}
                name={date.name}
                value={dateV}
                placeholder={date.placeholder}
                error={errors.date && touched.date}
                success={dateV.length > 0 && !errors.date}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormField
                type={startTime.type}
                label={startTime.label}
                name={startTime.name}
                value={startTimeV}
                placeholder={startTime.placeholder}
                error={errors.startTime && touched.startTime}
                success={startTimeV.length > 0 && !errors.startTime}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type={endTime.type}
                label={endTime.label}
                name={endTime.name}
                value={endTimeV}
                placeholder={endTime.placeholder}
                error={errors.endTime && touched.endTime}
                success={endTimeV.length > 0 && !errors.endTime}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                style={{ marginTop: "10px" }}
                onClick={() => addData(values)}
              >
                Add
              </MDButton>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",
              marginTop: "25px",
            }}
          >
            <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              table={tableData}
            />
          </Grid>
        </MDBox>
      )}

    </MDBox>
  );
}

// typechecking props for SlotInfo
SlotInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default SlotInfo;
