
import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";

// NewUser page components
import QualificationInfo from "layouts/pages/qualification/new-qualification/components/QualificationInfo"

import CoverLayout from "layouts/authentication/components/CoverLayout";
// image
import bgImage from "assets/images/bg-pricing.jpg";


// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/qualification/new-qualification/schemas/validations";
import form from "layouts/pages/qualification/new-qualification/schemas/form";
import initialValues from "layouts/pages/qualification/new-qualification/schemas/initialValues";
import axios from "../../../../axiosinstance"
import { Link, useNavigate } from "react-router-dom";

function getSteps() {
  return ["Qualification Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <QualificationInfo formData={formData} />;

    default:
      return null;
  }
}

function QualificationForm() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    console.log("hii", values);
    for (let i = 0; i < values.qualificationDataList.length; i++) {
      delete values.qualificationDataList[i].action
    }
    console.log("70",values.qualificationDataList)
  
    await axios
      .post(

        'qualification-service-mongo/api/qualification/v2/createList',
        values.qualificationDataList
      )

      .then((response) => {
        console.log("gfgfgf", response);
        swal("Good job!", "your Qualification has been submit!", "success");
      })
      .catch((error) => {
        console.log(error);
        swal("oops!", "something wents wrong");
      });


    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    console.log(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "70%", mt: -5 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%",width:"750px",marginLeft:"-208px" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-1}
                        p={3}
                        mb={1}
                        textAlign="center"
                      >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
                          Qualification Information
                        </MDTypography>
                        <MDTypography display="block" variant="button" color="white" my={1}>
                          Enter Qualification detail
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                            onClick={() => {
                              console.log("submit");
                            }}
                          >
                            submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}




export default QualificationForm;

