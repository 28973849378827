// api.js

import axios from "../../../../../axiosinstance";


 
  export const Newnumberchangeotp = async (formData) => {
      console.log("formData",formData)
    
     
    try {
      const response = await axios.post(`otps-service/api/Otps/v1/create`, formData);
      return response.data;
    } catch (error) {
      throw error;
    }
    };

  
  
//forgot passwor otp
export const conformnewnumberchangeotp = async (userid, otp) => {

   
   
  try {
    const response = await axios.post(`otps-service/api/Otps/v1/confirm?msgType=DIRECT&otp=${otp}&userid=${userid}`);
    return response.data;
  } catch (error) {
    throw error;
  }
  };
