// api.js

import axios from "../../../axiosinstance";



export const makeApiRequest = async (formData) => {
    console.log("formData",formData)
   
  try {
    const response = await axios.post(`login-service-mongo/api/loginservice/v2/create`, formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
