
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../../axiosinstance";
//create address


export const getAddress = createAsyncThunk("getAddress", async (profileid, {rejectWithValue}) => {
 

  try {
    const response = await axios.get(
     
      `address-service-mongo/api/address/v2/get?profileid=${profileid}&recstatus=APPROVED`
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});





