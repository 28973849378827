import checkout from "../schemas/form";
// initialValues.js

const fullName = JSON.parse(localStorage.getItem("mmu-auth"))?.name;
console.log(fullName);

var lastName = "";
// if (fullName !== undefined) {
 const  name = localStorage.getItem("mmu-auth") ? fullName.split(" "): [];
  
  for (let i = 1; i < name.length; i++) {
    if (i == 1) {
      lastName = lastName + name[i];
    } else {
      lastName = lastName + " " + name[i];
    }
  }
// }
const {
  formField: {
    serialNumber,
    brand,
    product,
    modal,

    //puchasedetail
    purchaseofRetailer,
    dateofPurchase,
    document,
    //personal Detail
    title,
    firstname,
    lastname,
    //personal Detail
    email,
    mobilenumber,
    alternatephonenumber,
    address,
  },
} = checkout;

const initialValues = {
  [serialNumber.name]: "",
  [brand.name]: "",
  [product.name]: "",
  [modal.name]: "",

  //purchase detail
  [dateofPurchase.name]: "",
  [purchaseofRetailer.name]: "",
  [document.name]: null,
  //personal Detail
  [title.name]: localStorage.getItem("mmu-auth") ? JSON.parse(localStorage.getItem("mmu-auth")).title : "",
  [firstname.name]: name.length > 0 ? name[0] : "",
  [lastname.name]: lastName,

  //contact Detail
  [mobilenumber.name]: localStorage.getItem("mmu-auth") ? JSON.parse(localStorage.getItem("mmu-auth"))?.mobileno : "",
  [email.name]: localStorage.getItem("mmu-auth") ? JSON.parse(localStorage.getItem("mmu-auth"))?.emailid : "",
  [alternatephonenumber.name]: "",
  // addresss
  [address.name]: "",
};

export default initialValues;
