// import React, { useState, useEffect } from "react";
// import { Formik, Field, Form, ErrorMessage } from "formik";
// import * as Yup from "yup"; // Import Yup for validation
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import TextField from "@mui/material/TextField";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import { getBrand } from "app/createBrand/service/Brand";
// import { useDispatch, useSelector } from "react-redux";
// import { getProduct } from "app/productMaster/service/Product";
// import MDBox from "components/MDBox";
// import { Grid } from "@mui/material";
// import Card from "@mui/material/Card";
// import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
// import Footer from "examples/Footer";
// import { createModal } from "./service/Modal";
// import { useNavigate } from "react-router-dom";

// // Newlab layout schemas for form and form feilds

// const validationSchema = Yup.object().shape({
//   brand: Yup.string().required("Brand is required"),
//   product: Yup.string().required("Product is required"),
//   extendedwarranty: Yup.string().required("extendedwarranty is required"),
//   warrantyapplicable: Yup.string().required("warrantyapplicable is required"),
//   name: Yup.string().required("name is required"),
//   warrantymonth: Yup.string().required("warrant month is required"),
// });

// function ModalMaster() {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { brand, loading } = useSelector((state) => state.brand);
//   const { product } = useSelector((state) => state.product);
//   const [productOptions, setProductOptions] = useState([]);

//   useEffect(() => {
//     // Fetch brand data when the component mounts
//     dispatch(getBrand());
//     dispatch(getProduct());
//   }, [dispatch]);

//   const fetchProductOptions = async (selectedBrand) => {
//     console.log(selectedBrand);

//     // Filter districts based on selectedBrand
//     const result = product.filter(
//       (productItem) => productItem.parenthierarchicalcode === selectedBrand
//     );
//     console.log(result);

//     // Set the district options based on the result
//     setProductOptions(result);
//   };

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />

//       <MDBox py={3} mb={20} height="65vh">
//         <Grid
//           container
//           justifyContent="center"
//           alignItems="center"
//           sx={{ height: "100%", mt: 8 }}
//         >
//           <Grid item xs={12} lg={8}>
//             <Formik
//               initialValues={{
//                 product: "",
//                 brand: "",
//                 extendedwarranty: "",
//                 name: "",
//                 warrantyapplicable: "",
//                 warrantymonth: "",
//               }}
//               validationSchema={validationSchema} // Apply the validation schema
//               onSubmit={(values, action) => {
//                 console.log(values);
//                 // Handle form submission here
//                 const requestData = {
//                   desc: values.description,
//                   name: values.name,
//                 iswarranty: values.warrantyapplicable,
//                    isextendedwarranty: values.extendedwarranty,
//                    parenthierarchicalcode: values.product,
//                   hierarchicalcode: values.product,
//                    warrantyinmonth: values.warrantymonth,
//                   recstatus: "OPEN",
//                   resourcecode: "JAINA",
//                   status: "Active",
//                   syncstatus: "SYNCED",
//                   type: "MODAL",
//                 };
//                 dispatch(createModal(requestData));
//                 navigate("/app/modallist/modal-list");
//                 action.resetForm();
//               }}
//             >
//               {({ values, setFieldValue, errors, touched }) => (

//                 <Form>
//                   <Card sx={{ height: "100%" }}>
//                     <MDBox mx={2} mt={-3}>
//                       <MDBox
//                         variant="gradient"
//                         bgColor="info"
//                         borderRadius="lg"
//                         coloredShadow="success"
//                         mx={2}
//                         mt={-3}
//                         p={2}
//                         mb={-2}
//                         textAlign="center"
//                       >
//                         <MDTypography
//                           variant="h4"
//                           fontWeight="small"
//                           color="white"
//                           mt={1}
//                         >
//                           Modal Master
//                         </MDTypography>
//                       </MDBox>
//                     </MDBox>
//                     <MDBox p={3}>
//                       <MDBox>
//                         <Grid container spacing={3}>
//                           <Grid item xs={12} sm={6}>
//                             <FormControl fullWidth>
//                               <InputLabel htmlFor="brand">
//                                 Select Brand
//                               </InputLabel>
//                               <Select
//                                 native
//                                 variant="standard"
//                                 style={{ width: "100%" }}
//                                 name="brand"
//                                 id="brand"
//                                 value={values.brand}
//                                 onChange={(e) => {
//                                   const selectedBrand = e.target.value;
//                                   setFieldValue("brand", selectedBrand);
//                                   fetchProductOptions(selectedBrand);
//                                 }}
//                                 error={touched.brand && Boolean(errors.brand)} // Add validation error handling
//                               >
//                                 <option value=""></option>
//                                 {brand.map((option) => (
//                                   <option
//                                     key={option.id}
//                                     value={option.hierarchicalcode}
//                                   >
//                                     {option.name}
//                                   </option>
//                                 ))}
//                               </Select>
//                               <ErrorMessage
//                                 name="brand"
//                                 component="div"
//                                 style={{ color: "red", fontSize: "12px" }}
//                               />
//                             </FormControl>
//                           </Grid>
//                           <Grid item xs={12} sm={6}>
//                             <FormControl fullWidth>
//                               <InputLabel htmlFor="district">
//                                 Select Product
//                               </InputLabel>
//                               <Select
//                                 native
//                                 variant="standard"
//                                 style={{ width: "100%" }}
//                                 name="product"
//                                 id="product"
//                                 value={values.product} // Set the dropdown value from the form state
//                                 onChange={(e) => {
//                                   const selectedProduct = e.target.value;
//                                   setFieldValue("product", selectedProduct); // Update the form state
//                                 }}
//                                 error={
//                                   touched.product && Boolean(errors.product)
//                                 }
//                               >
//                                 <option value=""></option>
//                                 {productOptions.map((option) => (
//                                   <option
//                                     key={option.id}
//                                     value={option.hierarchicalcode}
//                                   >
//                                     {option.name}
//                                   </option>
//                                 ))}
//                               </Select>
//                               <ErrorMessage
//                                 name="product"
//                                 component="div"
//                                 style={{ color: "red", fontSize: "12px" }}
//                               />
//                             </FormControl>
//                           </Grid>
//                         </Grid>
//                         <Grid container spacing={3}>
//                           <Grid item xs={12} sm={6}>
//                             <FormControl fullWidth>
//                               <TextField
//                                 label="Modal Name"
//                                 name="name"
//                                 id="name"
//                                 value={values.name}
//                                 onChange={(e) => {
//                                   const nameValue = e.target.value;
//                                   setFieldValue("name", nameValue);
//                                 }}
//                                 variant="standard"
//                                 error={touched.name && Boolean(errors.name)}
//                                 helperText={touched.name && errors.name}
//                               />
//                             </FormControl>
//                           </Grid>
//                           <Grid item xs={12} sm={6}>
//                             <FormControl fullWidth>
//                               <TextField
//                                 label="Description"
//                                 name="description"
//                                 id="description"
//                                 value={values.description}
//                                 onChange={(e) => {
//                                   const descriptionValue = e.target.value;
//                                   setFieldValue(
//                                     "description",
//                                     descriptionValue
//                                   );
//                                 }}
//                                 variant="standard"
//                                 error={
//                                   touched.description &&
//                                   Boolean(errors.description)
//                                 }
//                                 helperText={
//                                   touched.description && errors.description
//                                 }
//                               />
//                             </FormControl>
//                           </Grid>
//                         </Grid>
//                         <Grid container spacing={3} mt={0.5}>
//                           <Grid item xs={12} sm={6}>
//                             <FormControl fullWidth>
//                               <InputLabel htmlFor="extendedwarranty">
//                                 Is Extended Warranty Applicable{" "}
//                               </InputLabel>
//                               <Select
//                                 native
//                                 variant="standard"
//                                 style={{ width: "100%" }}
//                                 name="extendedwarranty"
//                                 id="extendedwarranty"
//                                 value={values.extendedwarranty}
//                                 onChange={(e) => {
//                                   const selectedextendedwarranty =
//                                     e.target.value;
//                                   setFieldValue(
//                                     "extendedwarranty",
//                                     selectedextendedwarranty
//                                   );
//                                 }}
//                                 error={
//                                   touched.extendedwarranty &&
//                                   Boolean(errors.extendedwarranty)
//                                 }
//                               >
//                                 <option value=""></option>
//                                 <option value="true">True</option>
//                                 <option value="false">False</option>
//                               </Select>
//                               <ErrorMessage
//                                 name="extendedwarranty"
//                                 component="div"
//                                 style={{ color: "red", fontSize: "12px" }}
//                               />
//                             </FormControl>
//                           </Grid>
//                           <Grid item xs={12} sm={6}>
//                             <FormControl fullWidth>
//                               <InputLabel htmlFor="warrantyapplicable">
//                                 Is warranty Applicable{" "}
//                               </InputLabel>
//                               <Select
//                                 native
//                                 variant="standard"
//                                 style={{ width: "100%" }}
//                                 name="warrantyapplicable"
//                                 id="warrantyapplicable"
//                                 value={values.warrantyapplicable}
//                                 onChange={(e) => {
//                                   const selectedwarrantyapplicable =
//                                     e.target.value;
//                                   setFieldValue(
//                                     "warrantyapplicable",
//                                     selectedwarrantyapplicable
//                                   );
//                                 }}
//                                 error={
//                                   touched.warrantyapplicable &&
//                                   Boolean(errors.warrantyapplicable)
//                                 }
//                               >
//                                 <option value=""></option>
//                                 <option value="true">True</option>
//                                 <option value="false">False</option>
//                               </Select>
//                               <ErrorMessage
//                                 name="warrantyapplicable"
//                                 component="div"
//                                 style={{ color: "red", fontSize: "12px" }}
//                               />
//                             </FormControl>
//                           </Grid>
//                         </Grid>
//                         <Grid container spacing={3}>
//                           <Grid item xs={12} sm={6}>
//                             <FormControl fullWidth>
//                               <TextField
//                                 label="Warranty in month"
//                                 type="number"
//                                 name="warrantymonth"
//                                 id="warrantymonth"
//                                 value={values.warrantymonth}
//                                 onChange={(e) => {
//                                   const nameValue = e.target.value;
//                                   setFieldValue("warrantymonth", nameValue);
//                                 }}
//                                 variant="standard"
//                                 error={
//                                   touched.warrantymonth &&
//                                   Boolean(errors.warrantymonth)
//                                 }
//                                 helperText={
//                                   touched.warrantymonth && errors.warrantymonth
//                                 }
//                               />
//                             </FormControl>
//                           </Grid>
//                         </Grid>
//                         <MDBox
//                           mt={5}
//                           width="100%"
//                           display="flex"
//                           justifyContent="space-between"
//                         >
//                           <MDButton
//                             type="submit"
//                             variant="gradient"
//                             color="dark"
//                           >
//                             Submit
//                           </MDButton>
//                         </MDBox>
//                       </MDBox>
//                     </MDBox>
//                   </Card>
//                 </Form>
//               )}
//             </Formik>
//           </Grid>
//         </Grid>
//       </MDBox>
//       <Footer />
//     </DashboardLayout>
//   );
// }

// export default ModalMaster;

import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getBrand } from "app/createBrand/service/Brand";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "app/productMaster/service/Product";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
import { createModal } from "./service/Modal";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";



function ModalMaster() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isWarrantyApplicable, setIsWarrantyApplicable] = useState(false);
  const { brand } = useSelector((state) => state.brand);
  const { product } = useSelector((state) => state.product);
  const [productOptions, setProductOptions] = useState([]);
  const { error, loading } = useSelector((state) => state.modal);
  const validationSchema = Yup.object().shape({
    brand: Yup.string().required("Brand is required"),
    product: Yup.string().required("Product is required"),
    extendedwarranty: Yup.string().required("Extended Warranty is required"),
    warrantyapplicable: Yup.string().required("Warranty Applicable is required"),
    name: Yup.string().required("Name is required"),
    warrantymonth: Yup.string().when('warrantyapplicable', {
      is: 'true',
      then: Yup.string().required('Warranty Month is required'),
      otherwise: Yup.string().notRequired(),
    }),
  });
  

  useEffect(() => {
    dispatch(getBrand());
    dispatch(getProduct());
  }, [dispatch]);

  const fetchProductOptions = async (selectedBrand) => {
    const result = product.filter(
      (productItem) => productItem.parenthierarchicalcode === selectedBrand
    );
    setProductOptions(result);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={{
                product: "",
                brand: "",
                extendedwarranty: "",
                name: "",
                warrantyapplicable: "",
                warrantymonth: "",
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, action) => {
                const requestData = {
                  desc: values.description,
                  name: values.name,

                  iswarranty: values.warrantyapplicable,
                  isextendedwarranty: values.extendedwarranty,
                  parenthierarchicalcode: values.product,
                  hierarchicalcode: values.product,
                  warrantyinmonth: values.warrantymonth,
                  recstatus: "OPEN",
                  resourcecode: "JAINA",
                  status: "Active",
                  syncstatus: "SYNCED",
                  type: "MODAL",
                };
                const response = await dispatch(createModal(requestData));

                if (!response.error && !loading) {
                  action.resetForm();
                  navigate("/app/modellist/model-list");
                } else {
                  action.resetForm();
                  swal({
                    icon: "error",
                    title: "Error",
                    text: error.message,
                  });
                }
              }}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          Model Master
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <Grid container spacing={3} mt={1}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="brand" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>
                              Select Brand <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="brand"
                              id="brand"
                              value={values.brand}
                              onChange={(e) => {
                                const selectedBrand = e.target.value;
                                setFieldValue("brand", selectedBrand);
                                fetchProductOptions(selectedBrand);
                              }}
                              error={touched.brand && Boolean(errors.brand)}
                            >
                              <option value=""></option>
                              {brand.map((option) => (
                                <option
                                  key={option.id}
                                  value={option.hierarchicalcode}
                                >
                                  {option.name}
                                </option>
                              ))}
                            </Select>
                            <ErrorMessage
                              name="brand"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="product" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>
                              Select Product <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}>*</sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="product"
                              id="product"
                              value={values.product}
                              onChange={(e) => {
                                const selectedProduct = e.target.value;
                                setFieldValue("product", selectedProduct);
                              }}
                              error={touched.product && Boolean(errors.product)}
                            >
                              <option value=""></option>
                              {productOptions.map((option) => (
                                <option
                                  key={option.id}
                                  value={option.hierarchicalcode}
                                >
                                  {option.name}
                                </option>
                              ))}
                            </Select>
                            <ErrorMessage
                              name="product"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} mt={0.5}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>Model Name<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                              name="name"
                              id="name"
                              value={values.name}
                              onChange={(e) => {
                                const nameValue = e.target.value;
                                setFieldValue("name", nameValue);
                              }}
                              variant="standard"
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              label="Description"
                              name="description"
                              id="description"
                              value={values.description}
                              onChange={(e) => {
                                const descriptionValue = e.target.value;
                                setFieldValue("description", descriptionValue);
                              }}
                              variant="standard"
                              error={
                                touched.description &&
                                Boolean(errors.description)
                              }
                              helperText={
                                touched.description && errors.description
                              }
                              InputLabelProps={{
                                style: { paddingBottom: "2px" }}}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} mt={0.5}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="extendedwarranty" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>
                              Is Extended Warranty Applicable <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="extendedwarranty"
                              id="extendedwarranty"
                              value={values.extendedwarranty}
                              onChange={(e) => {
                                const selectedextendedwarranty = e.target.value;
                                setFieldValue(
                                  "extendedwarranty",
                                  selectedextendedwarranty
                                );
                              }}
                              error={
                                touched.extendedwarranty &&
                                Boolean(errors.extendedwarranty)
                              }
                            >
                              <option value=""></option>
                              <option value="true">true</option>
                              <option value="false">false</option>
                            </Select>
                            <ErrorMessage
                              name="extendedwarranty"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="warrantyapplicable" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>
                              Is Warranty Applicable <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="warrantyapplicable"
                              id="warrantyapplicable"
                              value={values.warrantyapplicable}
                              onChange={(e) => {
                                const selectedwarrantyapplicable =
                                  e.target.value;
                                  setIsWarrantyApplicable(selectedwarrantyapplicable === "true");
                                setFieldValue(
                                  "warrantyapplicable",
                                  selectedwarrantyapplicable
                                );
                              }}
                              error={
                                touched.warrantyapplicable &&
                                Boolean(errors.warrantyapplicable)
                              }
                            >
                              <option value=""></option>
                              <option value="true">true</option>
                              <option value="false">false</option>
                            </Select>
                            <ErrorMessage
                              name="warrantyapplicable"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} mt={0.5}>
                      {isWarrantyApplicable && (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        label=<span>Warranty in months<sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>
                        type="number"
                        name="warrantymonth"
                        id="warrantymonth"
                        value={values.warrantymonth}
                        onChange={(e) => {
                          const nameValue = e.target.value;
                          setFieldValue("warrantymonth", nameValue);
                        }}
                        variant="standard"
                        error={touched.warrantymonth && Boolean(errors.warrantymonth)}
                        helperText={touched.warrantymonth && errors.warrantymonth}
                        InputLabelProps={{
                          style: { paddingBottom: "2px" }
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                       
                      </Grid>
                      <MDBox
                        mt={5}
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <MDButton type="submit" variant="gradient" color="dark">
                          {loading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Submit"
                          )}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ModalMaster;
