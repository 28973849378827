// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import axios from "axios";
import { BarLoader } from "react-spinners";
import { FaRegTimesCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

function AddressInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const dataObj = {
    columns: [
      { Header: "firstline", accessor: "firstline", width: "25%" },
      { Header: "secondline", accessor: "secondline", width: "30%" },
      { Header: "country", accessor: "country", width: "12%" },
      { Header: "state", accessor: "state" },
      { Header: "village", accessor: "city" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };
  const [tableData, setTableData] = useState(dataObj);
  
  const [color] = useState("#344767");
  const [profileId,setProfileId]=useState(JSON.parse(localStorage.getItem("mmu-auth")).profileid)


  const { formField, values, errors, touched } = formData;
 

  const {
    firstline,
    secondline,
    country,
   state,
    district,
    subdistrict,
    village,
    pincode,
   
   
  } = formField;
  var {
    firstline: firstlineV,
    secondline: secondlineV,
    country:countryV,
    
    state:stateV,
    district:districtV,
    subdistrict:subdistrictV,
    village:villageV,
    pincode:pincodeV
    
    
  } = values;

  const removeItem = function (uniqueKey) {

    const objWithIdIndex = tableData.rows.findIndex((obj) => obj.city == uniqueKey);
    tableData.rows.splice(objWithIdIndex, 1);
    setTableData(tableData);
     values.addressdataList  = tableData.rows;
  };


  //var profileId = JSON.parse(localStorage.getItem("mmu-auth")).profileid;
  
  const addData = (data) => {

    console.log("67",data);
    const addressData = {
     
      addresstype: "",
      approvedby: "",
     approveddate: "",
       approver: "",
       approvertype: "",
       city: data.village,
       classname: "",
       country: data.country,
       createdby: "ANKIT",
       createddate: "",
       defaultaddress: "",
       district: data.district,
        documentofproof: "",
       firstline: data.firstline,
      id: null,
     
     latlong: "",
      
       modifiedby: "",
       modifieddate: "",
       postcode: data.pincode,
       priority: 0,
       profileid: profileId,
     recstatus: "OPEN",
       secondline: data.secondline,
       state: data.state,
       status: "Active",
     syncstatus: "SYNCED",
       tehsil: "",
       tempid: ""
        
   };
   console.log("115", addressData);
   
   addressData.action = (
    <span>

        <Link to={{}}>
            <FaRegTimesCircle
                style={{
                    color: "#f44336",
                    width: "25px",
                    height: "25px",
                    cursor: "pointer",

                }}
                onClick={() => removeItem(addressData.city)}
            />
        </Link>

    </span>
);



let filter = tableData.rows.filter(
    (a) => a.city == addressData.city
);

if (filter.length < 1) {

    tableData.rows.push(addressData);
    dataObj.rows = tableData.rows;
    
    setTableData(dataObj);
     values.addressdataList  = tableData.rows;
}
console.log("150",tableData)

   
   
  };

  


 

 
  const handleChange = (event) => {
    roleV = event.target.value.value;
    console.log(roleV);
    setValue(event.target.value);
  };
 
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Address</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={firstline.type}
                label={firstline.label}
                name={firstline.name}
                value={firstlineV}
                placeholder={firstline.placeholder}
                error={errors.firstline && touched.firstline}
                success={firstlineV.length > 0 && !errors.firstline}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={secondline.type}
                label={secondline.label}
                name={secondline.name}
                value={secondlineV}
                placeholder={secondline.placeholder}
                error={errors.secondline && touched.secondline}
                success={secondlineV.length > 0 && !errors.secondline}
              />
            </Grid>
          </Grid>
         
         
        
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
          
            <FormField
                type={country.type}
                label={country.label}
                name={country.name}
                value={countryV}
                placeholder={country.placeholder}
                error={errors.country && touched.country}
                success={countryV.length > 0 && !errors.country}
              />
            </Grid>
          

         
            <Grid item xs={12} sm={6}>
            <FormField
                type={state.type}
                label={state.label}
                name={state.name}
                value={stateV}
                placeholder={state.placeholder}
                error={errors.state && touched.state}
                success={stateV.length > 0 && !errors.state}
              />
            </Grid>
            </Grid>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
          
            <FormField
                type={district.type}
                label={district.label}
                name={district.name}
                value={districtV}
                placeholder={district.placeholder}
                error={errors.district && touched.district}
                success={districtV.length > 0 && !errors.district}
              />
              
            </Grid>
          

         
            <Grid item xs={12} sm={6}>
            <FormField
                type={subdistrict.type}
                label={subdistrict.label}
                name={subdistrict.name}
                value={subdistrictV}
                placeholder={subdistrict.placeholder}
                error={errors.subdistrict && touched.subdistrict}
                success={subdistrictV.length > 0 && !errors.subdistrict}
              />
               
            </Grid>
            </Grid>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
          
            <FormField
                type={village.type}
                label={village.label}
                name={village.name}
                value={villageV}
                placeholder={village.placeholder}
                error={errors.village && touched.village}
                success={villageV.length > 0 && !errors.village}
              />
              
            </Grid>
            <Grid item xs={12} sm={6}>
          
          <FormField
              type={pincode.type}
              label={pincode.label}
              name={pincode.name}
              value={pincodeV}
              placeholder={pincode.placeholder}
              error={errors.pincode && touched.pincode}
              success={pincodeV.length > 0 && !errors.pincode}
            />
            
          </Grid>
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                style={{ marginTop: "10px" }}
                onClick={()=>addData(values)}
              >
                Add
              </MDButton>
            </Grid>
            <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",
              marginTop: "25px",
            }}
          >
            <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              table={tableData}
            />
          </Grid>
           
           
          
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for AddressInfo
AddressInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AddressInfo;



