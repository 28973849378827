import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: {
    //puchase detail
  product,
  complaintpriority,
    
  //job detail
    callby,
    purchasesource,
    purchasesubsource,
   
   //personal Deatil
   title,
   firstname,
   //contact Detail
   email,
  dealer,
  calltype,
  mobilenumber,
  alternatephonenumber,
date,
  fullreport,
  slot,
  ampm
  },
} = checkout;

const validations = [
  //product detail
  Yup.object().shape({
    [product.name]: Yup.string().required(product.errorMsg),
    [complaintpriority.name]: Yup.string().required(complaintpriority.errorMsg),
    
    }),

//job detail
  Yup.object().shape({
  
    [callby.name]: Yup.string().required(callby.errorMsg),
  [purchasesource.name]: Yup.string().required(purchasesource.errorMsg),
    // [purchasesubsource.name]: Yup.string().required(purchasesource.errorMsg),
     
   
    [purchasesubsource.name]: Yup.string().when(purchasesource.name, {
      is: "Online", // Only apply the validation when purchasesource is "Online"
      then: Yup.string().required("Purchase Source is required"),
    }),
  
  }),
  //personal detail
  Yup.object().shape({
    [title.name]: Yup.string().required(title.errorMsg),
    [firstname.name]: Yup.string().required(firstname.errorMsg),
  }),
  //contact detail
  Yup.object().shape({
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    // [mobilenumber.name]: Yup.string()
    // .required("Mobile number is required")
    // .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
    [alternatephonenumber.name]: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
    }),

   
      Yup.object().shape({
        [dealer.name]: Yup.string().required(dealer.errorMsg),
         [calltype.name]: Yup.string().required(calltype.errorMsg),
         [fullreport.name]: Yup.string().required(fullreport.errorMsg),
   
         
        }),
        Yup.object().shape({
          [date.name]: Yup.string()
          .required(date.errorMsg)
          .test('future-date', 'Date must not be in the past', (value) => {
            // Parse the input date string into a Date object
            const selectedDate = new Date(value);
            
            // Get the current date and time
            const currentDate = new Date();
            
            // Set the time of the current date to midnight (00:00:00)
            currentDate.setHours(0, 0, 0, 0);
            
            // Compare the selected date (with time set to midnight) with the current date (with time set to midnight)
            return selectedDate >= currentDate;
          }),
          [slot.name]: Yup.string().required(slot.errorMsg),
          [slot.name]: Yup.string().required(slot.errorMsg),
          [ampm.name]: Yup.string().required(ampm.errorMsg),
        
          }),
  
  
  
];

export default validations;
   
      
  
   
   
