import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import LightModeIcon from '@mui/icons-material/LightMode';
import NightlightOutlinedIcon from '@mui/icons-material/NightlightOutlined';


// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";

//Material UI
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


// NewUser page components
// import UserInfo from "layouts/pages/users/new-user/components/UserInfo";
import bookingCalenderInfo from "layouts/pages/Booking Calender/new-bookingCalender/components/bookingCalenderInfo";

// NewUser layout schemas for form and form feilds
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function getSelectDate(selectDate, formData) {
  switch (selectDate) {
    case 0:
      return <slotsInfo formData={formData} />;

      // case 1:
      //   return <slotsInfo formData={formData} />;

    default:
      return null;
  }
}


function NewBookingCalender(props) {

  const slotsInfo = () => {
    const morningSlot = ['09:00', '10:00', '11:00', '12:00', '01:00', '02:00'];
    const eveningSlot = ['04:00', '05:00', '06:00', '07:00', '08:00', '09:00'];
    
      // return values
      return {
        'morningSlot': morningSlot,
        'eveningSlot': eveningSlot
      };   

  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik

            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          Booking Slot
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        <Grid container spacing={3}>

                          <Grid item xs={12} sm={8}>
                            {/* // <MDDatePicker input={{ placeholder: "Book-your-Slot" }} /> */}

                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <MDDatePicker input={{ placeholder: "Book-your-Slot" }} />

                          </Grid>
                        </Grid>

                        <MDBox p={3}>
                          <Grid container spacing={3}>

                            <Grid item xs={12} sm={1}>
                              {/* <MDInput 
                                disabled = "true" 
                               label="Type here 1..." /> */}
                            </Grid>
                            <Grid item xs={12} sm={1}>
                              <ChevronLeftIcon fontSize="large" />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <MDInput

                                disabled="true"
                                label="Today"

                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <MDInput
                                disabled="true"
                                label="Tommorrow"
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <MDInput
                                disabled="true"
                                label="Day after tommorrow"
                              />
                            </Grid>
                            <Grid item xs={12} sm={1}>
                              <ChevronRightIcon fontSize="large" />
                            </Grid>
                          </Grid>
                        </MDBox>

                        <MDBox p={1}>
                          <Grid container spacing={2}>

                            <Grid item xs={12} sm={1}>

                              <LightModeIcon
                                fontSize="large"
                              />

                            </Grid>
                            <Grid item xs={12} sm={1}>
                              <MDTypography
                                variant="caption"
                                fontWeight="small"
                                ml={-2}
                               
                                >
                                Morning
                              </MDTypography>
                            </Grid>

                            {/* <Grid item xs={12} sm={2}>
                              <MDButton variant="outlined" color="info" size="small">
                                formData.getMorning[i]
                              </MDButton>
                            </Grid> */}
                            {/* <Grid item xs={12} sm={2}>
                              <MDButton variant="outlined" color="info" size="small">
                                formData.getEvening[i]
                              </MDButton>
                            </Grid> */}
                            <Grid item xs={12} sm={2}>
                              <MDButton variant="outlined" color="info" size="small">
                                11:30 AM
                              </MDButton>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <MDButton variant="outlined" color="info" size="small">
                                12:00 PM
                              </MDButton>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <MDButton variant="outlined" color="info" size="small">
                                01:00 PM
                              </MDButton>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <MDButton variant="outlined" color="info" size="small">
                                02:00 PM
                              </MDButton>
                            </Grid>
                          </Grid>
                        </MDBox>

                        {/* { borderColor: grey[300] } */}

                        <MDBox p={1}>
                          <Grid container spacing={2}>

                            <Grid item xs={12} sm={1}>

                              <NightlightOutlinedIcon
                                fontSize="large"
                              />

                            </Grid>
                            <Grid item xs={12} sm={1}>
                              <MDTypography
                                variant="caption"
                                fontWeight="small"
                                ml={-3}
                                mt={-12}

                                >
                                Evening
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <MDButton variant="outlined" color="info" size="small">
                                04:00 PM
                              </MDButton>
                            </Grid>
                            {/* <Grid item xs={12} sm={2}>
                              <MDButton variant="outlined" color="info" size="small">
                                formData.getEvening[i]
                              </MDButton>
                            </Grid> */}

                            <Grid item xs={12} sm={2}>
                              <MDButton variant="outlined" color="info" size="small">
                                05:00 PM
                              </MDButton>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <MDButton variant="outlined" color="info" size="small">
                                06:00 PM
                              </MDButton>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <MDButton variant="outlined" color="info" size="small">
                                07:00 PM
                              </MDButton>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <MDButton variant="outlined" color="info" size="small">
                                08:00 PM
                              </MDButton>
                            </Grid>
                          </Grid>
                        </MDBox>


                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewBookingCalender;

