import { createSlice } from "@reduxjs/toolkit";
import { getlocality } from "../service/Address";





const initialState = {
  locality: [],
  loading: false,
  error: null,
};

export const locality = createSlice({
  name: "localitycrud",
  initialState,
  reducers: {
    resetLocalityList: (state) => {
      state.locality = [];
    },
  },
  extraReducers: {
    [getlocality.pending]: (state) => {
      state.loading = true;
    },
    [getlocality.fulfilled]: (state, action) => {
      state.loading = false;
      state.locality = action.payload.data.dtoList;
    },
    [getlocality.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { resetLocalityList } = locality.actions; 
export default locality.reducer;
