// import { useState } from "react";

// // formik components
// import { Formik, Form } from "formik";

// // @mui material components
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import MDTypography from "components/MDTypography";

// // Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

// // Material Dashboard 2 PRO React examples
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";

// // SLOT INFO page components
// import SlotInfo from "./new slotcreation/componets/SlotInfo";
// // Newlab layout schemas for form and form feilds

// import validations from "layouts/pages/slotcreation/new slotcreation/schemas/validations";
// import form from "layouts/pages/slotcreation/new slotcreation/schemas/form";
// import initialValues from "layouts/pages/slotcreation/new slotcreation/schemas/initialValues";
// import CoverLayout from "layouts/authentication/components/CoverLayout";
// // image
// import bgImage from "assets/images/bg-pricing.jpg";

// import { useNavigate } from "react-router-dom";


// function getSteps() {
//   return ["Slot Info"];
// }

// function getStepContent(stepIndex, formData) {
//   switch (stepIndex) {
//     case 0:
//       return <SlotInfo formData={formData} />;
//     default:
//       return null;
//   }
// }

// function Createslot() {
//   const navigate = useNavigate();
//   const [activeStep, setActiveStep] = useState(0);
//   const [equipmentList, setEquipmentList] = useState([]);
  
//   const steps = getSteps();
//   const { formId, formField } = form;
//   const currentValidation = validations[activeStep];
//   const isLastStep = activeStep === steps.length - 1;
//   const equipmentModel = {
//     assetno: "",
//     item: "",
//     quantity: "",
//   };

//   function creatingEquipList(values) {
//     console.log("before", values);

//     if(typeof  values.EquipmentList!=="undefined"){
//        for (let i = 0; i < values.EquipmentList.length; i++) {
//       let equipmentModel = {
//         assetno: "",
//         item: "",
//         quantity: "",
//       };
//       equipmentModel.quantity = values.EquipmentList[i].inputQty;
//       equipmentModel.assetno = values.EquipmentList[i].inputSerialNo;
//       delete values.EquipmentList[i].action;
//       delete values.EquipmentList[i].inputQty;
//       delete values.EquipmentList[i].inputSerialNo;
//       equipmentModel.item = values.EquipmentList[i];
//       equipmentList.push(equipmentModel);
//     }
//   };
//     // values.EquipmentList.map((obj) => {

//     //   obj.quantity = obj.inputQty;
//     //   obj.assetno = obj.inputSerialNo;
//     //   obj.item = obj;

//     //   delete obj.action;
//     //   delete obj.inputQty;
//     //   delete obj.inputSerialNo;
//     //   equipmentList.push(equipmentModel);
//     // });
//     console.log("after", equipmentList);
//   }
//   const sleep = (ms) =>
//     new Promise((resolve) => {
//       setTimeout(resolve, ms);
//     });
//   const handleBack = () => setActiveStep(activeStep - 1);

//   const submitForm = async (values, actions) => {
//     console.log("hii", values);
//     creatingEquipList(values);
//     const getData = {
//       approvedby: "",
//       approveddate: "",
//       classname: "",
//       code: "",
//       createdby: "",
//       createddate: "",
//       description: values.MMUDescription,
//       documentofproof: "",
//       id: null,
//       latlong: "",
//       location: "",
//       modifiedby: "",
//       modifieddate: "",
//       name: values.MMUName,
//       recstatus: "OPEN",
//       status: "Active",
//       syncstatus: "SYNCED",
//       vehicle: values.VehicleAssign,
//       raspberry: values.RaspberryAssign,
//       warehouse: values.mapware,
//       equipmentlist: equipmentList,
//     };

//     await axios
//       .post(
//         "mmucreation-service/api/MMUCreation/v1/create",
//         getData
//       )

//       .then((response) => {
//         console.log("gfgfgf", response);
//         console.log("final obj", getData);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//     navigate("/pages/users/mmu-list");

//     // eslint-disable-next-line no-alert
//     // alert(JSON.stringify(values, null, 2));

//     actions.setSubmitting(false);
//     actions.resetForm();

//     setActiveStep(0);
//   };

//   const handleSubmit = (values, actions) => {
//     console.log(values);

//     if (isLastStep) {
//       submitForm(values, actions);
//     } else {
//       setActiveStep(activeStep + 1);
//       actions.setTouched({});
//       actions.setSubmitting(false);
//     }
//   };

//   return (
//     <DashboardLayout>
//     <CoverLayout mt={20} image={bgImage}>
     
//       <MDBox py={3} mb={20} height="65vh">
//         <Grid
//           container
//           justifyContent="center"
//           alignItems="center"
//           sx={{ height: "100%",  }}
//         >
//           <Grid item xs={12} lg={8}>
//             <Formik
//               initialValues={initialValues}
//               validationSchema={currentValidation}
//               onSubmit={handleSubmit}
//             >
//               {({ values, errors, touched, isSubmitting }) => (
//                 <Form id={formId} autoComplete="off">
//                 <Card sx={{ height: "80%",width:"620px",marginLeft:"-140px" }}>
//                     <MDBox mx={2} mt={-3}>
//                     <MDBox
//         variant="gradient"
//         bgColor="info"
//         borderRadius="lg"
//         coloredShadow="success"
//         mx={2}
//         mt={-1}
//         p={3}
//         mb={1}
//         textAlign="center"
//       >
//         <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
//          Slot
//         </MDTypography>
//         <MDTypography display="block" variant="button" color="white" my={1}>
//           Enter your detail
//         </MDTypography>
//       </MDBox>
//                     </MDBox>
//                     <MDBox p={3}>
//                       <MDBox>
//                         {getStepContent(activeStep, {
//                           values,
//                           touched,
//                           formField,
//                           errors,
//                         })}
//                         <MDBox
//                           mt={2}
//                           width="100%"
//                           display="flex"
//                           justifyContent="space-between"
//                         >
//                           {activeStep === 0 ? (
//                             <MDBox />
//                           ) : (
//                             <MDButton
//                               variant="gradient"
//                               color="light"
//                               onClick={handleBack}
//                             >
//                               back
//                             </MDButton>
//                           )}
//                           <MDButton
//                             disabled={isSubmitting}
//                             type="submit"
//                             variant="gradient"
//                             color="dark"
//                           >
//                             submit
//                           </MDButton>
//                         </MDBox>
//                       </MDBox>
//                     </MDBox>
//                   </Card>
//                 </Form>
//               )}
//             </Formik>
//           </Grid>
//         </Grid>
//       </MDBox>
//       <Footer />
//     </CoverLayout>
//     </DashboardLayout>
//   );
// }



// export default Createslot;



import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// SLOT INFO page components
import SlotInfo from "./new slotcreation/componets/SlotInfo";
// Newlab layout schemas for form and form feilds

import validations from "layouts/pages/slotcreation/new slotcreation/schemas/validations";
import form from "layouts/pages/slotcreation/new slotcreation/schemas/form";
import initialValues from "layouts/pages/slotcreation/new slotcreation/schemas/initialValues";
import CoverLayout from "layouts/authentication/components/CoverLayout";
// image
import bgImage from "assets/images/bg-pricing.jpg";
// import axios from "axios";

import axios from "../../../axiosinstance"

import { useNavigate } from "react-router-dom";


function getSteps() {
  return ["Slot Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <SlotInfo formData={formData} />;

    default:
      return null;
  }
}

function Createslot() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    console.log("hii", values);
    for (let i = 0; i < values.slotDataList.length; i++) {
      delete values.slotDataList[i].action
    }
    console.log("finalslotdata",values.slotDataList)
  
    await axios
      .post(

        'event-service/api/event/v1/createList',
        values.slotDataList
      )

      .then((response) => {
        console.log("gfgfgf", response);
        swal("Good job!", "your Address Detail  has been submit!", "success");
        navigate("/mmu/con-list")
      })
      .catch((error) => {
        console.log(error);
        swal("oops!", "something wents wrong");
      });

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    console.log(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                  <MDBox mx={2} mt={-3}>
                    <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-1}
        p={3}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
         Slot INFORMATION
        </MDTypography>
        <MDTypography display="block" variant="button" color="white" my={1}>
          Enter your detail
        </MDTypography>
      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                            onClick={() => {
                              console.log("submit");
                            }}
                          >
                            submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Createslot;









