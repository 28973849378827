
import React,{useState,useEffect} from 'react'

// import axios from "../axiosinstance"
import axios from "axios";


export default function Data(){
   
    
   
    axios.get('https://reqres.in/api/users?page=2')
    .then((response)=>{
        console.log(response)
        return response.data
           
       
    })
    .catch(error => console.error('Error:${error}'));
      
   }
  








