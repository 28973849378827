import { createSlice } from "@reduxjs/toolkit";
import { createJob, getJob,  } from "../server/jobservice";




const initialState = {
  job: [],
  loading: false,
  error: null,
};

export const JobDetail = createSlice({
  name: "jobDetail",
  initialState,
  extraReducers: {
    [createJob.pending]: (state) => {
      state.loading = true;
    },
    [createJob.fulfilled]: (state, action) => {
      console.log("state",state.brand)
      state.loading = false;
      state.job.push(action.payload.data.dto);
    },
    [createJob.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getJob.pending]: (state) => {
      state.loading = true;
    },
    [getJob.fulfilled]: (state, action) => {
      state.loading = false;
      state.job = action.payload.data.dtoList;
    },
    [getJob.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
   
  
  },
});

export default JobDetail.reducer;
