/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Countries flags
// import US from "assets/images/icons/flags/US.png";
// import DE from "assets/images/icons/flags/DE.png";
// import GB from "assets/images/icons/flags/GB.png";
// import BR from "assets/images/icons/flags/BR.png";

const salesTableData = [
  {
    Amount: ["44%","99.9%"],
    Reporate: 2500,
    value: "Rs. 230,900",
    coverage: "29.9%",
  },
  {
    Amount: ["44%","57%"],
    Reporate: "3.900",
    value: "Rs. 440,000",
    coverage: "40.22%",
  },
  {
    Amount: ["44%","67%"],
    Reporate: "1.400",
    value: "Rs. 190,700",
    coverage: "23.44%",
  },
  {
    Amount: ["44%","77%"],
    Reporate: 562,
    value: "Rs. 143,960",
    coverage: "32.14%",
  },
];

export default salesTableData;
