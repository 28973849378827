/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "category-form",
  formField: {
    projectselection: {
      name: "projectselection",
      label: "Project Selection",
      type: "text",
      errorMsg: "Project selection is required.",
    },
    state: {
      name: "state",
      label: "State",
      type: "text",
      errorMsg: "State is required.",
    },
    district: {
      name: "district",
      label: "District",
      type: "text",
      errorMsg: "District is required.",
    },
    subDistrict: {
      name: "subDistrict",
      label: "Sub-District",
      type: "text",
      errorMsg: "Sub-District is required.",
    },

    pincode: {
      name: "pincode",
      label: "Pincode",
      type: "number",
      errorMsg: "Pincode is required.",
    },

    createproject: {
      name: "createproject",
      label: "Create Project",
      type: "text",
      errorMsg: "Create project is required.",
    },

    parenthierachical: {
      name: "parenthierachical",
      label: "Parent hiearachical",
      type: "text",
      errorMsg: "Parent hierachical is required.",
    },
  },
};

export default form;
