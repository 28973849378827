import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";

export const getserailnumber = createAsyncThunk("getserailnumber", async (item, {rejectWithValue}) => {
 

    try {
      const response = await axios.get(
       
        `product-service/api/Product/v2/get?serialnumber=${item}&recstatus=APPROVED`
       
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });
 