import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux"; 
//import { brandList, deleteBrand } from "Services/Brand Service/Brand";
import CircularProgress from "@mui/material/CircularProgress";
 
//edit icon
import { FaRegEdit } from "react-icons/fa";
import { deleteProduct, getProduct } from "../service/Product";
import Productedit from "./Productedit";
import { getBrand } from "app/createBrand/service/Brand";



function ProductList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {product, loading} = useSelector((state) => state.product);
  
  // const [loading, setLoading] = useState(true);
 
  const [openpopup, setopenpopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);


function editBrand(item){
    setSelectedProduct(item)
     setopenpopup(true);
     
}



function onDelete(item) {
  // Show a confirmation dialog using SweetAlert
  swal({
    title: "Are you sure?",
    text: "You will not be able to recover this item!",
    icon: "warning",
    buttons: ["Cancel", "Delete"],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      // User clicked "Delete," proceed with deletion
      swal("Deleting...", {
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
      });

      // Dispatch the deleteBrand action
      dispatch(deleteProduct(item))
        .then(() => {
          // After successful deletion, close the Swal dialog
          swal("Deleted!", "Your item has been deleted.", "success");
          // Fetch the updated brand list
        
        })
        .catch((error) => {
          // Handle errors, display an error message, and close the Swal dialog
          swal("Error", "An error occurred while deleting the item.", "error");
        });
    } else {
      // User clicked "Cancel," do nothing
      swal("Cancelled", "Your item is safe.", "info");
    }
  });
}

useEffect(() => {
  dispatch(getProduct());
  dispatch(getBrand());
}, [])

  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "blue",
    marginRight: "9px",
  };

  
  if (loading) {
    // Render a centered circular loader
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DataTable
        canSearch={true}
        table={{
          columns: [
            { Header: "name", accessor: "name" },
            { Header: "description", accessor: "desc" },
            { Header: "brand", accessor: "parenthierarchicalcode" },
            {
    Header: "Created Date",
    accessor: "createddate",
    Cell: ({ value }) => {
     const formattedDate = new Date(value).toDateString();
      return formattedDate;
    },
  },
{ Header: "Action", accessor: "action",

              Cell: ({ row }) => (
                <div>
                  <Link to={{}}>
                    <FaRegEdit
                      style={iconStyleedit}
                      onClick={() => {
                       editBrand(row.original)
                      }}
                    />
                  </Link>

                  <Link to={{}}>
                    <MdDelete
                      style={iconStyle}
                      onClick={() => {
                        onDelete(row.original);
                      }}
                    />
                  </Link>
                </div>
              ),
            },
          ],
          rows: product || [],
        }}
      />
      <Productedit
        openpopup={openpopup}
        setopenpopup={setopenpopup}
       title={"Product Edit"}
       selectedProduct={selectedProduct}

      />
    </DashboardLayout>
  );
}

export default ProductList;




