/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import checkout from "layouts/pages/category/new Category/schemas/form";

const {
  formField: {
    createproject,
    projectselection,
    state,
    district,
    subDistrict,
    pincode,
  },
} = checkout;

const initialValues = {
  [projectselection.name]: "",

  [createproject.name]: "",
  [state.name]: "",
  [district.name]: "",
  [subDistrict.name]: "",
  [pincode.name]: "",
};

export default initialValues;
