import { createSlice } from "@reduxjs/toolkit";
import { getpincode } from "../service/Address";





const initialState = {
  citylist: [],
  loading: false,
  error: null,
};

export const pincode = createSlice({
  name: "pincodecrud",
  initialState,
  reducers: {
    resetCityList: (state) => {
      state.citylist = [];
    },
  },
  extraReducers: {
    [getpincode.pending]: (state) => {
      state.loading = true;
    },
    [getpincode.fulfilled]: (state, action) => {
      state.loading = false;
      state.citylist = action.payload.data.dtoList;
    },
    [getpincode.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { resetCityList } = pincode.actions; 
export default pincode.reducer;
