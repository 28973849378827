import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../FormField";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Input,
} from "@mui/material";

import { useFormikContext } from "formik";
function CoustomerTime({ formData }) {
  const { formField, values, errors, touched } = formData;
  const currentDate = new Date().toISOString().split('T')[0];
  const defaultDate = currentDate;
  const { setFieldValue, setFieldError } = useFormikContext(); // Access Formik context
  
  const { date, email,alternatephonenumber,timeofresolution } = formField;
  const { date: dateV, email: emailV, slot:slotV, alternatephonenumber:alternatephonenumberV,ampm:ampmV,timeofresolution:timeofresolutionV} = values;
  values.date =  dateV || defaultDate
  const handleBlur = (event) => {
    const { name, value } = event.target;

    // Validation logic...
    // For example, let's assume you want to ensure that the "brand" field is not empty onBlur.
    // if (!value) {
    //   setFieldError(name, "Brand is required");
    // } else {
    //   setFieldError(name, ""); // Clear the error if it's valid
    // }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };
  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Customer Time
      </MDTypography>
      <MDBox mt={1.625}>
      <Grid container spacing={3}>
            

      <Grid item xs={12} sm={6}>
              <FormField
                type={date.type}
                label={date.label}
                name={date.name}
                value={dateV}
                placeholder={date.placeholder}
                error={errors.date && touched.date}
                success={dateV.length > 0 && !errors.date}
              />
            </Grid>
            
            <Grid item xs={12} sm={3}>
            <div style={{marginTop: window.innerWidth <= 700 ? "-10px" : "auto"}}>
              <FormControl fullWidth error={!!errors.slot && touched.slot}>
                <InputLabel htmlFor="dealer" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Time Slot <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}>*</sup></InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="slot"
                  id="slot"
                  value={slotV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value=""></option>
                  
                  <option value="9-10">9-10</option>
                  <option value="10-11">10-11</option>
                  <option value="11-12">11-12</option>
                
                </Select>
                {errors.slot && touched.slot && (
                  <FormHelperText error style={{marginLeft:"0px"}}>{errors.slot}</FormHelperText>
                )}
              </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
                  
                  <FormControl fullWidth error={!!errors.ampm && touched.ampm}>
                    <InputLabel htmlFor="dealer" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>AM || PM <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}>*</sup></InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="ampm"
                      id="ampm"
                      value={ampmV}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value=""></option>
                      
                 
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    
                     
                    
                    </Select>
                    {errors.ampm && touched.ampm && (
                      <FormHelperText error style={{marginLeft:"0px"}}>{errors.ampm}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
            </Grid>
            
         
            <Grid container spacing={3}>
            

                  <Grid item xs={12} sm={6}>
                  <div style={{marginTop: window.innerWidth <= 700 ? "25px" : "auto"}}>
              <FormField
                type={timeofresolution.type}
                label={timeofresolution.label}
                name={timeofresolution.name}
                value={timeofresolutionV}
                placeholder={timeofresolution.placeholder}
                error={errors.timeofresolution && touched.timeofresolution}
                InputLabelProps={{
                  style: { paddingBottom: "2px" }}}
              />
              </div>
            </Grid>
              
                  </Grid>
          
      </MDBox>
    </MDBox>
  );
}

// typechecking props for ContactDetail
CoustomerTime.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};



export default CoustomerTime