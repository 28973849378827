
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create address

export const createproductregistration = createAsyncThunk("createProductregistration", async (data, {rejectWithValue}) => {
  console.log("xyz", data);

  try {
    const response = await axios.post(
      `product-registration-service/api/ProductRegistration/v2/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getProductregistration = createAsyncThunk("getProductregistration", async (profileid, {rejectWithValue}) => {
 

  try {
    const response = await axios.get(
     
      `product-registration-service/api/ProductRegistration/v2/get?profileid=${profileid}`
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fileUpload = createAsyncThunk("fileUpload", async (data, {rejectWithValue}) => {
  console.log("formData", data);
// https://japi.softwaremathematics.com/new-upload-service-mongo/api/attachment/upload
  try {
    const response = await axios.post(
      `new-upload-service-mongo/api/attachment/upload`,
     // `http://5.189.171.116:9187/api/attachment/upload`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFile = createAsyncThunk("getFile", async (data, {rejectWithValue}) => {
 console.log("apidata",data)
 

  try {
    const response = await axios.get(
     
      `new-upload-service-mongo/api/attachment/getAll/code/${data.profileId}/model/${data.code}`
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});


// /api/attachment/getAll/code/{code}/model/{model}

export const getProductregistrationbyrecstatus = createAsyncThunk("getProductregistrationbyrecstatus", async (profileid, {rejectWithValue}) => {
 

  try {
    const response = await axios.get(
     
      `product-registration-service/api/ProductRegistration/v2/get?profileid=${profileid}&recstatus=APPROVED`
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});