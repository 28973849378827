
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create brand

export const createJob = createAsyncThunk("createJob", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `job-service/api/Job/v2/create`,
      data
    );
    
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});


export const getJob = createAsyncThunk("getJob", async (profileid, {rejectWithValue}) => {
 

  try {
    const response = await axios.get(
     
      `job-service/api/Job/v2/get?profileid=${profileid}`
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});



