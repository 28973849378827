import * as Yup from "yup";
import checkout from "layouts/pages/qualification/new-qualification/schemas/form";

const {
  formField: {
    qualificationName,
    mode,
    board,
    grade,
    startDate,
    // type,
    endDate,


  },
} = checkout;

const validations = [
  Yup.object().shape({
    [qualificationName.name]: Yup.string().required(qualificationName.errorMsg),
   // [mode.name]: Yup.string().required(mode.errorMsg),
    [grade.name]: Yup.string().required(grade.errorMsg),
    [startDate.name]: Yup.string().required(startDate.errorMsg),
    [board.name]: Yup.string().required(board.errorMsg),
    [endDate.name]: Yup.string().required(endDate.errorMsg),

  }),


];

export default validations;