// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import { Autocomplete } from "@mui/material";

import MDDatePicker from "components/MDDatePicker";



import { useState } from "react";

import { BarLoader } from "react-spinners";
import MDInput from "components/MDInput";


function bookingCalenderInfo({ formData }) {
  
  const [loading, setLoading] = useState(false);
  
  const [color] = useState("#344767");


  
  


  return (
    <MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>

            
              
            </Grid>
            <Grid item xs={12} sm={6}>
              
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
             
            </Grid>
            <Grid item xs={12} sm={6}>
              
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>

              
            </Grid>
            <Grid item xs={12} sm={6}>

            </Grid>

          </Grid>


          <Grid container spacing={3}>


            <Grid item xs={12} sm={6}>
              

            </Grid>
            <Grid item xs={12} sm={6}>
              
            </Grid>

          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              
            </Grid>
            <Grid item xs={12} sm={6}>
              
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {/* <FormField
                type={bankname.type}
                label={bankname.label}
                name={bankname.name}
                value={banknameV}
                placeholder={bankname.placeholder}
                error={errors.bankname && touched.bankname}
                success={banknameV.length > 0 && !errors.bankname}
              /> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              
            </Grid>

          </Grid>

          <Grid container spacing={3}>

            <Grid item xs={12} sm={6}>

            </Grid>
          </Grid>






        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for UserInfo
bookingCalenderInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default bookingCalenderInfo;

