
import { useState } from "react";
import {Link, useNavigate } from "react-router-dom";
// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import swal from 'sweetalert';
// NewUser page components


// import Socials from "layouts/pages/doctor/new-doctor/components/Socials";
// import Profile from "layouts/pages/doctor/new-doctor/components/Profile";
import MDTypography from "components/MDTypography";

// NewUser layout schemas for form and form feilds
import PatientInfo from "../patientsignup/new-patient/components/PatientInfo";
import validations from "layouts/pages/patientsignup/new-patient/schemas/validations";
import form from "layouts/pages/patientsignup/new-patient/schemas/form";
import initialValues from "layouts/pages/patientsignup/new-patient/schemas/initialValues";
import CoverLayout from "layouts/authentication/components/CoverLayout";
// image
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

import axios from "../../../axiosinstance"


function getSteps() {
  return ["Doctor Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <PatientInfo formData={formData} />;

    default:
      return null;
  }
}

function NewDoctor() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    console.log("hii", values);
    const formData = {
      createdby:"HEALTHCARE WEB UI",
     
    createddate: null,
    documentofproof: "",
    emailid: values.email,
    
  //groupcode: "MMU",
    id: null,
    firstname:values.firstName,
    lastname:values.lastName,
    latlong: "",
    password: values.password,
    phoneno: values.phonenumber,
    recstatus: "APPROVED",
    resetflag: null,
    resourcecode: "HEALTHCARE",
   

   rolecode: "DO001",
   rolename:"DOCTOR",
    status: "ACTIVE",
    syncstatus: "synced",
    token: "",
    username: values.username,
    };
    console.log("payload", formData);
        await axios
        .post(
          'profile-service-mongo/api/ProfileEntity/v2/create',
          formData
        )
  
        .then((response) => {
          swal("Good job!", "your registration has been done!", "success");
          navigate('/pages/otp/new-otp');
        }
          )
        .catch((error) => {
          console.log(error);
        });

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    console.log(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <CoverLayout image={bgImage}>
    
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: -1}}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                <Card sx={{ height: "100%", width: "450px",ml:-12}}>
                    <MDBox mx={2} mt={-3}>
                    <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-1}
        p={3}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
         Sign up
        </MDTypography>
        <MDTypography display="block" variant="button" color="white" my={1}>
          Enter your detail and password to register
        </MDTypography>
      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                            onClick={() => {
                              console.log("submit");
                            }}
                          >
                            submit
                          </MDButton>
                        </MDBox>
                        <div style={{textAlign:"center"}}>
                            <span style={{ fontSize: "12px" }}>Already have an account?</span>
                            <Link
                              style={{

                                marginLeft: "5px",
                                fontWeight: "bold",

                                color: "blue",
                                fontSize: "16px"
                              }}
                              to={'/authentication/sign-in/basic'}
                            >
                              Sign In
                            </Link>
                            </div>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </CoverLayout>
  );
}






export default NewDoctor;