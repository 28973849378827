import { useState,useEffect } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { MoonLoader } from "react-spinners";
// NewUser page components

// NewUser layout schemas for form and form feilds
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { useNavigate } from "react-router-dom";
import ContactDetail from "./components/contactDetail";
// import AddressDetail from "./components/addressDetail";

import JobDetail from "./components/jobdetail";
import PersonalDetail from "./components/personalDetail";

import PurchaseDetail from "./components/purchaseDetail";
import ProductDetail from "./components/productDetail";
import MoreDetail from "./components/moredetail";
import CoustomerTime from "./components/coustomerTime";
import { createJob } from "./server/jobservice";
import { useDispatch } from "react-redux";
import AddressDetail from "./components/addressDetail";
import { useSelector } from "react-redux";

function getSteps() {
  return [
    "Product Detail",
    "Job Detail",
    "Personal Detail",
    "Contact Detail",
    "More Detail",
    "Customer Time",

    "Address Detail",
  ];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <ProductDetail formData={formData} />;
    case 1:
      return <JobDetail formData={formData} />;
    case 2:
      return <PersonalDetail formData={formData} />;
    case 3:
      return <ContactDetail formData={formData} />;
    case 4:
      return <MoreDetail formData={formData} />;
    case 5:
      return <CoustomerTime formData={formData} />;
    case 6:
      return <AddressDetail formData={formData} />;

    default:
      return null;
  }
}

function ServiceRequest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [profileId, setProfileId] = useState(
    JSON.parse(localStorage.getItem("mmu-auth")).profileid
  );
  const {error,loading} = useSelector((state) => state.job); 
  console.log(error)

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
  
    if (values.address === undefined) {
      swal("oops!", "Please Add Address First!", "error")
     
      actions.setSubmitting(false);
    } else {
      values.dealer = JSON.parse(values.dealer);
      values.product = JSON.parse(values.product);
      const requstData = {
        address: values.address,

        alternatephonenumber: values.alternatephonenumber,
        // "ascname": "string",
        callby: values.callby,
        callnature: values.callnature,
        callreason: values.callreason,
        callsource: values.callsource,

        calltype: values.calltype,
        closedreason: values.closedreason,
        complaintpriority: values.complaintpriority,

        customeravialabledate: values.date,
        dealer: values.dealer,

        email: values.email,
        // "estimateTimeResolution": "string",
        faultreported: values.fullreport,
        firstname: values.firstname,
        id: null,
        invoiceno: values.invoice,
        lastcomplaintno: values.lastcomplaint,
        lastname: values.lastname,
        phonenumber: values.mobilenumber,
        productregister: values.product,
        profileid: profileId,
        purchasesource: values.purchasesource,
        purhcasesubsource: values.purchasesubsource,
        recstatus: "OPEN",
        slot: values.slot,
        specialinstruction: values.specialinstruction,
        status: "ACTIVE",
        syncstatus: "synced",
        title: values.title,
        wallmount: values.wallmount,
      };
      const response =  await dispatch(createJob(requstData));
    
      // eslint-disable-next-line no-alert
      // alert(JSON.stringify(values, null, 2));

     

     
      if (!response.error && !loading) {
        swal("Good job!", "Your complain has been successfully Register!", "success")
        actions.setSubmitting(false);
      actions.resetForm();
      setActiveStep(0);
      } else {
        actions.resetForm();
        setActiveStep(0);
        swal({
          icon: "error",
          title: "Error",
          text: error.message,
        });
      }
    }
  };

  const handleSubmit = (values, actions) => {
    console.log(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };
 
 

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step
                            key={label}
                            sx={{
                              marginRight:
                                window.innerWidth <= 700 ? "-9px" : "auto",
                            }}
                          >
                            <StepLabel>
                              <div
                                style={{
                                  fontSize:
                                    window.innerWidth <= 700 ? "8px" : "12px",
                                }}
                              >
                                {label}
                              </div>
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}

                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="dark"
                            disabled={isLoading}
                          >
                            {loading ? (
                              <MoonLoader color="#f2fefa" size={16} />
                            ) : isLastStep ? (
                              "submit"
                            ) : (
                              "next"
                            )}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ServiceRequest;
