import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { Category } from '@material-ui/icons';
import QualificationForm from '../qualification/new-qualification';
import NewCategory from '../category';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

function getSteps() {
    return ["Qualification Form", "Additional Information"];
}


function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <QualificationForm />;
        case 1:
            return <NewCategory />;

        default:
            return null;
    }
}


export default function Steeper() {
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const handleBack = () => setActiveStep(activeStep - 1);
    const isLastStep = activeStep === steps.length - 1;
    return (
        <DashboardLayout>
            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <MDBox p={3}>
                <MDBox>
                    {getStepContent(activeStep)}
                    <MDBox
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                    >
                        {activeStep === 0 ? (
                            <MDBox />
                        ) : (
                            <MDButton
                                variant="gradient"
                                color="light"
                                onClick={handleBack}
                            >
                                back
                            </MDButton>
                        )}
                        <MDButton
                            // disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                        >
                            {isLastStep ? "submit" : "next"}
                        </MDButton>
                    </MDBox>
                </MDBox>
            </MDBox>
        </DashboardLayout>
    );
}