
import React, { useState, useEffect} from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import { BarLoader } from "react-spinners";
import { useFormikContext } from "formik";

import FormField from "../FormField";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Input,
} from "@mui/material";
import { getProductregistrationbyrecstatus } from "app/productRegistration/service/prodctRegistration";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

function ProductDetail({ formData }) {

  const { setFieldValue, setFieldError } = useFormikContext(); // Access Formik context

  const [color] = useState("#344767");
  const dispatch = useDispatch()
  const [profileId, setProfileId] = useState(JSON.parse(localStorage.getItem("mmu-auth")).profileid)
 

  const { formField, values, errors, touched } = formData;
  const {productregistration, loading} = useSelector((state) => state.productregistration);
  console.log("productregistration",productregistration)

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    // Validation logic...
    // For example, let's assume you want to ensure that the "brand" field is not empty onBlur.
    // if (!value) {
    //   setFieldError(name, "Brand is required");
    // } else {
    //   setFieldError(name, ""); // Clear the error if it's valid
    // }
  };

  const { invoice,lastcomplaint } = formField;

  const {

    product: productV,
  purchasesource:purchasesourceV,
    callsource:callsourceV,
    callnature:callnatureV,
    purchasesubsource:purchasesubsourceV,
    callreason:callreasonV,
    invoice:invoiceV,
    complaintpriority:complaintpriorityV,
    lastcomplaint:lastcomplaintV
      
  } = values;
  useEffect(() => {
    dispatch(getProductregistrationbyrecstatus(profileId))
   
  }, []);
  

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Product Detail</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>
    
    
        <MDBox mt={1.625}>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            
              <FormControl fullWidth error={!!errors.product && touched.product}>
                <InputLabel htmlFor="product" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Select Product <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}>*</sup></InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="product"
                  id="product"
                  value={productV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                   <option value=""></option>
                  {productregistration.map((option) => (
                                <option
                                  key={option.id}
                                  value={JSON.stringify(option)}
                                >
                                  {option.product?.brand?.name+" "+option.product?.product?.name+" "+option.product?.model?.name}
                                </option>
                              ))}
                              
                  
                 
          
                </Select>
                {errors.product && touched.product && (
                  <FormHelperText error style={{marginLeft:"0px"}}>{errors.product}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
            <div style={{marginTop: window.innerWidth <= 700 ? "0px" : "auto"}}>
              <FormField
                type={invoice.type}
                label={invoice.label}
                name={invoice.name}
                value={invoiceV}
                placeholder={invoice.placeholder}
                error={errors.invoice && touched.invoice}
                success={invoiceV.length > 0 && !errors.invoice}
              />
           </div>
            </Grid>
          </Grid>
         
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <div style={{marginTop: window.innerWidth <= 700 ? "15px" : "auto"}}>
              <FormControl fullWidth error={!!errors.complaintpriority && touched.complaintpriority}>
                <InputLabel htmlFor="complaintpriority" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Complaint Priority <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}>*</sup></InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="complaintpriority"
                  id="complaintpriority"
                  value={complaintpriorityV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value=""></option>
                  
                 
                  <option value="Normal">Normal</option>
                  <option value="Urgent">Urgent</option>
                  
                
                </Select>
                {errors.complaintpriority && touched.complaintpriority && (
                  <FormHelperText error style={{marginLeft:"0px"}}>{errors.complaintpriority}</FormHelperText>
                )}
              </FormControl>
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
            <div style={{marginTop: window.innerWidth <= 700 ? "6px" : "auto"}}>
              <FormField
                type={lastcomplaint.type}
                label={lastcomplaint.label}
                name={lastcomplaint.name}
                value={lastcomplaintV}
                placeholder={lastcomplaint.placeholder}
                error={errors.lastcomplaint && touched.lastcomplaint}
               
              />
           </div>
            </Grid>
          </Grid>
         
        </MDBox>
 
    </MDBox>
  );
}

ProductDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};





export default ProductDetail;

