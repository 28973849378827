import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../FormField";


function ContactDetail({ formData }) {
  const { formField, values, errors, touched } = formData;
  
  const { mobilenumber, email,alternatephonenumber } = formField;
  const { mobilenumber: mobilenumberV, email: emailV, alternatephonenumber:alternatephonenumberV} = values;
  const data = JSON.parse(values.product)
  console.log("dataaaaaa",data)
  values.mobilenumber = data.phonenumber
  values.email = data.email

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Contact Detail
      </MDTypography>
      <MDBox mt={1.625}>
      <Grid container spacing={3}>
            

      <Grid item xs={12} sm={6}>
              <FormField
                type={mobilenumber.type}
                label={mobilenumber.label}
                name={mobilenumber.name}
                value={mobilenumberV}
                placeholder={mobilenumber.placeholder}
                error={errors.mobilenumber && touched.mobilenumber}
                success={mobilenumberV.length > 0 && !errors.mobilenumber}
                disabled="true"
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
            <div style={{marginTop: window.innerWidth <= 700 ? "-20px" : "auto"}}>
              <FormField
                type={email.type}
                label={email.label}
                name={email.name}
                value={emailV}
                placeholder={email.placeholder}
                error={errors.email && touched.email}
                 success={emailV.length > 0 && !errors.email}
                disabled="true"
              />
              </div>
            </Grid>
            
          </Grid>
          <Grid container spacing={3}>
            

      <Grid item xs={12} sm={6}>
              <FormField
                type={alternatephonenumber.type}
                label={alternatephonenumber.label}
                name={alternatephonenumber.name}
                value={alternatephonenumberV}
                placeholder={alternatephonenumber.placeholder}
                error={errors.alternatephonenumber && touched.alternatephonenumber}
                 success={alternatephonenumberV.length > 0 && !errors.alternatephonenumber}
              
              />
            </Grid>
            
            
            
          </Grid>
          
      </MDBox>
    </MDBox>
  );
}

// typechecking props for ContactDetail
ContactDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ContactDetail;
