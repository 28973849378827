import swal from 'sweetalert';
const initialState = {
    auth: localStorage.getItem("mmu-auth") ? JSON.parse(localStorage.getItem("mmu-auth")) : {},
    Username: localStorage.getItem("profile-Name") ? JSON.parse(localStorage.getItem("profile-Name")) : {},
   // Investment: localStorage.getItem("investment-profile") ? JSON.parse(localStorage.getItem("investment-profile")) : {},
    isAuthenticated: localStorage.getItem("mmu-auth") ? true : false,

    errors: {},

    loading: false
}
const AuthReducer = (state = initialState, action) => {

    switch (action.type) {
        case "LOGIN_PENDING":
            return { loading: true }

        case 'LOGIN_FULFILLED':
            swal("Welcome Back!", action.payload?.data?.dto.name, "success");
          
            if (action.payload?.data?.dto !== "") {
                console.log("Login");
                localStorage.setItem('mmu-auth', JSON.stringify(action.payload?.data?.dto))
                localStorage.setItem('profile-Name', JSON.stringify(action.payload?.data?.dto.name))
                return {
                    
                    auth: action.payload?.data?.dto,
                    isAuthenticated: true,
                    loading: false     

                }
                

            }
            else {
                swal("Alert!", "...Internal Server Error!");
                localStorage.removeItem('mmu-auth')
                localStorage.removeItem('profile-Name')
               // localStorage.removeItem('investment-profile')
                return {
                    auth: {},
                    Username: {},
                  //  Investment:{},
                    isAuthenticated: false
                }
            }
        case "LOGIN_REJECTED":
            swal("Alert!", "...Incorrect Username or Password!");
            localStorage.removeItem('mmu-auth')
            localStorage.removeItem('profile-Name')
           // localStorage.removeItem('investment-profile')
            return {
                auth: {},
                Username: {},
              //  Investment:{},
                isAuthenticated: false,
                errors: action.payload,
                loading: false
            }

        case "LOGOUT_FULFILLED":
            localStorage.removeItem('mmu-auth')
            localStorage.removeItem('profile-Name')
          //  localStorage.removeItem('investment-profile')
            return {
                auth: {},
                Username: {},
              //  Investment:{},
                isAuthenticated: false
            }
        default:
            return state
    }
}

export default AuthReducer