// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import {  FormHelperText } from "@mui/material";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import {  Button, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MDButton from "components/MDButton";

function PurchaseDetail({ formData }) {
  const fileInputRef = useRef(null); // Create a ref for the file input element
  const { formField, values, errors, touched } = formData;
  const currentDate = new Date().toISOString().split('T')[0];
  const defaultDateofPurchase = currentDate;
  const [selectedFile, setSelectedFile] = useState(formData.values.document || null);
  const { dateofPurchase, purchaseofRetailer, 
    } = formField;
  const { dateofPurchase:dateofPurchaseV,
    
    purchaseofRetailer:purchaseofRetailerV } = values;
     values.dateofPurchase=  dateofPurchaseV || defaultDateofPurchase
 
   
  
   
    const handleFileChange = (e) => {
      const file = e.target.files[0];
    
      // Reset the error message for the document field
      formData.errors.document = "";
    
      formData.values.document = file;
      setSelectedFile(file);
    };
    const handleChooseFileClick = () => {
      // Trigger the file input's click event
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };
    
  
 
  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
       Purchase Detail
      </MDTypography>
      <MDBox mt={1.625}>
      

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={dateofPurchase.type}
              label={dateofPurchase.label}
              name={dateofPurchase.name}
              value={dateofPurchaseV} 
              placeholder={dateofPurchase.placeholder}
              error={errors.dateofPurchase && touched.dateofPurchase}
              success={dateofPurchaseV.length > 0 && !errors.dateofPurchase}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          <div style={{marginTop: window.innerWidth <= 700 ? "-20px" : "auto"}}>
            <FormField
              type={purchaseofRetailer.type}
              label=<span>{purchaseofRetailer.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
              name={purchaseofRetailer.name}
              value={purchaseofRetailerV}
              placeholder={purchaseofRetailer.placeholder}
              error={errors.purchaseofRetailer && touched.purchaseofRetailer}
              success={purchaseofRetailerV.length > 0 && !errors.purchaseofRetailer}
            />
            </div>
          </Grid>
          </Grid>
          <Grid container spacing={3}>
      <Grid item xs={12} sm={3}>
      <div style={{marginTop: window.innerWidth <= 700 ? "10px" : "auto"}}>
        <Typography variant="body2">Upload Document <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></Typography>
        <input
          type="file"
          name="document"
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <MDButton
          variant="outlined"
          color="info"
          startIcon={<CloudUploadIcon />}
          onClick={handleChooseFileClick}
        >
          {selectedFile ? 'Change File' : 'Choose File'}
        </MDButton>
        {errors.document && (
          <FormHelperText error>{errors.document}</FormHelperText>
        )}
        </div>
      </Grid>
      {selectedFile && (
        <Grid item xs={12} sm={3}>
          <Typography variant="body2">Selected File:</Typography>
          <MDTypography variant="body2" color="info">
            {selectedFile.name}
          </MDTypography>
        </Grid>
      )}
    </Grid>
          
          
      </MDBox>
    </MDBox>
  );
}

// typechecking props for PurchaseDetail
PurchaseDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PurchaseDetail;

