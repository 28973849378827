 import * as Yup from "yup";



export const validationdata = Yup.object({

  mpin: Yup.string().min(6).max(6).required("Please enter your mpin"),

  confirm_mpin: Yup.string()
    .required()
    .oneOf([Yup.ref('mpin'), null], "MPIN must match"),
 
});

