
import React, { useState,useEffect } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import { BarLoader } from "react-spinners";
import { useFormikContext } from "formik";

import FormField from "../FormField";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Input,
} from "@mui/material";
import { getDelar } from "app/createdelar/server/delar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

function MoreDetail({ formData }) {
  const dispatch =useDispatch()

  const { setFieldValue, setFieldError } = useFormikContext(); // Access Formik context

  const [color] = useState("#344767");
 

  const { formField, values, errors, touched } = formData;
  const {delar, loading} = useSelector((state) => state.delar);

  console.log(delar)
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };
  

  const handleBlur = (event) => {
    const { name, value } = event.target;

    // Validation logic...
    // For example, let's assume you want to ensure that the "brand" field is not empty onBlur.
    // if (!value) {
    //   setFieldError(name, "Brand is required");
    // } else {
    //   setFieldError(name, ""); // Clear the error if it's valid
    // }
  };

  const { specialinstruction,lastcomplaint } = formField;

  const {
specialinstruction:specialinstructionV,
    Dealer: dealerV,
  purchasesource:purchasesourceV,
    callsource:callsourceV,
    callnature:callnatureV,
    purchasesubsource:purchasesubsourceV,
    callreason:callreasonV,
    invoice:invoiceV,
    complaintpriority:complaintpriorityV,
    lastcomplaint:lastcomplaintV,
    calltype:calltypeV,
    closedreason:closedreasonV,
    wallmount:wallmountV,
    fullreport:fullreportV
  } = values;
  useEffect(() => {
    dispatch(getDelar())
   
  }, []);
  
  
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">More Detail</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>
    
    
        <MDBox mt={1.625}>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            
          
            <FormControl fullWidth error={!!errors.dealer && touched.dealer}>
                <InputLabel htmlFor="dealer" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Select Dealer <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}>*</sup></InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="dealer"
                  id="dealer"
                  value={values.dealer? values.dealer :dealerV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                <option value=""></option>
                   {delar.map((option) => (
                                <option
                                  key={option.id}
                                  value={JSON.stringify(option)}
                                >
                                  {option.name}
                                </option>
                              ))}
                
                
                </Select>
                {errors.dealer && touched.dealer && (
                  <FormHelperText error style={{marginLeft:"0px"}}>{errors.dealer}</FormHelperText>
                )}
              </FormControl> 



            </Grid>
            <Grid item xs={12} sm={6}>
            <div style={{marginTop: window.innerWidth <= 700 ? "0px" : "auto"}}>
            <FormControl fullWidth error={!!errors.calltype && touched.calltype}>
            
              <InputLabel htmlFor="calltype" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Call Type <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}>*</sup></InputLabel>
              
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="calltype"
                id="calltype"
                value={calltypeV}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value=""></option>
                
                <option value="Demo">Demo</option>
                <option value="installation">Installation</option>
                <option value="Service">Service</option>
                <option value="Stock Repair">Stock Repair</option>
                <option value="Warehouse Repair">Warehouse Repair</option>
               
              
              </Select>
              {errors.calltype && touched.calltype && (
                <FormHelperText error style={{marginLeft:"0px"}}>{errors.calltype}</FormHelperText>
              )}
            </FormControl>
            </div>
          </Grid>
          </Grid> 
          <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={6}>
          <div style={{marginTop: window.innerWidth <= 700 ? "0px" : "auto"}}>
            
              <FormControl fullWidth >
                <InputLabel htmlFor="closedreason" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Closed Reason</InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="closedreason"
                  id="closedreason"
                  value={closedreasonV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value=""></option>
                  
                  <option value="Air Throw">Air Throw</option>
                  <option value="Automatic Shut Down">Automatic Shut Down</option>
                  <option value="Electric Spark">Electric Spark</option>
                
                </Select>
                
              </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
            <div style={{marginTop: window.innerWidth <= 700 ? "0px" : "auto"}}>
            
           <FormControl fullWidth >
             <InputLabel htmlFor="closedreason" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Wall mount</InputLabel>
             <Select
               native
               variant="standard"
               style={{ width: "100%" }}
               name="wallmount"
               id="wallmount"
               value={wallmountV}
               onChange={handleChange}
               onBlur={handleBlur}
             >
               <option value=""></option>
               
               <option value="Yes">Yes</option>
               <option value="No">No</option>
              
             
             </Select>
             
           </FormControl>
           </div>
         </Grid>
            </Grid>
           
         
            <Grid container spacing={3} mt={1}>
            <Grid item xs={12} sm={6}>
            <div style={{marginTop: window.innerWidth <= 700 ? "0px" : "auto"}}>
              <FormControl fullWidth error={!!errors.fullreport && touched.fullreport}>
                <InputLabel htmlFor="fullreport" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Fault Report <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}>*</sup></InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="fullreport"
                  id="fullreport"
                  value={fullreportV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value=""></option>
                  
                  <option value="No Picture">No Picture</option>
                  <option value="No Sound">No Sound</option>
                 
                
                </Select>
                {errors.fullreport && touched.fullreport && (
                  <FormHelperText error style={{marginLeft:"0px"}}>{errors.fullreport}</FormHelperText>
                )}
              </FormControl>
              </div>
            </Grid>
           
            
            <Grid item xs={12} sm={6}>
            <div style={{marginTop: window.innerWidth <= 700 ? "5px" : "auto"}}>
              <FormField
                type={specialinstruction.type}
                label={specialinstruction.label}
                name={specialinstruction.name}
                value={specialinstructionV}
                placeholder={specialinstruction.placeholder}
                error={errors.specialinstruction && touched.specialinstruction}
                InputLabelProps={{
                  style: { paddingBottom: "2px" }}}
              />
       </div>
          </Grid>
          </Grid>
          
        </MDBox>
 
    </MDBox>
  );
}

MoreDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};








export default MoreDetail













