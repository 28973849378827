
    
//     phonenumber: {
//       name: "phonenumber",
//       label: "Phonenumber",
//       type: "number",
//       errorMsg: "number is required.",
//     },
   
   



const form = {
  formId: "new-user-form",
  formField: {
    serialNumber: {
      name: "serialNumber",
      label: "Serial Number",
      type: "text",
      errorMsg: "Serial Number is required.",
    },
    
    brand: {
      name: "brand",
      label: "Brand",
      type: "select", // Set the type appropriately
      errorMsg: "Brand is required.",
    },
    product: {
      name: "product",
      label: "Product",
      type: "select", // Set the type appropriately
      errorMsg: "Product is required.",
    },
    modal: {
      name: "modal",
      label: "Model",
      type: "select", // Set the type appropriately
      errorMsg: "Model is required.",
    },
   //purchase detail
    dateofPurchase: {
      name: "dateofPurchase",
      label: "Purchase Date",
      type: "date",
    
      errorMsg: "Date is required.",
    },
   
  
    purchaseofRetailer: {
      name: "purchaseofRetailer",
      label: "Purchase from Retailer",
      type: "text",
      errorMsg: "Purchase from Retailer is required.",
      
    },
   
    document: {
      name: "document",
      label: "Upload Invoice",
      type: "text",
      errorMsg: "Document is required.",
      
    },
//personal Detail
title: {
  name: "title",
  label: "Title",
  type: "Select",
  errorMsg: "Title is required.",
},


firstname: {
  name: "firstname",
  label: "First Name",
  type: "text",
  errorMsg: "Firstname is required.",
  
},
lastname: {
  name: "lastname",
  label: "Last Name",
  type: "text",
  errorMsg: "Lastname is required.",
  
},
//contact Detail
email: {
      name: "email",
      label: "Email Address",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    mobilenumber: {
      name: "mobilenumber",
      label: "Mobile number",
      type: "number",
      errorMsg: "Number is required.",
      
    },
    alternatephonenumber: {
      name: "alternatephonenumber",
      label: "Alternate Mobile Number",
      type: "number",
      errorMsg: "Alternatephonenumber is required.",
      
    },
    address: {
      name: "address",
      label: "Address",
      type: "radio",
      errorMsg: "Address is required.",
      
    },
   
  },
};

export default form;
