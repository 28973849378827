import { createSlice } from "@reduxjs/toolkit";
import {  createproductregistration, fileUpload, getProductregistration, getProductregistrationbyrecstatus } from "../service/prodctRegistration";


const initialState = {
  productregistration: [],
  fileUpload: [],
  loading: false,
  error: null,
};

export const productregistrationcrud = createSlice({
  name: "createproductregistration",
  initialState,
  extraReducers: {
    [createproductregistration.pending]: (state) => {
      state.loading = true;
    },
    [createproductregistration.fulfilled]: (state, action) => {
      console.log("state",state.productregistration)
      state.loading = false;
      state.productregistration.push(action.payload.data.dto);
    },
    [createproductregistration.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
   
    [getProductregistration.pending]: (state) => {
      state.loading = true;
    },
    [getProductregistration.fulfilled]: (state, action) => {
      state.loading = false;
      state.productregistration = action.payload.data.dtoList;
    },
    [getProductregistration.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [fileUpload.pending]: (state) => {
      state.loading = true;
    },
    [fileUpload.fulfilled]: (state, action) => {
      console.log("state",state.fileUpload)
      state.loading = false;
      state.fileUpload.push(action.payload?.data?.dto);
    },
    [fileUpload.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getProductregistrationbyrecstatus.pending]: (state) => {
      state.loading = true;
    },
    [getProductregistrationbyrecstatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.productregistration = action.payload.data.dtoList;
    },
    [getProductregistrationbyrecstatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    
  },
});

export default productregistrationcrud.reducer;
