import { createSlice } from "@reduxjs/toolkit";
import { createModal, deleteModal, getModal, updateModal } from "../service/Modal";





const initialState = {
  modal: [],
  loading: false,
  error: null,
};

export const modalDetail = createSlice({
  name: "modalDetail",
  initialState,
  extraReducers: {
    [createModal.pending]: (state) => {
      state.loading = true;
    },
    [createModal.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.modal.push(action.payload.data.dto);
    },
    [createModal.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getModal.pending]: (state) => {
      state.loading = true;
    },
    [getModal.fulfilled]: (state, action) => {
      state.loading = false;
      state.modal = action.payload.data.dtoList;
    },
    [getModal.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteModal.pending]: (state) => {
      state.loading = true;
    },
    [deleteModal.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.modal = state.modal.filter((modal) => modal.id !== id);
      }
    },
    [deleteModal.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateModal.pending]: (state) => {
      state.loading = true;
    },
    [updateModal.fulfilled]: (state, action) => {
      state.loading = false;
    
      const index = state.modal.findIndex(modal => modal.id === action.payload.data.dto.id)
      state.modal[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateModal.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default modalDetail.reducer;
