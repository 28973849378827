
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../../axiosinstance";
//create address

export const createAddress = createAsyncThunk("createAddress", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `address-service-mongo/api/address/v2/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getAddress = createAsyncThunk("getAddress", async (profileid, {rejectWithValue}) => {
 

  try {
    const response = await axios.get(
     
      `address-service-mongo/api/address/v2/get?profileid=${profileid}`
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateAddress = createAsyncThunk("updateAddress", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `address-service-mongo/api/address/v2/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteAddress = createAsyncThunk("deleteAddress", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `address-service-mongo/api/address/v2/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

//pincode search api =========================
export const getpincode = createAsyncThunk("getpincode", async (pincode, {rejectWithValue}) => {

  try {
    const response = await axios.get(
     
      `pincode-service/api/pincode/v1/get?type=CITY&pincode=${pincode}`
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getlocality = createAsyncThunk("getlocality", async (data, {rejectWithValue}) => {
 
console.log(data)
  try {
    const response = await axios.get(
     
      `pincode-service/api/pincode/v1/get?type=LOCALITY&pincode=${data.pincode}&city.code=${data.code}`
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

//state search api================
export const getState = createAsyncThunk("getState", async ( {rejectWithValue}) => {
 

  try {
    const response = await axios.get(
     
      `pincode-service/api/pincode/v1/get?type=STATE`
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCity = createAsyncThunk("getCity", async (data, {rejectWithValue}) => {
 

  try {
    const response = await axios.get(
     
      `pincode-service/api/pincode/v1/get?type=CITY&state.code=${data}`
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getVillage = createAsyncThunk("getVillage", async (data, {rejectWithValue}) => {
 
  try {
    const response = await axios.get(
     
      `pincode-service/api/pincode/v1/get?type=LOCALITY&state.code=${data.statecode}&city.code=${data.citycode}`
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
