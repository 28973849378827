
  import checkout from "../schemas/form";
 
  const {
    formField: {
      purchasesource,
    callby,
    callsource,
   callnature,
   callreason,
     //puchasedetail
     complaintpriority,
      purchasesubsource,
   document,
   //personal Detail
   title,
   firstname,
    lastname,
     //personal Detail
   email,
   mobilenumber,
   alternatephonenumber,
   address,
   product,
   invoice,
   lastcomplaint,
   dealer,
   calltype,
   closedreason,
   wallmount,
   specialinstruction,
   fullreport,date,slot,ampm,timeofresolution
    },
  } = checkout;

  const initialValues = {
  
    [callsource.name]: "",
   
    [callby.name]: "",
 [date.name]:"",
 [purchasesource.name]: "",
 [closedreason.name]: "",
 [callreason.name]: "",
 [wallmount.name]:"",
    [complaintpriority.name]: "",
    [purchasesubsource.name]:"",
//purchase detail
[calltype.name]: "",
    [purchasesource.name]: "",
    [fullreport.name]: "",
    [ampm.name]: "",
  
  [callnature.name]:"",
  [invoice.name]:"",
   //personal Detail
   [title.name]: "",
   [firstname.name]:"",
    [lastname.name]: "", 
[lastcomplaint.name]:"",
[dealer.name]:"",
[slot.name]:"",
   //contact Detail
   [mobilenumber.name]: "",
   [email.name]: "", 
   [alternatephonenumber.name]: "",
   // addresss
   [address.name]: "",
   [product.name]: "",
   [specialinstruction.name]:"",
   [timeofresolution.name]:""
   
  };

  export default initialValues;
