// import React, { useEffect, useState } from "react";
// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";

// import { useFormik } from "formik";
// import Grid from "@mui/material/Grid";

// import * as Yup from "yup";
// import { useDispatch } from "react-redux";

// import swal from "sweetalert";

// import MDButton from "components/MDButton";
// import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";
// import { MoonLoader } from "react-spinners";
// import {
//   Newnumberchangeotp,
//   conformnewnumberchangeotp,
// } from "../reducer/server";

// const validationSchema = Yup.object({
//   mobileNumber: Yup.string()
//     .required("Mobile number is required")
//     .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
//   otp: Yup.string().when(["mobileNumber", "showOTPInput"], {
//     is: (mobileNumber, showOTPInput) => showOTPInput && !!mobileNumber,
//     then: Yup.string()
//       .required("OTP is required")
//       .matches(/^\d{6}$/, "OTP must be 6 digits"),
//   }),
// });
// const Numberpopup = (props) => {
//   const { title, openpopup, childToparent, setopenpopup } = props;

//   const initialValues = {
//     mobileNumber: "",
//     otp: "",
//   };

//   const [showOTPInput, setShowOTPInput] = useState(false);
//   const [profileId, setProfileId] = useState(
//     JSON.parse(localStorage.getItem("mmu-auth")).profileid
//   );
//   const [isloading, setLoading] = useState(false);
//   const handleClose = () => {
//     setopenpopup(false);
//     setShowOTPInput(false);
//     values.mobileNumber = "";
//     values.otp = "";
//   };

//   const {
//     values,
//     errors,
//     touched,
//     handleBlur,
//     handleChange,
//     handleSubmit,
//     isSubmitting,
//     setSubmitting,
//   } = useFormik({
//     initialValues: initialValues,
//     validationSchema: validationSchema,
//     onSubmit: async (values, { setSubmitting }) => {
//       setLoading(true);
//       if (!showOTPInput && values.mobileNumber.length === 10) {
//         try {
//           // Call the API function with form values

//           setShowOTPInput(true);
//           const object = {
//             userid: profileId,
//             otptype: "SMS",
//             typevalue: "DIRECT",
//             resourcecode: "JAINA",
//             resourcename: "Jaina India",
//             createdby: "",
//             recstatus: "OPEN",
           
//             status: "ACTIVE",
//           };

//           await Newnumberchangeotp(object);
//           setLoading(false);
//         } catch (error) {
//           // Handle any API errors or other errors
//           swal(
//             "Oops!",
//             error.response.data.aceErrors[0].errorMessage,
//             "warning"
//           );
//           setLoading(false);
//         }
//       } else if (showOTPInput) {
//         setLoading(true);
//         try {
//           await conformnewnumberchangeotp(profileId, values.otp);

//           // Handle the response data as needed

//           swal("Good job!", "Number has been change sucessfully", "success");
//           childToparent(values.mobileNumber);

//           handleClose();
//           setLoading(false);
//         } catch (error) {
//           // Handle any API errors or other errors
//           swal(
//             "Oops!",
//             error.response.data.aceErrors[0].errorMessage,
//             "warning"
//           );
//           setLoading(false);
//         }
//       }
//       setSubmitting(false);
//       setLoading(false);
//     },
//   });
//   return (
//     <Dialog open={openpopup} onClose={handleClose}>
//       <DialogTitle
//         style={{ backgroundColor: "blue", color: "#fff", textAlign: "center" }}
//       >
//         {title}
//       </DialogTitle>
//       <form onSubmit={handleSubmit}>
//         <DialogContent>
//           <MDBox pb={3} px={3}>
//             <Grid item xs={12}>
//               <MDInput
//                 fullWidth
//                 name="mobileNumber"
//                 id="mobileNumber"
//                 type="text"
//                 label=" New Mobile Number"
//                 autoComplete="off"
//                 value={values.mobileNumber}
//                 onBlur={handleBlur}
//                 onChange={handleChange}
//               />
//               {errors.mobileNumber && touched.mobileNumber && (
//                 <p
//                   style={{ fontWeight: "400", color: "red", fontSize: "12px" }}
//                 >
//                   {errors.mobileNumber}
//                 </p>
//               )}
//             </Grid>
//             {showOTPInput && (
//               <Grid item xs={12} mt={3}>
//                 <MDInput
//                   fullWidth
//                   name="otp"
//                   id="otp"
//                   type="number"
//                   label="OTP"
//                   autoComplete="off"
//                   value={values.otp}
//                   onBlur={handleBlur}
//                   onChange={handleChange}
//                 />
//                 {errors.otp && touched.otp && (
//                   <p
//                     style={{
//                       fontWeight: "400",
//                       color: "red",
//                       fontSize: "12px",
//                     }}
//                   >
//                     {errors.otp}
//                   </p>
//                 )}
//               </Grid>
//             )}
           
//           </MDBox>
//         </DialogContent>
//         <DialogActions>
//           <MDButton
//             variant="gradient"
//             color="success"
//             size="small"
//             onClick={handleClose}
//           >
//             Cancel
//           </MDButton>

//           <MDButton
//             size="small"
//             variant="gradient"
//             color="primary"
//             type="submit"
//             disabled={isSubmitting}
//           >
//             {isloading ? (
//               <MoonLoader color="#f2fefa" size={16} />
//             ) : showOTPInput ? (
//               "Submit OTP"
//             ) : (
//               "Submit"
//             )}
//           </MDButton>
//         </DialogActions>
//       </form>
//     </Dialog>
//   );
// };

// export default Numberpopup;



import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { MoonLoader } from "react-spinners";
import { Newnumberchangeotp, conformnewnumberchangeotp } from "../reducer/server";

const validationSchema = Yup.object({
  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
  otp: Yup.string().when(["mobileNumber", "showOTPInput"], {
    is: (mobileNumber, showOTPInput) => showOTPInput && !!mobileNumber,
    then: Yup.string()
      .required("OTP is required")
      .matches(/^\d{6}$/, "OTP must be 6 digits"),
  }),
});

const Numberpopup = (props) => {
  const { title, openpopup, childToparent, setopenpopup } = props;

  const initialValues = {
    mobileNumber: "",
    otp: "",
  };

  const [showOTPInput, setShowOTPInput] = useState(false);
  const [profileId, setProfileId] = useState(
    JSON.parse(localStorage.getItem("mmu-auth")).profileid
  );
  const [isloading, setLoading] = useState(false);
  const [remainingTime, setRemainingTime] = useState(300); // 300 seconds = 5 minutes

  useEffect(() => {
    let timer;
    if (showOTPInput && remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000); // Decrease the remaining time every second
    } else if (!showOTPInput) {
      // Reset the timer when the OTP input is not shown
      setRemainingTime(300);
    }

    return () => {
      clearInterval(timer); // Cleanup the timer when the component unmounts or changes
    };
  }, [showOTPInput, remainingTime]);

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  const handleClose = () => {
    setopenpopup(false);
    setShowOTPInput(false);
    setRemainingTime(300); // Reset the timer when the dialog is closed
    values.mobileNumber="",
    values.otp=""
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      if (!showOTPInput && values.mobileNumber.length === 10) {
        try {
          // Call the API function with form values

          setShowOTPInput(true);
          const object = {
            userid: values.mobileNumber,
            otptype: "SMS",
            typevalue: "DIRECT",
            resourcecode: "JAINA",
            resourcename: "Jaina India",
            createdby: "",
            recstatus: "OPEN",
            status: "ACTIVE",
          };

          await Newnumberchangeotp(object);
          setLoading(false);
        } catch (error) {
          // Handle any API errors or other errors
         
          sweetAlert("Oops...", error.response.data.aceErrors[0].errorMessage, "error");
          setLoading(false);
        }
      } else if (showOTPInput) {
        setLoading(true);
        try {
          await conformnewnumberchangeotp(values.mobileNumber, values.otp);

          // Handle the response data as needed

          swal("Good job!", "Number has been changed successfully", "success");
          childToparent(values.mobileNumber);

          handleClose();
          setLoading(false);
        } catch (error) {
          // Handle any API errors or other errors
          sweetAlert("Oops...", error.response.data.aceErrors[0].errorMessage, "error");
          setLoading(false);
        }
      }
      setSubmitting(false);
      setLoading(false);
    },
  });

  return (
    <Dialog open={openpopup} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle style={{ backgroundColor: "blue", color: "#fff", textAlign: "center" }}>
        {title}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <MDBox pb={3} px={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDInput
                  fullWidth
                  name="mobileNumber"
                  id="mobileNumber"
                  type="text"
                  label="New Mobile Number"
                  autoComplete="off"
                  value={values.mobileNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.mobileNumber && touched.mobileNumber && (
                  <p style={{ fontWeight: "400", color: "red", fontSize: "12px" }}>
                    {errors.mobileNumber}
                  </p>
                )}
              </Grid>
              {showOTPInput && (
                <Grid item xs={12}>
                  <MDInput
                    fullWidth
                    name="otp"
                    id="otp"
                    type="text"
                    label="OTP"
                    autoComplete="off"
                    value={values.otp}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.otp && touched.otp && (
                    <p style={{ fontWeight: "400", color: "red", fontSize: "12px" }}>
                      {errors.otp}
                    </p>
                  )}
                </Grid>
              )}
            </Grid>
            {showOTPInput && (
              <MDBox style={{ textAlign: "center" }} mt={1}>
     
          <p style={{color:"blue",fontWeight:"bold"}}>OTP will expire in {minutes} minutes {seconds} seconds.</p>
          </MDBox>
      )}
          </MDBox>
        </DialogContent>
       
        <DialogActions>
          <MDButton variant="gradient" color="success" size="small" onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton
            size="small"
            variant="gradient"
            color="primary"
            type="submit"
            disabled={isSubmitting}
          >
            {isloading ? (
              <MoonLoader color="#f2fefa" size={16} />
            ) : showOTPInput ? (
              "Submit OTP"
            ) : (
              "Submit"
            )}
          </MDButton>
        </DialogActions>
      </form>
      
    </Dialog>
  );
};

export default Numberpopup;
