/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Autocomplete } from "@mui/material";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data

import { Link } from "react-router-dom";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";
import { FaRegEdit } from "react-icons/fa";
//EDit button

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
   
function UsersListTable() {
  const [loading, setLoading] = useState(true);
  const [tableDatas, setTableDatas] = useState({});
  const [color] = useState("#344767");
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = useState({});
  const [username, setUsername] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstname, setFirstName] = useState("");
  
  const [roleList, setRoleList] = useState("");
  const[ selectitem,setSelectItem]=useState('')
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const defaultProps = {
    options: !roleList ? [{ name: "Loading...", id: 0 }] : roleList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  async function getRoleData() {
    await axios
      .get(
        `https://apithf.softwaremathematics.com/role-service-mongo/api/role/v2/get`
      )
      .then((response) => {
        setRoleList(response.data.data.dtoList);
        getData(response.data?.data?.dtoList);
        console.log("81",response.data?.data?.dtoList)
       
       
      })

      .catch((e) => {
        console.log("error", e);
      });
  }
  async function onUpdatedata(object,rolelist) {
    setSelectItem({ name: "Loading...", id: 0, })
   
         setRoleList(rolelist);
   
    console.log("hii", object);
    for (let i=0;i<rolelist.length;i++){

      if(rolelist[i].name===object.rolename){
        console.log("900",rolelist[i])
        setSelectItem(rolelist[i]);
      }
    }
    setItem(object);
    setUsername(object.username);
    setFirstName(object.firstname);
    setLastName(object.lastname);
    setEmail(object.emailid);
    setPhoneNo(object.phoneno);
    setOpen(true);
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  async function onDelete(item) {
    if (window.confirm("Are you sure that you want to Delete That User Record"))
      item.modifiedby =
        JSON.parse(localStorage.getItem("mmu-auth")).firstname +
        " " +
        JSON.parse(localStorage.getItem("mmu-auth")).lastname;
    item.modifieddate = new Date();
    await axios
      .put(
        "https://apithf.softwaremathematics.com/profile-service-mongo/api/ProfileEntity/v2/delete",
        item
      )
      .then((response) => {
        console.log(response);
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function updateData() {
    item.username = username;
    item.emailid = email;
    item.phoneno = phoneNo;
    item.firstname=firstname;
    item.lastname=lastName;
    item.modifiedby =
      JSON.parse(localStorage.getItem("mmu-auth")).firstname +
      " " +
      JSON.parse(localStorage.getItem("mmu-auth")).lastname;
    item.modifieddate = new Date();

    await axios
      .put(
        "https://apithf.softwaremathematics.com/profile-service-mongo/api/ProfileEntity/v2/update",
        item
      )
      .then((response) => {
        setOpen(false);
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getData(typelist) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "username", accessor: "username", width: "12%" },
        { Header: "fullname", accessor: "fullname",width: "15%" },
        { Header: "role", accessor: "rolecode", width: "13%" },
        { Header: "emailId", accessor: "emailid",  },
        { Header: "phoneNo", accessor: "phoneno", width: "8%" },
        // { Header: "created by", accessor: "createdby" },
       
        { Header: "action", accessor: "action", width: "12%" },
      ],
      rows: [],
    };
    axios
      .get(
        `https://apithf.softwaremathematics.com/profile-service-mongo/api/ProfileEntity/v2/get`
      )
      .then((response) => {
        console.log("====================================");
        console.log(response);
        console.log("====================================");
        response.data.data.dtoList.map((item) => {
          if (item.firstname != null) {
            item.fullname = "" + item.firstname + " ";
          }
          if (item.lastname != null) {
            item.fullname += item.lastname;
          }
          item.action = (
            <span>
              <Link to={{}}>
                <FaRegEdit
                  style={iconStyle}
                  onClick={() => {
                    onUpdatedata(item,typelist);
                  }}
                />
              </Link>

              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
   
    getRoleData();
  }, []);
 
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={0}>
        <MDBox mb={0}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <MDTypography variant="h5" fontWeight="medium">
                  User List
                </MDTypography>
                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    size: "20px",
                    color: "#344767",
                  }}
                  to={"/pages/users/new-user"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update User</DialogTitle>
        <DialogContent style={{ width: 550, height: 350 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="User Name"
              variant="standard"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <span style={{ color: "red" }}>{err}</span>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Email"
              variant="standard"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="number"
              label="Phone no"
              variant="standard"
              fullWidth
              value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="FirstName"
              variant="standard"
              fullWidth
              value={firstname}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="LastName"
              variant="standard"
              fullWidth                  
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultProps}
              value={selectitem}
              onChange={(event,newValue) => {
                // handleChange(event);
                item.rolename = newValue.rolecode;
           
           
                 setSelectItem(newValue);

                // values.department = newValue.hierarchicalcode;
                console.log("281", selectitem)

              }}
              renderInput={(params) => (
                <MDInput
                  {...params}

                  variant="standard"
                  label="Role"
                />
              )}
            />

          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} disabled={!username}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default UsersListTable;
