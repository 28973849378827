import { createSlice } from "@reduxjs/toolkit";
import { getCity } from "../service/Address";





const initialState = {
  cities: [],
  loading: false,
  error: null,
};

export const cities = createSlice({
  name: "citiescrud",
  initialState,
  reducers: {
    resetStateList: (state) => {
      state.cities = [];
    },
  },
  extraReducers: {
    [getCity.pending]: (state) => {
      state.loading = true;
    },
    [getCity.fulfilled]: (state, action) => {
      state.loading = false;
      state.cities = action.payload.data.dtoList;
    },
    [getCity.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { resetStateList } = cities.actions; 
export default cities.reducer;
