import { createSlice } from "@reduxjs/toolkit";
import { createProduct, deleteProduct, getProduct, updateProduct } from "../service/Product";
;



const initialState = {
  product: [],
  loading: false,
  error: null,
};

export const productDetail = createSlice({
  name: "productDetail",
  initialState,
  extraReducers: {
    [createProduct.pending]: (state) => {
      state.loading = true;
    },
    [createProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.product.push(action.payload.data.dto);
    },
    [createProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getProduct.pending]: (state) => {
      state.loading = true;
    },
    [getProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.product = action.payload.data.dtoList;
    },
    [getProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteProduct.pending]: (state) => {
      state.loading = true;
    },
    [deleteProduct.fulfilled]: (state, action) => {
      state.loading = false;
      const { id } = action.payload.data.dto;
      if (id) {
        state.product = state.product.filter((product) => product.id !== id);
      }
    },
    [deleteProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateProduct.pending]: (state) => {
      state.loading = true;
    },
    [updateProduct.fulfilled]: (state, action) => {
      state.loading = false;
      const index = state.product.findIndex(product => product.id === action.payload.data.dto.id)
      state.product[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default productDetail.reducer;
