import { createSlice } from "@reduxjs/toolkit";
import { getserailnumber } from "./serialapi";



const initialState = {
    listsize: 0,
  serial: [],
  loading: false,
  error: null,
};

export const serialnumber = createSlice({
  name: "createserail",
  initialState,
  extraReducers: {
   
   
    [getserailnumber.pending]: (state) => {
      state.loading = true;
    },
    [getserailnumber.fulfilled]: (state, action) => {
        console.log(action)
      state.loading = false;
      state.serial = action.payload.data.dtoList;
      state.listsize=action.payload.data.listSize
    },
    [getserailnumber.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
   
    
  },
});

export default serialnumber.reducer;
