  import React, { useEffect, useState } from "react";

  import ProductCell from "layouts/dashboards/sales/components/ProductCell";
  import nikeV22 from "assets/images/ecommerce/blue-shoe.jpeg";
  import DataTable from "examples/Tables/DataTable";
  import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
  import DashboardNavbar from "examples/Navbars/DashboardNavbar";
  import { getProductregistration } from "./service/prodctRegistration";
  import { useSelector } from "react-redux";
  import { useDispatch } from "react-redux";
  import { Link, useNavigate } from "react-router-dom";
  import { CircularProgress } from "@mui/material";

  import Viewpopup from "./components/view popup";
  import PreviewIcon from '@mui/icons-material/Preview';

  const MyProduct = () => {
    const dispatch = useDispatch();
    
    
    const { productregistration, loading } = useSelector(
      (state) => state.productregistration
    );
    const [profileId, setProfileId] = useState(
      JSON.parse(localStorage.getItem("mmu-auth")).profileid
    );
    const [openpopup, setopenpopup] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    
  const viewproductDetail=(item)=>{
  
    setopenpopup(true);
      setSelectedProduct(item)

  }

    useEffect(() => {
      dispatch(getProductregistration(profileId));
    }, []);

    if (loading) {
      // Render a centered circular loader
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    return (
      <DashboardLayout>
        <DashboardNavbar />

        <DataTable
          canSearch={true}
          table={{
            columns: [
              {
                Header: "photo",
                accessor: "photo",

                Cell: ({ row }) => (
                  <div>
                    <ProductCell
                      image={nikeV22}
                      name={row.original?.product?.brand?.name+" "+row.original.product?.product?.name}
                      orders= {row?.original?.product?.model?.name}
                    />
                  </div>
                ),
              },

              { Header: "warranty start", accessor: "dateofpurchase" },

{
  Header: "warranty end",
  accessor: (row) => {
    // Check if necessary properties exist
    if (
      row &&
      row.dateofpurchase &&
      row.product &&
      row?.product?.model &&
      row?.product?.model?.iswarranty !== undefined &&
      row?.product?.model?.iswarranty == true &&
      row?.product?.model?.warrantyinmonth !== undefined
    ) {
     
      const purchaseDate = new Date(row?.dateofpurchase);
      const warrantyMonths = parseInt(row?.product?.model?.warrantyinmonth, 10);

      if (!isNaN(warrantyMonths)) {
        purchaseDate.setMonth(purchaseDate.getMonth() + warrantyMonths);

        // Get the exact date
        var day = purchaseDate.getDate();
        var month = purchaseDate.getMonth() + 1; 
        var year = purchaseDate.getFullYear();

       
       

        return `${year}-${month}-${day}`;
      } else {
        console.log("Invalid warranty duration");
        return "N/A";
      }
    } else {
      console.log("Invalid or missing data for calculating warranty expiration");
      return "N/A"; 
    }
  },
},



              
              {
                Header: "Name",
                accessor: "fullname",
                Cell: ({ row }) => (
                  <span>
                    {row?.original?.lastname
                      ? row?.original?.firstname + " " + row?.original?.lastname
                      : row?.original?.firstname}
                  </span>
                ),
              },
              { Header: "serial number", accessor: "product.serialnumber" },
              {
          Header: 'Status',
          accessor: 'recstatus',
          Cell: ({ value }) => (
            <span
              style={{
                color: value === 'PENDING' ? 'red' : value === 'APPROVED' ? 'green' : 'black',
              }}
            >
              {value}
            </span>
          ),
        },
              {
                Header: "Action",
                accessor: "action",

                Cell: ({ row }) => (
                  <div>
                    <Link to={{}}>
                      <PreviewIcon
                    fontSize="medium"
                        onClick={() => {
                          viewproductDetail(row.original);
                        }}
                      />
                    </Link>
                  </div>
                ),
              },
            ],
            rows: productregistration || [],
          }}
        />
        <Viewpopup
          openpopup={openpopup}
          setopenpopup={setopenpopup}
        title={"Product Detail"}
        selectedProduct={selectedProduct}
        />
      </DashboardLayout>
    );
  };

  export default MyProduct;
