import { createSlice } from "@reduxjs/toolkit";
import { getState } from "../service/Address";





const initialState = {
  states: [],
  loading: false,
  error: null,
};

export const states = createSlice({
  name: "statescrud",
  initialState,
  reducers: {
    resetStateList: (state) => {
      state.states = [];
    },
  },
  extraReducers: {
    [getState.pending]: (state) => {
      state.loading = true;
    },
    [getState.fulfilled]: (state, action) => {
      state.loading = false;
      state.states = action.payload.data.dtoList;
    },
    [getState.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { resetStateList } = states.actions; 
export default states.reducer;
