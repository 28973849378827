// // prop-type is a library for typechecking of props
// import PropTypes from "prop-types";
// import * as React from "react";

// // @mui material components
// import Grid from "@mui/material/Grid";

// // Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import { Link } from "react-router-dom";
// import { Autocomplete } from "@mui/material";

// // NewUser page components
// import FormField from "layouts/pages/qualification/new-qualification/components/FormField";


// import { useState } from "react";

// import { BarLoader } from "react-spinners";
// import MDInput from "components/MDInput";
// import MDDatePicker from "components/MDDatePicker";


// function QualificationInfo({ formData }) {
//   const [loading, setLoading] = useState(false);
//   const [modevalue, setModeValue] = useState(null);
//   const [typeValue, setTypeValue] = useState(null);
//   const [typelist, setTypelist] = useState("");
//   const [modelist, setModelist] = useState("");
//   const [color] = useState("#344767");



//   const { formField, values, errors, touched } = formData;

//   const { qualificationName, mode, board, type, grade, startDate,
//      endDate } =
//     formField;
//   var {
//     qualificationName: qualificationNameV,
//     mode: modeV,
//     board: boardV,
//     grade: gradeV,
//     endDate: endDateV,
//     startDate: startDateV,
//   } = values;
  

//   const defaultPropsdocumentypeMode = {
//     options: !modelist
//       ? [
//         { name: "Regular", code: "X" },    
//         { name: "Open", code: "y" },
//       ]
//       : modelist,
//     getOptionLabel: (option) => option.name,
//     isOptionEqualToValue: (option, value) => option.code === value.code,
//   };


//   const defaultPropsdocumentype = {
//     options: !typelist
//       ? [
//         { name: "Percentage", code: "X" },
//         { name: "CGP", code: "y" },
//       ]
//       : typelist,
//     getOptionLabel: (option) => option.name,
//     isOptionEqualToValue: (option, value) => option.code === value.code,
//   };



//   return (
//     <MDBox>
//       {/* <MDBox lineHeight={0}>
        
//       </MDBox> */}
//       <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
//       {!loading && (
//         <MDBox mt={1.625}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
//               <FormField
//                 type={qualificationName.type}
//                 label={qualificationName.label}
//                 name={qualificationName.name}
//                 value={qualificationNameV}
//                 placeholder={qualificationName.placeholder}
//                 error={errors.qualificationName && touched.qualificationName}
//                 success={qualificationNameV.length > 0 && !errors.qualificationName}
//               />
//             </Grid>
//             {/* <Grid item xs={12} sm={6}>
//               <FormField
//                 type={mode.type}
//                 label={mode.label}
//                 name={mode.name}
//                 value={modeV}
//                 placeholder={mode.placeholder}
//                 error={errors.mode && touched.mode}
//                 success={modeV.length > 0 && !errors.mode}
//               />
//             </Grid> */}
//             <Grid item xs={12} sm={6}>

              // <Autocomplete
              //   {...defaultPropsdocumentypeMode}
              //   value={modevalue}
              //   onChange={(event, newValue) => {
              //     // handleChange(event);

              //     setModeValue(newValue);

              //     values.mode = newValue?.name;
              //     console.log(newValue);
              //   }}
              //   renderInput={(params) => (
              //     <MDInput
              //       {...params}
              //       // error={errors.type && touched.type}
              //       // success={type.length > 0 && !errors.type}
              //       variant="standard"
              //       label={mode.label}
              //     />
              //   )}
              // />
//             </Grid>
//           </Grid>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>

              // <FormField
              //   type={board.type}
              //   label={board.label}
              //   name={board.name}
              //   value={boardV}
              //   placeholder={board.placeholder}
              //   error={errors.board && touched.board}
              //   success={boardV.length > 0 && !errors.board}
              // />

//             </Grid>

//             <Grid item xs={12} sm={6}>

              // <Autocomplete
              //   {...defaultPropsdocumentype}
              //   value={typeValue}
              //   onChange={(event, newValue) => {
              //     // handleChange(event);

              //     setTypeValue(newValue);

              //     values.type = newValue?.name;
              //     console.log(newValue);
              //   }}
              //   renderInput={(params) => (
              //     <MDInput
              //       {...params}
              //       // error={errors.type && touched.type}
              //       // success={type.length > 0 && !errors.type}
              //       variant="standard"
              //       label={type.label}
              //     />
              //   )}
              // />
//             </Grid>
//           </Grid>

//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>

//               <FormField
//                 type={grade.type}
//                 label={grade.label}
//                 name={grade.name}
//                 value={gradeV}
//                 placeholder={grade.placeholder}
//                 error={errors.grade && touched.grade}
//                 success={gradeV.length > 0 && !errors.grade}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//             <MDTypography variant="button" color="text">
//             Start Date
//         </MDTypography>
         
//             <FormField
//             style={{marginTop:"-9px"}}
//                 type={startDate.type}
//                 label={startDate.label}
//                 name={startDate.name}
//                 value={startDateV}
//                 placeholder={startDate.placeholder}
//                 error={errors.startDate && touched.startDate}
//                 success={startDateV.length > 0 && !errors.startDate}
//               />
//               {/* <MDDatePicker 
               
//                input={{ placeholder: "Select a Start Date" }} /> */}
//                {/* <MDInput type="date" label="Date" value="2018-11-23" /> */}
//             </Grid>

//           </Grid>

//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
        //     <MDTypography variant="button" color="text">
        //     End Date
        // </MDTypography>
        //       <FormField
        //        style={{marginTop:"-9px"}}
        //         type={endDate.type}
        //         // label={endDate.label}
        //         name={endDate.name}
        //         value={endDateV}
        //         placeholder={endDate.placeholder}
        //         error={errors.endDate && touched.endDate}
        //         success={endDateV.length > 0 && !errors.endDate}
        //       />
            
//             </Grid>
//           </Grid>

//         </MDBox>
//       )}
//     </MDBox>
//   );
// }

// // typechecking props for QualificationInfo
// QualificationInfo.propTypes = {
//   formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

// export default QualificationInfo;




// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import { Autocomplete } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
// NewUser page components
import FormField from "layouts/pages/qualification/new-qualification/components/FormField";


import { useState } from "react";

import { BarLoader } from "react-spinners";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import MDButton from "components/MDButton";
import { FaRegTimesCircle } from "react-icons/fa";

function QualificationInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const dataObj = {
    columns: [
      { Header: "qualification name", accessor: "qualificationname" },
      { Header: "mode", accessor: "qualificationmode" },
      { Header: "Board", accessor: "qualificationboard" },
      { Header: "grade", accessor: "qualificationgrade" },
      { Header: "startdate", accessor: "startdate" },
      { Header: "enddate", accessor: "enddate"},
     
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };
  const [tableData, setTableData] = useState(dataObj);
  const [modevalue, setModeValue] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [typelist, setTypelist] = useState("");
  const [modelist, setModelist] = useState("");
  const [color] = useState("#344767");



  const { formField, values, errors, touched } = formData;

  const { qualificationName, mode, board, type, grade, startDate,
     endDate } =
    formField;
  var {
    qualificationName: qualificationNameV,
    mode: modeV,
    board: boardV,
    grade: gradeV,
    endDate: endDateV,
    startDate: startDateV,
  } = values;
  

  const defaultPropsdocumentypeMode = {
    options: !modelist
      ? [
        { name: "Regular", code: "X" },    
        { name: "Open", code: "y" },
      ]
      : modelist,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };


  const defaultPropsdocumentype = {
    options: !typelist
      ? [
        { name: "Percentage", code: "X" },
        { name: "CGP", code: "y" },
      ]
      : typelist,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };
  const removeItem = function (uniqueKey) {

    const objWithIdIndex = tableData.rows.findIndex((obj) => obj.startdate == uniqueKey);
    tableData.rows.splice(objWithIdIndex, 1);
    setTableData(tableData);
     values.qualificationDataList  = tableData.rows;
  };



  const addData = (data) => {
    console.log("336",data);
    const qualificationData = {
     
      addresstype: "",
      approvedby: "",
     approveddate: "",
       approver: "",
       approvertype: "",
      classname: "",
      createdby: "ANKIT",
        createddate: "",
      documentofproof: "",
      enddate: data.endDate,
      id: null,
       latlong: "",
      
       modifiedby: "",
        modifieddate: "",
        profileid: "",
      qualificationboard: data.board,
      qualificationgrade: data.grade,
      qualificationgradetype: data.type,
      qualificationmode: data.mode,
      qualificationname: data.qualificationName,
      qualificationtype: "",
      recstatus: "OPEN",
      startdate: data.startDate,
       status: "ACTIVE",
      syncstatus: "synced",
      tempid: ""
        
   };
   console.log("368", qualificationData);
   
   qualificationData.action = (
    <span>

        <Link to={{}}>
            <FaRegTimesCircle
                style={{
                    color: "#f44336",
                    width: "25px",
                    height: "25px",
                    cursor: "pointer",

                }}
                onClick={() => removeItem(qualificationData.startdate)}
            />
        </Link>

    </span>
);



let filter = tableData.rows.filter(
    (a) => a.startdate == qualificationData.startdate
);

if (filter.length < 1) {

    tableData.rows.push(qualificationData);
    dataObj.rows = tableData.rows;
    
    setTableData(dataObj);
     values.qualificationDataList  = tableData.rows;
}
console.log("150",tableData)

   
   
  };

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Address</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <FormField
                type={qualificationName.type}
                label={qualificationName.label}
                name={qualificationName.name}
                value={qualificationNameV}
                placeholder={qualificationName.placeholder}
                error={errors.qualificationName && touched.qualificationName}
                success={qualificationNameV.length > 0 && !errors.qualificationName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <Autocomplete
                {...defaultPropsdocumentypeMode}
                value={modevalue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setModeValue(newValue);

                  values.mode = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.type && touched.type}
                    // success={type.length > 0 && !errors.type}
                    variant="standard"
                    label={mode.label}
                  />
                )}
              />
            </Grid>
          </Grid>
         
         
        
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
          
            <FormField
                type={board.type}
                label={board.label}
                name={board.name}
                value={boardV}
                placeholder={board.placeholder}
                error={errors.board && touched.board}
                success={boardV.length > 0 && !errors.board}
              />
            </Grid>
          

         
            <Grid item xs={12} sm={6}>
            <Autocomplete
                {...defaultPropsdocumentype}
                value={typeValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setTypeValue(newValue);

                  values.type = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.type && touched.type}
                    // success={type.length > 0 && !errors.type}
                    variant="standard"
                    label={type.label}
                  />
                )}
              />
            </Grid>
            </Grid>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
          
            <FormField
                type={grade.type}
                label={grade.label}
                name={grade.name}
                value={gradeV}
                placeholder={grade.placeholder}
                error={errors.grade && touched.grade}
                success={gradeV.length > 0 && !errors.grade}
              />
              
            </Grid>
          

         
            <Grid item xs={12} sm={6}>
                         <MDTypography variant="button" color="text">
             Start Date
        </MDTypography>
                       <FormField
            style={{marginTop:"-9px"}}
                type={startDate.type}
                label={startDate.label}
                name={startDate.name}
                value={startDateV}
                placeholder={startDate.placeholder}
                error={errors.startDate && touched.startDate}
                success={startDateV.length > 0 && !errors.startDate}
              />
               
            </Grid>
            </Grid>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
          
            <MDTypography variant="button" color="text">
            End Date
        </MDTypography>
              <FormField
               style={{marginTop:"-9px"}}
                type={endDate.type}
                // label={endDate.label}
                name={endDate.name}
                value={endDateV}
                placeholder={endDate.placeholder}
                error={errors.endDate && touched.endDate}
                success={endDateV.length > 0 && !errors.endDate}
              />
              
            </Grid>
           
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                style={{ marginTop: "10px" }}
                onClick={()=>addData(values)}
              >
                Add
              </MDButton>
            </Grid>
            <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",
              marginTop: "25px",
            }}
          >
            <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              table={tableData}
            />
          </Grid>
           
           
          
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for QualificationInfo
QualificationInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default QualificationInfo;