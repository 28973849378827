import checkout from "layouts/pages/slotcreation/new slotcreation/schemas/form";
const {
  formField: {
   address,
   slot,
    count,
  
    date,
    startTime,
    endTime,
   
  },
} = checkout;

const initialValues = {
  [address.name]:"",
  [slot.name]:"",
  [count.name]: "",
  [slot.name]: "",
  [date.name]: "",

   [startTime.name]: "",
   [endTime.name]: "",
  // [mapware.name]: "",
};

export default initialValues;
