import * as Yup from "yup";

export const validationdata = Yup.object({
  product: Yup.string()
    .required("Please enter  product name"),
    
 brand: Yup.string()
    .required("Please select a brand"),
});

