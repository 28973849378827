
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create brand

export const createProduct = createAsyncThunk("createProduct", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `category-service/api/Category/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getProduct = createAsyncThunk("getProduct", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.get(
      `category-service/api/Category/v1/get?type=PRODUCT`,
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateProduct = createAsyncThunk("updateProduct", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `category-service/api/Category/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteProduct = createAsyncThunk("deleteProduct", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `category-service/api/Category/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




