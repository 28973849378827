import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getProduct, updateProduct } from "../service/Product";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";

const Productedit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedProduct, openpopup, setopenpopup } = props;
  const { brand, loading } = useSelector((state) => state.brand);
 const [branddefaultvalue,setbranddefaultvalue]= useState("")

  // Validation schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required("Product Name is required"),
    brand: Yup.string().required("Brand Name is required"),
    description: Yup.string(),
   
  });

  
 console.log("selectedProduct",selectedProduct)
   
 async function getbranddefaultvalue(){
  const result= await brand.filter(brand => brand.hierarchicalcode === selectedProduct?.parenthierarchicalcode)
  console.log("rerererr",result)
  setbranddefaultvalue(result[0]?.hierarchicalcode)


 }
 
  // Formik form setup
  const formik = useFormik({
    initialValues: {
      name: selectedProduct?.name || "",
      description: selectedProduct?.desc || "",
      brand:  branddefaultvalue || "",
     
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("submitvalue",values)
      // Create a copy of selectedProduct with updated values
      const updatedProduct = {
        ...selectedProduct,
        desc: values.description.trim(),
          name: values.name.trim(),
          recstatus: "OPEN",
          resourcecode: "JAINA",
          status: "Active",
          syncstatus: "SYNCED",
          type: "PRODUCT",
          parenthierarchicalcode:values.brand,
          hierarchicalcode:values.brand
       
         // Update parentdepcode with brand
      };
      console.log("payload",updatedProduct)

      // Dispatch the action to update the product in the Redux store
      dispatch(updateProduct(updatedProduct));

      // Close the dialog
      // dispatch(getProduct());
      setopenpopup(false);
    },
  });

  useEffect(() => {
    getbranddefaultvalue()
    // Update the form values when selectedProduct changes
    formik.setValues({
      name: selectedProduct?.name || "",
      description: selectedProduct?.desc || "",
     
      brand:  branddefaultvalue || "",
    });
  
  }, [selectedProduct, branddefaultvalue]);

  const handleClose = () => {
    setopenpopup(false);
    

  };

  return (
    <Dialog open={openpopup} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "#fff",
          textAlign: "center",
        }}
      >{title}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent style={{ maxwidth: 550, height: 230 }}>
          <MDBox mb={2} mt={1}>
            <MDInput
              type="text"
              label="Product Name"
              variant="standard"
              fullWidth
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name && formik.errors.name ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.name}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor="brand" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"8px" }}>Select Brand</InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="brand"
                id="brand"
                value={formik.values.brand}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.brand && Boolean(formik.errors.brand)}
              >
               
                {brand.map((option) => (
                  <option key={option.id} value={option.hierarchicalcode}>
                    {option.name}
                  </option>
                ))}
              </Select>
              {formik.touched.brand && formik.errors.brand && (
                <p
                  style={{
                    fontWeight: "400",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {formik.errors.brand}
                </p>
              )}
            </FormControl>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Product Description"
              variant="standard"
              fullWidth
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Update</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Productedit;
