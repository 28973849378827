import axios from "../../axiosinstance"

export const logIn = (data) => async (dispatch) => {
    dispatch({ type: "LOGIN_PENDING", payload: "" })
    try {

        const resp = await axios.post('login-service-mongo/api/loginservice/v2/login', data)

        dispatch({ type: "LOGIN_FULFILLED", payload: resp.data })
       
    } catch (error) {
        dispatch({ type: "LOGIN_REJECTED", payload: error.response?.data })
    }
   
}
