const form = {
  formId: "new-slot-form",
  formField: {
    address: {
      name: "address",
      label: "Address Selection",
      type: "text",
      errorMsg: "Address name is required.",
    },
    slot: {
      name: "slot",
      label: "Slot Per Patient",
      type: "text",
      errorMsg: "Slot is required.",
    },
    date: {
      name: "date",
      
      type: "date",
      errorMsg: "Date is required.",
    },
    startTime: {
      name: "startTime",
      label: "Start Time",
      type: "time",
      errorMsg: "StartTime is required.",
    },
    endTime: {
      name: "endTime",
      label: "End Time",
      type: "time",
      errorMsg: "EndTime is required.",
    },


    count: {
      name: "count",
      label: "Average Count Per Slot",
      type: "text",
      errorMsg: "Count is required.",
    },

   

   
    AssignEquipment: {
      name: "AssignEquipment",
      label: "Assign Equipment",
      type: "text",
      errorMsg: "AssignEquipment is required.",
    },
    EquipmentList: {
      name: "Equipment",
      label: "Equipment",
      type: "text",
      errorMsg: "Equipment is required.",
    },
  },
};

export default form;
