/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Pboard: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "layouts/pages/qualification/new-qualification/schemas/form";

const {
  formField: {
    qualificationName,
   
    board,
    startDate,
    grade,
    endDate,
mode,

     type,

  },
} = checkout;

const initialValues = {
  [qualificationName.name]: "",
  
  [board.name]: "",
  [endDate.name]: "",
  [grade.name]: "",
  [type.name]: "",
  [mode.name]:"",
  [startDate.name]: "",

};

export default initialValues;