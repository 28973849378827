
// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import MDInput from "components/MDInput";
// import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
// import { useFormik } from "formik";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";

// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";
// import { validationdata } from "../schemas";
// import Footer from "examples/Footer";
// import { getBrand } from "app/createBrand/service/Brand";
// import CircularProgress from "@mui/material/CircularProgress";
// import { createProduct } from "../service/Product";
// import { useNavigate } from "react-router-dom";


// const initialValues = {
//   product: "",
//   productdescrpition:"",
 
//   brand: "", // Added gender field to initialValues
// };

// function CreateProduct() {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { brand, loading } = useSelector((state) => state.brand);
 

//   const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
//     useFormik({
//       initialValues: initialValues,
//       validationSchema: validationdata,
//       onSubmit: async (values, action) => {
//         const requestData = {
//           desc: values.product.trim(),
//           name: values.productdescrpition.trim(),
//           recstatus: "OPEN",
//           resourcecode: "JAINA",
//           status: "Active",
//           syncstatus: "SYNCED",
//           type:"PRODUCT"
//         };
//          dispatch(createProduct(requestData));
//          action.resetForm()
//          navigate("/app/productlist/product-list");
         
      
        
//       },
//     });

//   useEffect(() => {
//     // Fetch brand data when the component mounts
//     dispatch(getBrand());
//   }, [dispatch]);
  
//   // Function to handle changes in the selected gender
//   const handleBrandChange = (event) => {
//     handleChange(event); // Handle change for Formik
//     values.gender = event.target.value; // Manually set the gender value
//   };
//   if (loading) {
//     // Render a centered circular loader
//     return (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "100vh",
//         }}
//       >
//         <CircularProgress />
//       </div>
//     );
//   }

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox py={3} mb={20} height="65vh">
//         <Grid
//           container
//           justifyContent="center"
//           alignItems="center"
//           sx={{ height: "100%", mt: 8 }}
//         >
//           <Grid item xs={12} lg={8}>
//             <form onSubmit={handleSubmit}>
//               <Card>
//                 <MDBox mx={2} mt={-3}>
//                   <MDBox
                    
//                     bgColor="info"
//                     borderRadius="lg"
//                     coloredShadow="success"
//                     mx={2}
//                     mt={-3}
//                     p={2}
//                     mb={-2}
//                     textAlign="center"
//                   >
//                     <MDTypography
//                       variant="h4"
//                       fontWeight="small"
//                       color="white"
//                       mt={1}
//                     >
//                       Product Master
//                     </MDTypography>
//                   </MDBox>
//                 </MDBox>

//                 <MDBox p={3}>
//                   <Grid container spacing={3}>
//                   <Grid item xs={12} sm={6}>
                  
                 
//                       <MDInput
//                       variant="standard"
//                    style={{width:"350px"}}
//                         name="product"
//                         id="product"
//                         type="text"
//                         label="Product Name"
//                         autoComplete="off"
//                         value={values.product}
//                         onBlur={handleBlur}
//                         onChange={handleChange}
//                       />
//                       {errors.product && touched.product && (
//                         <p
//                           style={{
//                             fontWeight: "400",
//                             color: "red",
//                             fontSize: "12px",
//                           }}
//                         >
//                           {errors.product}
//                         </p>
//                       )}
//                     </Grid>


// <Grid item xs={12} sm={6}>
//                       <MDInput
//                       variant="standard"
//                    style={{width:"350px"}}
//                         name="productdescrpition"
//                         id="productdescrpition"
//                         type="text"
//                         label="Product Description"
//                         autoComplete="off"
//                         value={values.productdescrpition}
//                         onBlur={handleBlur}
//                         onChange={handleChange}
//                       />
                     
//                     </Grid>
//                   </Grid>

//                   <Grid container spacing={3} mt={1}>
//                     <Grid item xs={12} sm={6}>
//                       <FormControl>
//                         <InputLabel htmlFor="brand">Select Brand</InputLabel>
//                         <Select
//                           native
//                           variant="standard"
//                           style={{ width: "350px" }}
//                           name="brand"
//                           id="brand"
//                           label="brand"
//                           value={values.brand}
//                           onBlur={handleBlur}
//                           onChange={handleBrandChange}
                         
//                         >
//                           <option value=""></option>
//                           {brand.map((option) => (
//                             <option
//                               key={option.id}
//                               value={option.hierarchicalcode}
//                             >
//                               {option.name}
//                             </option>
//                           ))}
//                         </Select>
//                         {errors.brand && touched.brand && (
//                           <p
//                             style={{
//                               fontWeight: "400",
//                               color: "red",
//                               fontSize: "12px",
//                             }}
//                           >
//                             {errors.brand}
//                           </p>
//                         )}
//                       </FormControl>
//                     </Grid>
//                   </Grid>

//                   <MDBox
//                     mt={5}
//                     width="100%"
//                     display="flex"
//                     justifyContent="space-between"
//                   >
//                           <MDButton type="submit" variant="gradient" color="dark">
//                           Submit
//                         </MDButton>
//                   </MDBox>
//                 </MDBox>
//               </Card>
//             </form>
//           </Grid>
//         </Grid>
//       </MDBox>
//       <Footer />
//     </DashboardLayout>
//   );
// }

// export default CreateProduct;


import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDInput from "components/MDInput";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import useMediaQuery from "@mui/material/useMediaQuery"; // Import useMediaQuery for breakpoints
import useTheme from "@mui/material/styles/useTheme"; // Import useTheme to access theme breakpoints
import { MoonLoader } from "react-spinners";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { validationdata } from "../schemas";
import Footer from "examples/Footer";
import { getBrand } from "app/createBrand/service/Brand";
import CircularProgress from "@mui/material/CircularProgress";
import { createProduct } from "../service/Product";
import { useNavigate } from "react-router-dom";

const initialValues = {
  product: "",
  productdescrpition: "",
  brand: "", // Added gender field to initialValues
};

function CreateProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { brand} = useSelector((state) => state.brand);
  const { error, loading } = useSelector((state) => state.product);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationdata,
      onSubmit: async (values, action) => {
        console.log(values)
        const requestData = {
          desc: values.productdescrpition.trim(),
          name: values.product.trim(),
          recstatus: "OPEN",
          resourcecode: "JAINA",
          status: "Active",
          syncstatus: "SYNCED",
          type: "PRODUCT",
          parenthierarchicalcode:values.brand,
          hierarchicalcode:values.brand
        };
        // dispatch(createProduct(requestData));
        // action.resetForm();
        // navigate("/app/productlist/product-list");

        
        const response = await dispatch(createProduct(requestData));
    

        if (!response.error && !loading) {
          action.resetForm();
          navigate("/app/productlist/product-list");
        } else {
          action.resetForm();
          swal({
            icon: "error",
            title: "Error",
            text: error.message,
          });
        }
      },
    });

  useEffect(() => {
    // Fetch brand data when the component mounts
    dispatch(getBrand());
  }, [dispatch]);

  // Function to handle changes in the selected gender
  const handleBrandChange = (event) => {
    handleChange(event); // Handle change for Formik
    values.brand = event.target.value; 
  };

  // Get the current theme
  const theme = useTheme();

  // Use media queries to check for screen size
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={isLargeScreen ? 8 : 12}>
            <form onSubmit={handleSubmit}>
              <Card>
                <MDBox mx={2} mt={-3}>
                  <MDBox
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={-2}
                    textAlign="center"
                  >
                    <MDTypography
                      variant="h4"
                      fontWeight="small"
                      color="white"
                      mt={1}
                    >
                      Product Master
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox p={3}>
                <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} sm={isMediumScreen ? 6 : 12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="brand" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }} >Select Brand <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="brand"
                          id="brand"
                          label="brand"
                          value={values.brand}
                          onBlur={handleBlur}
                          onChange={handleBrandChange}
                        >
                          <option value=""></option>
                          {brand.map((option) => (
                            <option
                              key={option.id}
                              value={option.hierarchicalcode}
                            >
                              {option.name}
                            </option>
                          ))}
                        </Select>
                        {errors.brand && touched.brand && (
                          <p
                            style={{
                              fontWeight: "400",
                              color: "red",
                              fontSize: "12px",
                            }}
                          >
                            {errors.brand}
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={isSmallScreen ? 2 : 3} mt={0}>
                    <Grid item xs={12} sm={isMediumScreen ? 6 : 12}>
                      <MDInput
                        variant="standard"
                        style={{ width: "100%" }}
                        name="product"
                        id="product"
                        type="text"
                        label=<span>Product Name<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                        autoComplete="off"
                        value={values.product}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {errors.product && touched.product && (
                        <p
                          style={{
                            fontWeight: "400",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors.product}
                        </p>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={isMediumScreen ? 6 : 12}>
                      <MDInput
                        variant="standard"
                        style={{ width: "100%" }}
                        name="productdescrpition"
                        id="productdescrpition"
                        type="text"
                        label="Product Description"
                        autoComplete="off"
                        value={values.productdescrpition}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputLabelProps={{
                          style: { paddingBottom: "2px" }}}
                      />
                    </Grid>
                  </Grid>
                <MDBox
                    mt={5}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    {/* <MDButton type="submit" variant="gradient" color="dark">
                      Submit
                    </MDButton> */}


                    <MDButton type="submit" variant="gradient" color="dark">
                          {loading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Submit"
                          )}
                        </MDButton>
                  </MDBox>
                </MDBox>
              </Card>
            </form>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateProduct;
