// import React, { useEffect, useState } from "react";
// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import TextField from "@mui/material/TextField";
// import Grid from "@mui/material/Grid";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
// import { resetCityList } from "../reducer/pincodeslice";

// import { MoonLoader } from "react-spinners";
// import swal from "sweetalert";
// import {
//   createAddress,
//   getlocality,
//   getpincode,
//   updateAddress,
// } from "../service/Address";
// import MDButton from "components/MDButton";
// import { resetLocalityList } from "../reducer/localityslice";

// const validationSchema = Yup.object().shape({
//   pincode: Yup.string().required("Pincode is required"),
//   // state: Yup.string().required("State is required"),

//   city: Yup.string().required("City is required"),
//   locality: Yup.string().required("Locality is required"),
//   landmark: Yup.string().required("Landmark is required"),
//   mainaddress: Yup.string().required("Main address is required"),
// });

// const Addresspopup = (props) => {
//   const dispatch = useDispatch();
//   const { citylist, loading } = useSelector((state) => state.pincode);
//   const { locality } = useSelector((state) => state.locality);

//   const [profileId, setProfileId] = useState(
//     JSON.parse(localStorage.getItem("mmu-auth")).profileid
//   );
//   const [isloading, setLoading] = useState(false);
//   const [statename, setStatename] = useState(
//     citylist.length > 0 ? citylist[0].state?.name : ""
//   );

//   const { editaddress, openpopup, setopenpopup } = props;
//   console.log(editaddress);
//   const [iscreatloading, setCreateloading] = useState(false);

//   const [defaultValues, setDefaultValues] = useState({
//     state: "",
//     pincode: "",
//     city: "",
//     locality: "",
//     landmark: "",
//     mainaddress: "",
//     description: "",
//     alternativeaddress: "",
//     State2: "",
//   });
//   const [selectedPincode, setSelectedpincode] = useState(editaddress?editaddress.pincode.pincode : "" );

//   useEffect(() => {
//     const data = {
//       pincode: editaddress?.pincode?.pincode,
//       code: editaddress?.pincode?.city?.code,
//     };
//     console.log(data);
//     dispatch(getlocality(data));
//     dispatch(getpincode(editaddress?.pincode?.pincode))
//     setDefaultValues({
//       state: "",
//       pincode: editaddress?.pincode?.pincode || "",
//       city: editaddress?.pincode?.city.name || "",
//       locality: editaddress?.pincode?.village?.name || "",
//       landmark: editaddress?.landmark || "",
//       mainaddress: editaddress?.mainaddress || "",
//       description: "",
//       alternativeaddress: editaddress?.alternateaddress || "",
//       State2: "",
//     });
//   }, [editaddress]);

//   const handleClose = () => {
//     setopenpopup(false);
//     setDefaultValues({});
//     dispatch(resetCityList());
//     dispatch(resetLocalityList());
//   };

//   async function serachPincodedata(pincode) {

//     setLoading(true); // Show loader
//     editaddress ? dispatch(resetLocalityList()):null
//     try {
//       await dispatch(getpincode(pincode));

//       setLoading(false); // Show loader

//       // if (!loading && citylist.length > 0) {
//       //   // Set the value of "state" field in the form
//       //   setFieldValue("state", citylist[0].state.name);
//       // }
//       // if (!loading && citylist.length > 0) {
//       //   const newstatelist = [];
//       //   newstatelist.push(citylist[0]);

//       //   setStatelist(newstatelist);
//       // setDefaultValues({
//       //   ...defaultValues,
//       //   state: JSON.stringify(citylist[0]),
//       // });
//       // }
//     } catch (error) {
//       setLoading(false); // Show loader
//       // Handle errors if necessary
//     }
//   }
//   const fetchProductOptions = async (code) => {
//     console.log(code)

//     const data = {
//       pincode: selectedPincode,
//       code: code,
//     };
//     console.log(data);
//     dispatch(getlocality(data));
//   };

//   return (
//     <Dialog open={openpopup} onClose={handleClose}>
//       <DialogTitle style={{ backgroundColor: "#ff5733", color: "#fff" ,textAlign:"center"}}>
//         {editaddress ? "Update Address" : "Add Address"}
//       </DialogTitle>
//       <Formik
//         initialValues={defaultValues}
//         validationSchema={validationSchema}
//         onSubmit={async (values, action) => {
//           setCreateloading(true);
//           values.State2 = citylist[0];
//           console.log(values);
//           values.locality = JSON.parse(values.locality);
//           if(editaddress){
//             alert(values)
//           }
//          else{

//           const requestData = {
//             alternateaddress: values.alternativeaddress,

//             country: "INDIA",

//             id: null,
//             landmark: values.landmark,
//             mainaddress: values.mainaddress,

//             pincode: values.locality,

//             profileid: profileId,
//             recstatus: "OPEN",
//             status: "ACTIVE",
//           };

//           try {
//             // Dispatch the createAddress action or API call with createData
//             await dispatch(createAddress(requestData));
//             swal(
//               "New Address has been created!",
//               "You clicked the button!",
//               "success"
//             );
//             // If the dispatch is successful, you can proceed to the next steps
//             setCreateloading(false);
//             handleClose();
//             action.resetForm();
//           } catch (error) {
//             // Handle errors here, e.g., show an error message to the user
//             console.error("Error creating address:", error);
//             setCreateloading(false);
//           }
//           //   // Dispatch the createAddress action or API call with createData
//           //   dispatch(createAddress(requstdata)); // Assuming you have a createAddress function that takes the data
//           //   setLoading(false)
//           // handleClose();
//           // action.resetForm();
//         }}}
//       >
//         {({ values, setFieldValue, errors, touched }) => (
//           <Form>
//             <DialogContent sx={{ width: 550, height: 350 }}>
//               <Grid container spacing={3} mt={1}>
//                 <Grid item xs={12} sm={4}>
//                   <FormControl fullWidth>
//                     <TextField
//                       label=<p style={{ color: "blue" }}>Pincode</p>
//                       type="text"
//                       name="pincode"
//                       id="pincode"
//                       value={values.pincode}
//                       onChange={(e) => {
//                         const nameValue = e.target.value;
//                         setSelectedpincode(nameValue);
//                         setFieldValue("pincode", nameValue);
//                       }}
//                       variant="standard"
//                       error={touched.pincode && Boolean(errors.pincode)}
//                       helperText={touched.pincode && errors.pincode}
//                     />
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} sm={2} mt={1}>
//                   {/* <MDButton
//                     variant="gradiant"
//                     size="small"
//                     color="info"

//                   >
//                     SEARCH
//                   </MDButton> */}
//                   <MDButton
//                     size="small"
//                     variant="gradient"
//                     color="secondary"
//                     onClick={() => serachPincodedata(selectedPincode)}
//                   >
//                     {isloading ? (
//                       <MoonLoader color="#f2fefa" size={16} />
//                     ) : (
//                       "SEARCH"
//                     )}
//                   </MDButton>
//                 </Grid>

//                 <Grid item xs={12} sm={6} >
//                 <p style={{ color: "blue", fontSize:"12px"}}>State</p>
//                   <FormControl fullWidth>
//                     <TextField
//                       // label=<p style={{ color: "blue" }}>State</p>
//                       type="text"
//                       name="state"
//                       id="state"
//                       value={citylist.length > 0 ? citylist[0].state?.name : editaddress?.pincode?.state?.name}
//                       // onChange={(e) => {
//                       //   const nameValue = e.target.value;
//                       //   setFieldValue("state", nameValue);
//                       // }}
//                       variant="standard"
//                     />
//                   </FormControl>
//                 </Grid>
//               </Grid>
//               <Grid container spacing={3} mt={1}>
//                 <Grid item xs={12} sm={6}>
//                 <p style={{ color: "blue", fontSize:"12px"}}>Select City</p>
//                   <FormControl fullWidth>
//                     {/* <InputLabel htmlFor="city" style={{ color: "blue" }}>
//                       Select City
//                     </InputLabel> */}
//                     <Select
//                       native
//                       variant="standard"
//                       style={{ width: "100%" }}
//                       name="city"
//                       id="city"
//                       value={values.city}
//                       onChange={(e) => {
//                         const selectedCity = e.target.value;
//                         setFieldValue("city", selectedCity);
//                         const selectedCityCode = citylist.find(
//                           (option) => option.city?.name === selectedCity
//                         )?.city?.code;
//                         fetchProductOptions(selectedCityCode);
//                       }}
//                       error={touched.city && Boolean(errors.city)}
//                     >
//                     <option value="" ></option>
//                       {citylist.map((option) => (
//                         <option
//                           key={option.city?.code}
//                           value={option.city?.name}
//                         >
//                           {option.city?.name}
//                         </option>
//                       ))}
//                     </Select>
//                     <ErrorMessage
//                       name="city"
//                       component="div"
//                       style={{ color: "red", fontSize: "12px" }}
//                     />
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                 <p style={{ color: "blue", fontSize:"12px"}}>Locality</p>
//                   <FormControl fullWidth>
//                     {/* <InputLabel htmlFor="city" style={{ color: "blue" }}>
//                       Select Locality
//                     </InputLabel> */}
//                     <Select
//                       native
//                       variant="standard"
//                       style={{ width: "100%" }}
//                       name="locality"
//                       id="locality"
//                       value={values.locality}
//                       onChange={(e) => {
//                         const selectedLocality = e.target.value;
//                         console.log("selectedLocality", selectedLocality);
//                         setFieldValue("locality", selectedLocality);
//                       }}
//                       error={touched.locality && Boolean(errors.locality)}
//                     >
//                       <option value="" ></option>
//                       {locality.map((option) => (
//                         <option key={option.id} value={JSON.stringify(option)}>
//                           {option.village.name}
//                         </option>
//                       ))}
//                     </Select>
//                     <ErrorMessage
//                       name="city"
//                       component="div"
//                       style={{ color: "red", fontSize: "12px" }}
//                     />
//                   </FormControl>
//                 </Grid>
//               </Grid>
//               <Grid container spacing={3} mt={1}>
//                 <Grid item xs={12} sm={6}>
//                   <FormControl fullWidth>
//                     <TextField
//                       label=<p style={{ color: "blue" }}>Main Address</p>
//                       type="text"
//                       name="mainaddress"
//                       id="warrantymonth"
//                       value={values.mainaddress}
//                       onChange={(e) => {
//                         const nameValue = e.target.value;
//                         setFieldValue("mainaddress", nameValue);
//                       }}
//                       variant="standard"
//                       error={touched.mainaddress && Boolean(errors.mainaddress)}
//                       helperText={touched.mainaddress && errors.mainaddress}
//                     />
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <FormControl fullWidth>
//                     <TextField
//                       label=<p style={{ color: "blue" }}>Land Mark</p>
//                       type="text"
//                       name="landmark"
//                       id="landmark"
//                       value={values.landmark}
//                       onChange={(e) => {
//                         const nameValue = e.target.value;
//                         setFieldValue("landmark", nameValue);
//                       }}
//                       variant="standard"
//                       error={touched.landmark && Boolean(errors.landmark)}
//                       helperText={touched.landmark && errors.landmark}
//                     />
//                   </FormControl>
//                 </Grid>
//               </Grid>
//               <Grid container spacing={3} mt={1}>
//                 <Grid item xs={12} sm={6}>
//                   <FormControl fullWidth>
//                     <TextField
//                       label=<p style={{ color: "blue" }}>Alternative Address</p>
//                       type="text"
//                       name="alternativeaddress"
//                       id="alternativeaddress"
//                       value={values.alternativeaddress}
//                       onChange={(e) => {
//                         const nameValue = e.target.value;
//                         setFieldValue("alternativeaddress", nameValue);
//                       }}
//                       variant="standard"
//                       error={
//                         touched.alternativeaddress &&
//                         Boolean(errors.alternativeaddress)
//                       }
//                       helperText={
//                         touched.alternativeaddress && errors.alternativeaddress
//                       }
//                     />
//                   </FormControl>
//                 </Grid>
//               </Grid>
//             </DialogContent>
//             <DialogActions>
//               <Button onClick={handleClose} style={{ color: "#ff5733" }}>
//                 Cancel
//               </Button>
//               <Button
//                 type="submit"
//                 style={{ backgroundColor: "#ff5733", color: "#fff" }}
//               >
//                 {/* {editaddress ? "Update" : "Submit"} */}
//                 {iscreatloading ? (
//                   <MoonLoader color="#f2fefa" size={16} />
//                 ) : (
//                   editaddress ? "Update" : "Submit"
//                 )}
//               </Button>
//             </DialogActions>
//           </Form>
//         )}
//       </Formik>
//     </Dialog>
//   );
// };

// export default Addresspopup;

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { pincode, resetCityList } from "../reducer/pincodeslice";
import { MoonLoader } from "react-spinners";
import swal from "sweetalert";

import {
  createAddress,
  getlocality,
  getpincode,
  getState,
  updateAddress,
  getCity,
  getVillage,
  createLocality,
} from "../service/Address";
import MDButton from "components/MDButton";
import { resetLocalityList } from "../reducer/localityslice";
import { Input } from "@mui/material";
import otp from "layouts/pages/otp/Otp";

// Define validation schema for form fields
const validationSchema = Yup.object().shape({
  pincode: Yup.string().required("Pincode is required"),
  // city: Yup.string().required("City is required"),
  // localityObject: Yup.string().required("Locality is required"),
  landmark: Yup.string().required("Landmark is required"),
  mainaddress: Yup.string().required("Main address is required"),
  // cities: Yup.string().required("City is required"),
});

const Addresspopup = (props) => {
  const dispatch = useDispatch();
  const { citylist, loading } = useSelector((state) => state.pincode);
  const { locality } = useSelector((state) => state.locality);
  const { states } = useSelector((state) => state.states);
  const { cities } = useSelector((state) => state.cities);
  const { village } = useSelector((state) => state.village);
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [selectedCityCode, setSelectedCityCode] = useState("");
  const [selectedLocalityCode, setSelectedLocalityCode] = useState("");
  const [ recStatus, setRecStatus ] = useState("");

  const [profileId, setProfileId] = useState(
    JSON.parse(localStorage.getItem("mmu-auth")).profileid
  );

  const [isloading, setLoading] = useState(false);
  const [statename, setStatename] = useState(
    citylist.length > 0 ? citylist[0].state?.name : ""
  );

  const { editaddress, openpopup, setopenpopup } = props;
  console.log("edittttt", editaddress);
  const [isSelectFocused, setIsSelectFocused] = useState(false);
  const [isSelectFocusedl, setIsSelectFocusedl] = useState(false);

  const handleSelectFocus = () => {
    setIsSelectFocused(true);
  };

  const handleSelectFocusl = () => {
    setIsSelectFocusedl(true);
  };

  const handleSelectBlur = () => {
    setIsSelectFocused(false);
    setIsSelectFocusedl(false);
  };

  const [iscreatloading, setCreateloading] = useState(false);

  const [defaultValues, setDefaultValues] = useState({
    pincode: "",
    // city: "",
    locality: "",
    localityObject: "",
    landmark: "",
    mainaddress: "",
    // description: "",
    alternativeaddress: "",
    // State2: "",
    states: "",
    // cities: "",
    // village: "",
  });

  const [selectedPincode, setSelectedpincode] = useState("");

  useEffect(() => {
    const data = {
      pincode: editaddress?.pincode?.pincode,
      code: editaddress?.pincode?.city?.code,
    };
    dispatch(getlocality(data));
    dispatch(getpincode(editaddress?.pincode?.pincode));
    dispatch(getState(states));

    console.log("editadrress",editaddress);

    setDefaultValues({
      pincode: editaddress?.pincode?.pincode || "",
      city: editaddress?.pincode?.city.name || "",
      locality: editaddress?.pincode?.id || "",
      localityObject: editaddress?.pincode ,
      landmark: editaddress?.landmark || "",
      mainaddress: editaddress?.mainaddress || "",
      // description: "",
      alternativeaddress: editaddress?.alternateaddress || "",
      // State2: "",
      states: editaddress?.pincode?.state?.name || "",
      cities: editaddress?.pincode?.city?.name || "",
      // village: editaddress?.pincode?.village?.name || "",
    });
  }, [editaddress]);

  for(let i =0;i<2;i++){  console.log("Locality data",locality[i]?.village?.code,locality[i]?.village?.name); }

  const handleClose = () => {
    setopenpopup(false);
    setDefaultValues({});
    dispatch(resetCityList());
    dispatch(resetLocalityList());
  };

  async function serachPincodedata(pincode) {
    setLoading(true);
    editaddress ? dispatch(resetLocalityList()) : null;
    try {
      const response = await dispatch(getpincode(pincode));
      console.log("response",response?.payload?.data?.listSize);
      setLoading(false);
      if (!loading && citylist.length > 0) {
        // Set the value of "state" field in the form
        setFieldValue("state", citylist[0]?.state?.name);
      }
      if (!loading && citylist.length > 0) {
        const newstatelist = [];
        newstatelist.push(citylist[0]);

        setStatelist(newstatelist);
        setDefaultValues({
          ...defaultValues,
          state: JSON.stringify(citylist[0]),
        });
      }
      if (response?.payload?.data?.listSize > 0){
        // alert("Pincode found")
        setRecStatus("APPROVED");
      }
      else { 
        swal({text:"No such pincode is present.",icon:"warning"}); 
        setRecStatus("PENDING");
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const fetchProductOptions = async (code) => {
    const data = {
      pincode: selectedPincode ? selectedPincode : editaddress?.pincode?.pincode,
      code: code,
    };
    dispatch(getlocality(data));
  };

  return (
    <Dialog open={openpopup} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "#ff5733",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {editaddress ? "Update Address" : "Add Address"}
      </DialogTitle>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          console.log("values:", values);
          setCreateloading(true);
          editaddress ? values.localityObject : values.localityObject = JSON.parse(values.localityObject);
          editaddress ? null : values.localityObject.pincode = selectedPincode;
          editaddress ? null : values.localityObject.recStatus = recStatus;
          console.log("Object of the locality data",values.localityObject);
          if (editaddress) {
            try {
              const requestData = {
                ...editaddress,
                alternateaddress: values.alternativeaddress,

                landmark: values.landmark,
                mainaddress: values.mainaddress,
                pincode: values.localityObject,
                profileid: profileId,
              };
              await dispatch(updateAddress(requestData));

              setCreateloading(false);
              swal(
                " Address has been sucesfully update!",
                "You clicked the button!",
                "success"
              );
              handleClose();
              action.resetForm();
            } catch (error) {
              console.error("Error updating address:", error);
            }
          } else {
            const requestData = {
              alternateaddress: values.alternativeaddress,
              country: "INDIA",
              id: null,
              landmark: values.landmark,
              mainaddress: values.mainaddress,
              pincode: values.localityObject,
              profileid: profileId,
              recstatus: recStatus,
              status: "ACTIVE",
            };
            try {
              await dispatch(createAddress(requestData));
              swal(
                "New Address has been created!",
                "You clicked the button!",
                "success"
              );
              setCreateloading(false);
              handleClose();
              action.resetForm();
            } catch (error) {
              console.error("Error creating address:", error);
              setCreateloading(false);
            }
          }
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
             {console.log("values",values)}
            <DialogContent sx={{ width: "100%", height: 350 }}>
              <Grid container spacing={3} mt={1}>
                <Grid item xs={12} sm={4}>
                <div style={{width: window.innerWidth <= 600 ? "65%" : "auto"}}>
                  <FormControl fullWidth>
                    <TextField
                      label="Pincode"
                      type="text"
                      name="pincode"
                      id="pincode"
                      value={values.pincode}
                      onChange={(e) => {
                        const nameValue = e.target.value;
                        setSelectedpincode(nameValue);
                        setFieldValue("pincode", nameValue);
                      }}
                      variant="standard"
                      error={touched.pincode && Boolean(errors.pincode)}
                      helperText={touched.pincode && errors.pincode}
                    />
                  </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} mt={1}>
                <div style={{ display: "flex", justifyContent: "end" ,
                position: window.innerWidth <= 600 ? "relative" : "static", 
                top: window.innerWidth <= 600 ? "-70px" : "auto", 
                right: window.innerWidth <= 600 ? "5px" : "auto"
              }}>
                  <MDButton
                    size="small"
                    variant="gradient"
                    color="secondary"
                    onClick={() => serachPincodedata(selectedPincode)}
                  >
                    {isloading ? (
                      <MoonLoader color="#f2fefa" size={16} />
                    ) : (
                      "SEARCH"
                    )}
                  </MDButton>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{marginTop: window.innerWidth <= 600 ? "-70px" : "auto"}}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="states"
                      style={{
                        marginLeft: "-14px",
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                    >
                      State
                    </InputLabel>
                    {citylist.length > 0 ? (
                      <Input
                        type="text"
                        name="states"
                        label="State"
                        id="states"
                        value={citylist[0]?.state?.name}
                        readOnly // Disable the input

                      />
                    ) : (
                      <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="states"
                        id="states"
                        value={values?.states || ""}
                        onChange={(e) => {
                          const selectedStateName = e.target.value;
                          const selectedStateCode = states.find(
                            (option) => option.state?.name === selectedStateName
                          )?.state?.code;
                          setSelectedStateCode(selectedStateCode);
                          setFieldValue("states", selectedStateName);
                          dispatch(getCity(selectedStateCode));
                        }}
                        error={touched.states && Boolean(errors.states)}
                      >
                        <option value=""></option>
                        {states.map((option) => (
                          <option
                            key={option.state?.code}
                            value={option.state?.name}
                          >
                            {option.state?.name}
                          </option>
                        ))}
                      </Select>
                    )}

                    <ErrorMessage
                      name="states"
                      component="div"
                      style={{ color: "red", fontSize: "12px" }}
                    />
                  </FormControl>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3} mt={1}>
                <Grid item xs={12} sm={6}>
                <div style={{marginTop: window.innerWidth <= 600 ? "-40px" : "auto"}}>
                  <FormControl fullWidth>
                  {editaddress ? (
                    <>
                     <InputLabel
                     htmlFor="cities"
                     style={{
                       marginLeft: "-14px",
                       marginTop: "-17px",
                       paddingBottom: isSelectFocused ? "0" : "2px",
                       marginTop: isSelectFocused ? "2px" : "-16px",
                       fontSize: isSelectFocused ? "15px" : "12px",
                     }}
                   >
                     City
                   </InputLabel>
                   <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="cities"
                        id="cities"
                        value={values?.cities || ""}
                        onFocus={handleSelectFocus}
                        onBlur={handleSelectBlur}
                        onChange={(e) => {
                          const selectedCityName = e.target.value;
                          const selectedCityCode = cities.find(
                            (option) => option.city?.name === selectedCityName
                          )?.city?.code;
                          setSelectedCityCode(selectedCityCode);
                          setFieldValue("cities", selectedCityName);

                          const selectedCitylistCode = citylist.find(
                            (option) => option.city?.name === selectedCityName
                          )?.city?.code;
                          fetchProductOptions(selectedCitylistCode);
                          const data = {
                            statecode: selectedStateCode,
                            citycode: selectedCityCode,
                          };
                          dispatch(getVillage(data));
                        }}
                        error={touched.cities && Boolean(errors.cities)}
                      >
                        <option value=""></option>
                        {citylist.length > 0 ? (
                          citylist.map((option) => (
                            <option
                              key={option.city?.code}
                              value={option.city?.name}
                            >
                              {option.city?.name}
                            </option>
                          ))
                        ) : (
                          cities.map((option) => (
                            <option
                              key={option.city?.code}
                              value={option.city?.name}
                            >
                              {option.city?.name}
                            </option>
                          ))
                        )}
                      </Select>
                       </>
                    ) : (
                      <>
                      <InputLabel
                      htmlFor="cities"
                      style={{
                        marginLeft: "-14px",
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                    >
                      City
                    </InputLabel>
                      <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="cities"
                        id="cities"
                        value={values?.cities || ""}
                        onChange={(e) => {
                          const selectedCityName = e.target.value;
                          const selectedCityCode = cities.find(
                            (option) => option.city?.name === selectedCityName
                          )?.city?.code;
                          setSelectedCityCode(selectedCityCode);
                          setFieldValue("cities", selectedCityName);

                          const selectedCitylistCode = citylist.find(
                            (option) => option.city?.name === selectedCityName
                          )?.city?.code;
                          fetchProductOptions(selectedCitylistCode);
                          const data = {
                            statecode: selectedStateCode,
                            citycode: selectedCityCode,
                          };
                          dispatch(getVillage(data));
                        }}
                        error={touched.cities && Boolean(errors.cities)}
                      >
                        <option value=""></option>
                        {citylist.length > 0 ? (
                          citylist.map((option) => (
                            <option
                              key={option.city?.code}
                              value={option.city?.name}
                            >
                              {option.city?.name}
                            </option>
                          ))
                        ) : (
                          cities.map((option) => (
                            <option
                              key={option.city?.code}
                              value={option.city?.name}
                            >
                              {option.city?.name}
                            </option>
                          ))
                        )}
                      </Select>
                      </>
                    )}
                    <ErrorMessage
                      name="cities"
                      component="div"
                      style={{ color: "red", fontSize: "12px" }}
                    />
                  </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                <div style={{marginTop: window.innerWidth <= 600 ? "-5px" : "auto"}}>
                  <FormControl fullWidth>
                  {editaddress ? (
                    <>
                    <InputLabel
                      htmlFor="locality"
                      style={{
                        marginLeft: "-14px",
                        marginTop: "-17px",
                        paddingBottom: isSelectFocusedl ? "0" : "2px",
                        marginTop: isSelectFocusedl ? "2px" : "-16px",
                        fontSize: isSelectFocusedl ? "15px" : "12px",
                      }}
                    >
                      Locality
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="locality"
                      id="locality"
                      onFocus={handleSelectFocusl}
                      onBlur={handleSelectBlur}
                      // value={values?.village?.name || editaddress.pincode?.pincode?.village?.name }
                      value={values?.locality}
                      onChange={(e) => {
                        const selectedLocalityCode = e.target.value;
                        const selectedLocality = locality.filter(
                          (l) => l.id === parseInt(selectedLocalityCode)
                        );
                        setFieldValue("locality", selectedLocalityCode);
                        setFieldValue("localityObject", selectedLocality[0]);

                        const selectedVillage = village.filter(
                          (l) => l.id === parseInt(selectedLocalityCode)
                        );
                        setFieldValue("locality", selectedLocalityCode);
                        setFieldValue("localityObject", selectedVillage[0]);
                        console.log("Changed locality",selectedVillage);
                        console.log("Values locality",values?.locality);
                      }}
                      error={touched.locality && Boolean(errors.locality)}
                    >
                      <option value=""></option>
                      {locality.length > 0 ? (
                      locality.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option?.village?.name}
                        </option>
                      ))
                      ) : (
                      village.map((option) => (
                        <option
                          key={option.id}
                          value={option.id}
                        >
                          {option.village?.name}
                        </option>
                      ))
                      )}
                    </Select>
                    </>
                  ) : (
                    <>
                    <InputLabel
                      htmlFor="locality"
                      style={{
                        marginLeft: "-14px",
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                    >
                      Locality
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="localityObject"
                      id="localityObject"
                      // value={values?.village?.name || editaddress.pincode?.pincode?.village?.name }
                      value={values?.localityObject}
                      onChange={(e) => {
                        const selectedLocality = e.target.value;
                        setFieldValue("localityObject", selectedLocality);
                        console.log("Changed locality",e.target.value);
                      }}
                      error={touched.localityObject && Boolean(errors.localityObject)}
                    >
                      <option value=""></option>
                      {village.map((option) => (
                        <option
                          key={option.village?.code}
                          value={JSON.stringify(option)}
                        >
                          {option.village?.name}
                        </option>
                      ))}
                      {locality.map((option) => (
                        <option key={option.id} value={JSON.stringify(option)}>
                          {option?.village?.name}
                        </option>
                      ))}
                    </Select>
                    </>
                  )}
                    <ErrorMessage
                      name="locality"
                      component="div"
                      style={{ color: "red", fontSize: "12px" }}
                    />
                  </FormControl>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3} mt={1}>
                <Grid item xs={12} sm={6}>
                <div style={{marginTop: window.innerWidth <= 600 ? "-10px" : "auto"}}>
                  <FormControl fullWidth>
                    <TextField
                      label="Main Address"
                      type="text"
                      name="mainaddress"
                      id="warrantymonth"
                      value={values.mainaddress}
                      onChange={(e) => {
                        const nameValue = e.target.value;
                        setFieldValue("mainaddress", nameValue);
                      }}
                      variant="standard"
                      error={touched.mainaddress && Boolean(errors.mainaddress)}
                      helperText={touched.mainaddress && errors.mainaddress}
                    />
                  </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                <div style={{marginTop: window.innerWidth <= 600 ? "-5px" : "auto"}}>
                  <FormControl fullWidth>
                    <TextField
                      label="Land Mark"
                      type="text"
                      name="landmark"
                      id="landmark"
                      value={values.landmark}
                      onChange={(e) => {
                        const nameValue = e.target.value;
                        setFieldValue("landmark", nameValue);
                      }}
                      variant="standard"
                      error={touched.landmark && Boolean(errors.landmark)}
                      helperText={touched.landmark && errors.landmark}
                    />
                  </FormControl>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3} mt={1}>
                <Grid item xs={12} sm={6}>
                <div style={{marginTop: window.innerWidth <= 600 ? "-10px" : "auto"}}>
                  <FormControl fullWidth>
                    <TextField
                      label="Alternative Address"
                      type="text"
                      name="alternativeaddress"
                      id="alternativeaddress"
                      value={values.alternativeaddress}
                      onChange={(e) => {
                        const nameValue = e.target.value;
                        setFieldValue("alternativeaddress", nameValue);
                      }}
                      variant="standard"
                      error={
                        touched.alternativeaddress &&
                        Boolean(errors.alternativeaddress)
                      }
                      helperText={
                        touched.alternativeaddress && errors.alternativeaddress
                      }
                    />
                  </FormControl>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} style={{ color: "#ff5733" }}>
                Cancel
              </Button>
              <Button
                type="submit"
                style={{ backgroundColor: "#ff5733", color: "#fff" }}
              >
                {iscreatloading ? (
                  <MoonLoader color="#f2fefa" size={16} />
                ) : editaddress ? (
                  "Update"
                ) : (
                  "Submit"
                )}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Addresspopup;
