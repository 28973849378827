// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components

import FormField from "layouts/pages/patientsignup/new-patient/components/FormField";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";

function PatientInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  

  const { formField, values, errors, touched } = formData;

  const {
    firstName,
    lastName,
    email,
    phonenumber,
    username,
    
    password,
    repeatPassword,
    
  } = formField;
  var {
    firstName:firstNameV,
    lastName:lastNameV,
    email: emailV,
    phonenumber: phonenumberV,
    username: usernameV,
    age:ageV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
  } = values;




  
  return (
    <MDBox>
      
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
        <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={firstNameV}
              placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
              <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lastNameV}
              placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              //success={lastNameV.length > 0 && !errors.lastName}
            />
         
        <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
           
           
           <FormField
              type={phonenumber.type}
              label={phonenumber.label}
              name={phonenumber.name}
              value={phonenumberV}
              placeholder={phonenumber.placeholder}
              error={errors.phonenumber && touched.phonenumber}
              success={phonenumberV.length > 0 && !errors.phonenumber}
            />
           
         
         
              <FormField
                type={username.type}
                label={username.label}
                name={username.name}
                value={usernameV}
                placeholder={username.placeholder}
                error={errors.username && touched.username}
                success={usernameV.length > 0 && !errors.username}
              />
          
           
     
         
      
        
              <FormField
                type={password.type}
                label={password.label}
                name={password.name}
                value={passwordV}
                placeholder={password.placeholder}
                error={errors.password && touched.password}
                success={passwordV.length > 0 && !errors.password}
                inputProps={{ autoComplete: "" }}
              />
           
           
              <FormField
                type={repeatPassword.type}
                label={repeatPassword.label}
                name={repeatPassword.name}
                value={repeatPasswordV}
                placeholder={repeatPassword.placeholder}
                error={errors.repeatPassword && touched.repeatPassword}
                success={repeatPasswordV.length > 0 && !errors.repeatPassword}
                inputProps={{ autoComplete: "" }}
              />
          
         
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for PatientInfo
PatientInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PatientInfo;
