// JobDetail.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import { BarLoader } from "react-spinners";
import { useFormikContext } from "formik";

import FormField from "../FormField";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

function JobDetail({ formData }) {
  const [loading, setLoading] = useState(false);
  const { setFieldValue, setFieldError } = useFormikContext(); // Access Formik context

  const [color] = useState("#344767");

  const { formField, values, errors, touched } = formData;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    // Validation logic...
  };

  const { serialNumber } = formField;

  const {
    callby: callbyV,
    purchasesource: purchasesourceV,
    callsource: callsourceV,
    callnature: callnatureV,
    purchasesubsource: purchasesubsourceV,
    callreason: callreasonV,
  } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Job Detail</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.callby && touched.callby}>
                <InputLabel htmlFor="call" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Call By <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}>*</sup></InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="callby"
                  id="callby"
                  value={callbyV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value=""></option>
                  <option value="Customer">Customer</option>
                  <option value="Dealer">Dealer</option>
                  <option value="HCC">HCC</option>
                  <option value="Branch">Branch</option>
                  <option value="Employee">Employee</option>
                </Select>
                {errors.callby && touched.callby && (
                  <FormHelperText error style={{marginLeft:"0px"}}>{errors.callby}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="callsource" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Call Source</InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="callsource"
                  id="callsource"
                  value={callsourceV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value=""></option>
                  <option value="Phone">Phone</option>
                  <option value="SMS">SMS</option>
                  <option value="Social Media">Social Media</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} sm={6}>
            <div style={{marginTop: window.innerWidth <= 700 ? "-8px" : "auto"}}>
              <FormControl fullWidth>
                <InputLabel htmlFor="product" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Call Nature</InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="callnature"
                  id="callnature"
                  value={callnatureV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value=""></option>
                  <option value="IN Bound">In Bound</option>
                  <option value="Out Bound">Out Bound</option>
                </Select>
              </FormControl>
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.purchasesource && touched.purchasesource}>
                <InputLabel htmlFor="purchasesource" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Purchase Source <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}>*</sup></InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="purchasesource"
                  id="purchasesource"
                  value={purchasesourceV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value=""></option>
                  <option value="Dealer">Dealer</option>
                  <option value="Online">Online</option>
                </Select>
                {errors.purchasesource && touched.purchasesource && (
                  <FormHelperText error style={{marginLeft:"0px"}}>{errors.purchasesource}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
         
            <Grid container spacing={3} mt={1}>
            {purchasesourceV === "Online" && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.purchasesubsource && touched.purchasesubsource}>
                  <InputLabel htmlFor="purchasesubsource" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Purchase Sub Source <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}>*</sup></InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="purchasesubsource"
                    id="purchasesubsource"
                    value={purchasesubsourceV}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value=""></option>
                    <option value="Amazon">Amazon</option>
                    <option value="Flipkart">Flipkart</option>
                  </Select>
                  {errors.purchasesubsource && touched.purchasesubsource && (
                    <FormHelperText error style={{marginLeft:"0px"}}>{errors.purchasesubsource}</FormHelperText>
                  )}
                </FormControl>
              </Grid> )}
             
            <Grid item xs={12} sm={6} mb={5}>
              <FormControl fullWidth>
                <InputLabel htmlFor="callreason" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Call Reason</InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="callreason"
                  id="callreason"
                  value={callreasonV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value=""></option>
                  <option value="Technical">Technical</option>
                  <option value="Non Technical">Non Technical</option>
                </Select>
              </FormControl>
            </Grid>
        
            </Grid>
         
         
        </MDBox>
      )}
    </MDBox>
  );
}

JobDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default JobDetail;
