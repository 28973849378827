/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "qualification-form",
  formField: {
    qualificationName: {
      name: "qualificationName",
      label: "Qualification Name",
      type: "text",
      errorMsg: "Qualification name is required.",
    },
    mode: {
      name: "mode",
      label: "Mode",
      type: "text",
      errorMsg: "Mode is required.",
    },
    startDate: {
      name: "startDate",
       
      type: "date",
      errorMsg: "Start Date is required.",
    },
    board: {
      name: "board",
      label: "Board",
      type: "text",
      errorMsg: "Board is required.",
    },
    
    type: {
      name: "type",
      label: " Type",  
      type: "text",
      errorMsg: "Type is required.",
    },
    grade: {
      name: "grade",
      label: "Grade",  
      type: "text",
      errorMsg: "Grade is required.",
    },
    
    endDate: {
      name: "endDate",
      label: "End Date",  
      type: "date",
      errorMsg: "End Date is required.",
    },
  },
};

export default form;