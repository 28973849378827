/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-patient-form",
  formField: {
    firstName: {
      name: "firstName",
      label: "First Name",
      type: "text",
      errorMsg: "First name is required.",
    },
    lastName: {
      name: "lastName",
      label: "Last Name",
      type: "text",
      errorMsg: "Last name is required.",
    },
    username: {
      name: "username",
      label: "Username",
      type: "text",
      errorMsg: "Username is required.",
    },
    age: {
      name: "age",
      label: "Age",
      type: "number",
      errorMsg: "Age is required.",
    },
    department: {
      name: "department",
      label: "Department",
      type: "text",
      errorMsg: "Department is required.",
    },

    designation: {
      name: "designation",
      label: "Designation",
      type: "text",
      errorMsg: "Designation is required.",
    },
    email: {
      name: "email",
      label: "Email Address",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 6 characters.",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "Repeat Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password doesn't match.",
    },
  
    phonenumber: {
      name: "phonenumber",
      label: "Phonenumber",
      type: "number",
      errorMsg: "Phone Number is required.",
    },
   
    twitter: {
      name: "twitter",
      label: "Twitter Handle",
      type: "text",
      errorMsg: "Twitter profile is required.",
    },
    facebook: {
      name: "facebook",
      label: "Facebook Account",
      type: "text",
    },
    instagram: {
      name: "instagram",
      label: "Instagram Account",
      type: "text",
    },
    publicEmail: {
      name: "publicEmail",
      label: "Public Email",
      type: "email",
    },
    bio: {
      name: "bio",
      label: "Bio",
    },
    state: {
      name: "state",
      label: "State",
      type: "text",
      errorMsg: "State is required.",
    },
    district: {
      name: "district",
      label: "District",
      type: "text",
      errorMsg: "District is required.",
    },
    subDistrict: {
      name: "subDistrict",
      label: "Sub-District",
      type: "text",
      errorMsg: "Sub-District is required.",
    },

    pincode: {
      name: "pincode",
      label: "Pincode",
      type: "number",
      errorMsg: "Pincode is required.",
    },
  },
};

export default form;
