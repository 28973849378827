import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { Grid, TextField } from "@mui/material";
import { updateModal } from "./service/Modal";

const validationSchema = Yup.object().shape({
  brand: Yup.string().required("Brand is required"),
  product: Yup.string().required("Product is required"),
  extendedwarranty: Yup.string().required("Extended warranty is required"),
  warrantyapplicable: Yup.string().required("Warranty applicable is required"),
  name: Yup.string().required("Name is required"),
  warrantymonth: Yup.string().when('warrantyapplicable', {
    is: 'true',
    then: Yup.string().required('Warranty Month is required'),
    otherwise: Yup.string().notRequired(),
  }),
});

const Modaledit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedModal, openpopup, setopenpopup } = props;
  const { brand } = useSelector((state) => state.brand);
  const { product } = useSelector((state) => state.product);
  const [isWarrantyApplicable, setIsWarrantyApplicable] = useState(false);
  useEffect(() => {
    // Set the initial state based on selectedModal
    setIsWarrantyApplicable(selectedModal?.iswarranty);
  }, [selectedModal]);

  const [defaultValues, setDefaultValues] = useState({
    product: "",
    brand: "",
    extendedwarranty: "",
    name: "",
    warrantyapplicable: "",
    warrantymonth: "",
    description: "",
  });
  console.log("is warranty:", selectedModal?.iswarranty);
console.log("is extended warranty:", selectedModal?.isextendedwarranty);

  const fetchProductOptions = async (selectedBrand) => {
    // Filter products based on selectedBrand
    const result = product.filter(
      (productItem) => productItem.parenthierarchicalcode === selectedBrand
    );

    // Set the product options based on the result
    setProductlist(result);
  };

  const handleClose = () => {
    setopenpopup(false);
    setIsWarrantyApplicable(false)
  };

  useEffect(() => {
    async function getproductdefaultvalue() {
      if (selectedModal) {
        const productHericalcode = selectedModal?.parenthierarchicalcode;
        const brandHericalcode = product.find(
          (p) => p.hierarchicalcode === productHericalcode
        )?.parenthierarchicalcode;

        const defaultProductList = product.filter(
          (p) => p.parenthierarchicalcode === brandHericalcode
        );

        setDefaultValues({
          product: productHericalcode,
          brand: brandHericalcode,
          extendedwarranty: selectedModal?.isextendedwarranty,
          name: selectedModal?.name || "",
          warrantyapplicable: selectedModal?.iswarranty,
          warrantymonth: selectedModal?.warrantyinmonth,
          description: selectedModal?.desc || "",
        });

        setProductlist(defaultProductList);
      }
    }

    getproductdefaultvalue();
  }, [selectedModal, product]);

  const [productlist, setProductlist] = useState([]);

  return (
    <Dialog open={openpopup} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "#fff",
          textAlign: "center",
        }}
      >{title}</DialogTitle>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={(values, action) => {
          // Handle form submission here
          const requestData = {
            ...selectedModal,
            desc: values.description,
            name: values.name,
            brand:values.brand,
            iswarranty: values.warrantyapplicable,
            parenthierarchicalcode: values.product,
            isextendedwarranty: values.extendedwarranty,
            warrantyinmonth: values.warrantymonth,
            
          };
          dispatch(updateModal(requestData));
        handleClose()
          action.resetForm();
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <DialogContent style={{ maxwidth: 550, height: 360 }}>
              <MDBox p={3}>
                <MDBox>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="brand" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"8px" }}>Select Brand</InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="brand"
                          id="brand"
                          value={values.brand}
                          onChange={(e) => {
                            const selectedBrand = e.target.value;
                            setFieldValue("brand", selectedBrand);
                            fetchProductOptions(selectedBrand);
                          }}
                          error={touched.brand && Boolean(errors.brand)}
                        >
                          <option value=""></option>
                          {brand.map((option) => (
                            <option
                              key={option.id}
                              value={option.hierarchicalcode}
                            >
                              {option.name}
                            </option>
                          ))}
                        </Select>
                        <ErrorMessage
                          name="brand"
                          component="div"
                          style={{ color: "red", fontSize: "12px" }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="product" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"8px" }}>Select Product</InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="product"
                          id="product"
                          value={values.product}
                          onChange={(e) => {
                            const selectedProduct = e.target.value;
                            setFieldValue("product", selectedProduct);
                            console.log("onchangevalue",e.target.value);
                          }}
                          error={touched.product && Boolean(errors.product)}
                        >
                          <option value=""></option>
                          {productlist.map((option) => (
                            <option
                              key={option.id}
                              value={option.hierarchicalcode}
                            >
                              {option.name}
                            </option>
                          ))}
                        </Select>
                        <ErrorMessage
                          name="product"
                          component="div"
                          style={{ color: "red", fontSize: "12px" }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Model Name"
                          name="name"
                          id="name"
                          value={values.name}
                          onChange={(e) => {
                            const nameValue = e.target.value;
                            setFieldValue("name", nameValue);
                          }}
                          variant="standard"
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Description"
                          name="description"
                          id="description"
                          value={values.description}
                          onChange={(e) => {
                            const descriptionValue = e.target.value;
                            setFieldValue("description", descriptionValue);
                          }}
                          variant="standard"
                          error={touched.description && Boolean(errors.description)}
                          helperText={touched.description && errors.description}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} mt={1}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="extendedwarranty" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"8px" }}>
                          Is Extended Warranty Applicable
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="extendedwarranty"
                          id="extendedwarranty"
                          value={values.extendedwarranty}
                          onChange={(e) => {
                            const selectedextendedwarranty = e.target.value;
                            setFieldValue("extendedwarranty", selectedextendedwarranty);
                          }}
                          error={touched.extendedwarranty && Boolean(errors.extendedwarranty)}
                        >
                          <option value=""></option>
                          <option value="true">true</option>
                          <option value="false">false</option>
                        </Select>
                        <ErrorMessage
                          name="extendedwarranty"
                          component="div"
                          style={{ color: "red", fontSize: "12px" }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="warrantyapplicable" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"8px" }}>
                          Is Warranty Applicable
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="warrantyapplicable"
                          id="warrantyapplicable"
                          value={values.warrantyapplicable}
                          onChange={(e) => {
                            const selectedwarrantyapplicable = e.target.value;
                            setFieldValue("warrantyapplicable", selectedwarrantyapplicable);
                            setIsWarrantyApplicable(selectedwarrantyapplicable === "true");
                          }}
                          error={touched.warrantyapplicable && Boolean(errors.warrantyapplicable)}
                        >
                          <option value=""></option>
                          <option value="true">true</option>
                          <option value="false">false</option>
                        </Select>
                        <ErrorMessage
                          name="warrantyapplicable"
                          component="div"
                          style={{ color: "red", fontSize: "12px" }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} mt={1}>
                  {isWarrantyApplicable && (
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Warranty in month"
                          type="number"
                          name="warrantymonth"
                          id="warrantymonth"
                          value={values.warrantymonth}
                          onChange={(e) => {
                            const nameValue = e.target.value;
                            setFieldValue("warrantymonth", nameValue);
                          }}
                          variant="standard"
                          error={touched.warrantymonth && Boolean(errors.warrantymonth)}
                          helperText={touched.warrantymonth && errors.warrantymonth}
                        />
                      </FormControl>
                    </Grid>)}
                  </Grid>
                   
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Update</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Modaledit;
