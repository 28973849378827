// import React, { useState, useEffect, useRef } from "react";
// import { useForm } from "react-cool-form";
// import MDInput from "components/MDInput";
// import { useNavigate } from "react-router-dom";
// import Card from "@mui/material/Card";
// import BasicLayout from "layouts/authentication/components/BasicLayout";
// import CoverLayout from "layouts/authentication/components/CoverLayout";

// // Images
// import bgImage from "assets/images/bg-pricing.jpg";

// import MDTypography from "components/MDTypography";
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import { logIn } from "../../../../redux/Actions/authActions"
// import { useDispatch, useSelector } from "react-redux"
// import lottie from "lottie-web";
// //navigate to other screen=============
// import { Link } from "react-router-dom";

// import Grid from "@mui/material/Grid";
// export default function Basic() {

//   const navigate = useNavigate();
//   const dispatch = useDispatch()
//   const container = useRef(null);
//   const { isAuthenticated } = useSelector((({ AuthReducer }) => AuthReducer))
//   console.log("isAuthenticated",isAuthenticated)
//  // const [rolecode, setRolecode] = useState(JSON.parse(localStorage.getItem("login-auth")).rolecode)

//   const { form, use } = useForm({

//     defaultValues: { email: "", password: "", resourcecode: "JAINA" },

//     onSubmit: (values) => loginUser(values)

//   });


//   const errors = use("errors");


//   const loginUser = async (data) => {
//     const getData = {
//       username: data.email,
//       password: data.password,
//       resourcecode: "JAINA"

//     };

//     dispatch(logIn(getData))
//   };

//   useEffect(() => {
//     if (isAuthenticated) {
      
//       navigate('/pages/profile/profile-overview')
//      }
//   }, [isAuthenticated])

//   useEffect(() => {
//     lottie.loadAnimation({
//       container: container.current,
//       renderer: "svg",
//       autoplay: true,

//       animationData: require("./gear.json"),
//     });
//   }, []);


//   return (
//     <CoverLayout image={bgImage}>
//       <Card>
//         <MDBox
//           variant="gradient"
//           bgColor="info"
//           borderRadius="lg"
//           coloredShadow="success"
//           mx={2}
//           mt={-3}
//           p={3}
//           mb={1}
//           textAlign="center"
//         >
//           <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
//             Sign in
//           </MDTypography>
//           <MDTypography display="block" variant="button" color="white" my={1}>
//             Enter your mpin and password to Sign In
//           </MDTypography>
//         </MDBox>




//         <form ref={form} noValidate>

//           <MDBox pt={4} pb={3} px={3}>

//             <MDBox>

//               <MDBox mb={2}>

//                 <MDInput name="email" placeholder="Enter Mobile" required fullWidth />
//                 {errors.email && <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.email}</p>}
//               </MDBox>



//               <MDBox mb={2}>

//                 <MDInput name="password" type="password" placeholder="Enter Mpin" required fullWidth />
//                 {errors.password && <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.password}</p>}
//               </MDBox>
//               <MDTypography variant="button" color="text">
//                <MDTypography
//                   component={Link}
//                   to="/app/ForgotPassword/forgot-password"
//                   variant="button"
//                   color="info"
//                   fontWeight="medium"
//                   textGradient
//                 >
//                 ForgotPassword?
//                 </MDTypography>
//               </MDTypography>

//               <MDBox mb={1}>
//                 <MDButton variant="gradient" color="info" fullWidth type="submit">
//                   Log In
//                 </MDButton>
//               </MDBox>
//             </MDBox>
//           </MDBox>

//         </form>
       
//         <MDBox mt={3} mb={1} textAlign="center">
//               <MDTypography variant="button" color="text">
//                 Don&apos;t have an account?{" "}
//                 <MDTypography
//                   component={Link}
//                   to="/app/newregistration/registration"
//                   variant="button"
//                   color="info"
//                   fontWeight="medium"
//                   textGradient
//                 >
//                   Sign up
//                 </MDTypography>
//               </MDTypography>
//             </MDBox>



//       </Card>
     

//     </CoverLayout>
//   );
// }


import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-cool-form";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-pricing.jpg";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { logIn } from "../../../../redux/Actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import lottie from "lottie-web";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { MoonLoader } from "react-spinners";
export default function Basic() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const container = useRef(null);
  const { isAuthenticated } = useSelector(({ AuthReducer }) => AuthReducer);

  const { form, use } = useForm({
    defaultValues: { email: "", password: "", resourcecode: "JAINA" },
    onSubmit: (values) => loginUser(values),
  });

  const errors = use("errors");
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const loginUser = async (data) => {
    setIsLoading(true); // Set loading state to true
    const getData = {
      username: data.email,
      password: data.password,
      resourcecode: "JAINA",
    };

    dispatch(logIn(getData)).then(() => {
      setIsLoading(false); // Set loading state back to false when authentication is finished
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
     
      navigate("/pages/profile/profile-overview");
      window.location.reload();
  

    }
  }, [isAuthenticated]);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      autoplay: true,
      animationData: require("./gear.json"),
    });
  }, []);

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your mpin and password to Sign In
          </MDTypography>
        </MDBox>

        <form ref={form} noValidate>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox mb={2}>
              <MDInput name="email" placeholder="Enter Mobile" required fullWidth />
              {errors.email && (
                <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>
                  {errors.email}
                </p>
              )}
            </MDBox>

            <MDBox mb={2}>
              <MDInput name="password" type="password" placeholder="Enter Mpin" required fullWidth />
              {errors.password && (
                <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>
                  {errors.password}
                </p>
              )}
            </MDBox>
            <MDBox mb={2} mt={5}>
             

              <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={isLoading}>
                          {isLoading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Log In"
                          )}
                        </MDButton>
            </MDBox>
            <MDTypography variant="button" color="text" style={{display:'flex',justifyContent:"center",alignItems:"center"}}>
              <MDTypography
                component={Link}
                to="/app/ForgotPassword/forgot-password"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                ForgotPassword?
              </MDTypography>
            </MDTypography>
          </MDBox>
        </form>

        <MDBox mt={2} mb={1} textAlign="center">
          <MDTypography variant="button" color="text">
            Don&apos;t have an account?{" "}
            <MDTypography
              component={Link}
              to="/app/newregistration/registration"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </MDTypography>
          </MDTypography>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}



