import axios from "axios";
 
 
 const instance = axios.create({
   baseURL: 'https://sm.softwaremathematics.com/',
    
 });

 instance.defaults.headers.common['X-Tenant']='JAT20240418';
 
 export default instance;

 