

// import React from 'react'
// // @mui material components
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// // Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
// import CoverLayout from "layouts/authentication/components/CoverLayout";

// //image
// import bgImage from "assets/images/otp.jpeg";
// import { Link } from "react-router-dom";
// import { useFormik } from 'formik';
// import { otpschemas } from './Schemas'
// const initialValues = {
//   otp: "",
// };
// function otp() {
//   const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
//     initialValues: initialValues,
//     validationSchema: otpschemas,
//     onSubmit: (values) =>
//       console.log("23", values)
//   })
  
  // const [counter, setCounter] = React.useState(20);
  // React.useEffect(() => {
  //   const timer =
  //     counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  //   return () => clearInterval(timer);
  // }, [counter]);
//   function Reload() {

//     alert("otp will be send on your updated mobile num")
//     setCounter(30);
   


//   }
//   return (

//     <CoverLayout image={bgImage}>
//       <MDBox py={3} mb={20} height="65vh">
//         <Grid
//           container
//           justifyContent="center"
//           alignItems="center"
//           sx={{ height: "100%", mt: 8 }}
//         >
//           <Card sx={{ height: "80%", marginTop: "-130px" }}>
//             <MDBox mx={2} mt={-3}>
//               <MDBox
//                 variant="gradient"
//                 bgColor="info"
//                 borderRadius="lg"
//                 coloredShadow="success"
//                 mx={2}
//                 mt={-1}
//                 p={3}
//                 mb={6}
//                 textAlign="center"
//               >
//                 <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
//                   OTP
//                 </MDTypography>
//                 <MDTypography display="block" variant="button" color="white" my={1}>
//                   Enter your OTP to register
//                 </MDTypography>

//               </MDBox>

//               <form onSubmit={handleSubmit}>
//                 <h5 style={{ marginTop: "-12px" }}>Please Enter the verification code </h5>
//                 <MDInput
//                   name="otp"
//                   type="number"
//                   label="Please Enter Valid Otp"
//                   fullWidth
//                   autocomplete="off"
//                   id="otp"
//                   required
//                   style={{ marginTop: "7px" }}
//                   value={values.otp}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                 />
//                 {errors.otp && <p style={{ fontWeight: "400px", color: "red", fontSize: "12px" }}>{errors.otp}</p>}
//                 <MDBox mt={2}>
//                   <MDButton variant="gradient" color="info" fullWidth type="submit">
//                     Verify
//                   </MDButton>
//                 </MDBox>
//               </form>
              // <MDBox mx={9} >
              //   <MDTypography

              //     variant="button"
              //     color="error"
              //     fontWeight="medium"
              //     textGradient
              //   >
              //     Resend code in <span>00:{counter}</span>
              //   </MDTypography>
              // </MDBox>
              // <MDBox mx={14} >
              //   <Link to={{}}>
              //     <MDTypography
              //       onClick={Reload}
                    
              //       variant="button"
              //       color="info"
              //       fontWeight="medium"
              //       textGradient
              //     >
              //       Resend Otp
              //     </MDTypography>
              //   </Link>





              // </MDBox>
//             </MDBox>
//           </Card>
//         </Grid>
//       </MDBox>
//     </CoverLayout>
//   )
// }

// export default otp





import { useState } from "react";
import {Link, useNavigate } from "react-router-dom";
// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import swal from 'sweetalert';

// import Socials from "layouts/pages/doctor/new-doctor/components/Socials";
// import Profile from "layouts/pages/doctor/new-doctor/components/Profile";
import MDTypography from "components/MDTypography";

// NewUser layout schemas for form and form feilds

import validations from "components/Resuable/components/otp/new-otp/schemas/validations";
import form from "components/Resuable/components/otp/new-otp/schemas/form";
import initialValues from "components/Resuable/components/otp/new-otp/schemas/initialValues";
import CoverLayout from "layouts/authentication/components/CoverLayout";
// image
import bgImage from "assets/images/otp.jpeg";
import OtpInfo from "components/Resuable/components/otp/new-otp/components/OtpInfo";
// import axios from "../../../../axiosinstance"


function getSteps() {
  return ["Otp Info"];
}

function getStepContent(stepIndex, formData) {

  
  switch (stepIndex) {
    case 0:
      return <OtpInfo formData={formData} />;

    default:
      return null;
  }
}

function otp() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    navigate('/authentication/sign-in/basic');
    console.log("hii", values);
  //   const formData = {
  //     createdby:"HEALTHCARE WEB UI",
     
  //   createddate: null,
  //   documentofproof: "",
  //   emailid: values.email,
    
  // groupcode: "MMU",
  //   id: null,
   
  //   latlong: "",
  //   password: values.password,
  //   phoneno: values.phonenumber,
  //   recstatus: "APPROVED",
  //   resetflag: null,
  //   resourcecode: "HEALTHCARE",
   

  //  rolecode: "DO001",
  //  rolename:"DOCTOR",
  //   status: "ACTIVE",
  //   syncstatus: "synced",
  //   token: "",
  //   username: values.username,
  //   };
    // console.log("payload", formData);
    //     await axios
    //     .post(
    //       "profile-service-mongo/api/ProfileEntity/v2/create",
    //       formData
    //     )
  
    //     .then((response) => {
    //       swal("Good job!", "your registration has been done!", "success");
    //       navigate('/authentication/sign-in/basic');
    //     }
    //       )
    //     .catch((error) => {
    //       console.log(error);
    //     });

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    console.log(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <CoverLayout mt={20} image={bgImage}>
    
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: -1}}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                <Card sx={{ height: "80%",width:"400px", marginTop: "50px",marginLeft:"-30px" }}>
                    <MDBox mx={2} mt={-3}>
                    <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-1}
        p={3}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
         Otp
        </MDTypography>
        <MDTypography display="block" variant="button" color="white" my={1}>
          Enter your OTP to register
        </MDTypography>
      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            fullwidth
                            variant="gradient"
                            color="info"
                            onClick={() => {
                              navigate('/pages/otp/new-otp')
                              console.log("submit");
                            }}
                          >
                           Verify
                          </MDButton>
                        </MDBox>
                       

                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </CoverLayout>
  );
}

 export default otp;



