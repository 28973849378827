/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import checkout from "layouts/pages/AddressInformation/new-address/schemas/form";
const {
  formField: {
    firstline,
    secondline,
   country,
    state,
    district,
    subdistrict,
    pincode,
    village
   
  },
} = checkout;

const initialValues = {
  [firstline.name]: "",
   [secondline.name]: "",
   [country.name]: "",
 
  [state.name]: "",
  [district.name]: "",
  [subdistrict.name]: "",
  [pincode.name]: "",
  [village.name]: "",
  

};

export default initialValues;
