import * as Yup from "yup";
import checkout from "layouts/pages/AddressInformation/new-address/schemas/form";

const {
  formField: {
    firstline,
     secondline,
     country,
     state,
     district,
     subdistrict,
     village,
     pincode,
   
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstline.name]: Yup.string().required(firstline.errorMsg),
     [secondline.name]: Yup.string().required(secondline.errorMsg),
     [country.name]: Yup.string().required(country.errorMsg),
     [state.name]: Yup.string().required(state.errorMsg),
     [district.name]: Yup.string().required(district.errorMsg),
     [subdistrict.name]: Yup.string().required(subdistrict.errorMsg),
     [village.name]: Yup.string().required(village.errorMsg),
     [pincode.name]: Yup.string().required(pincode.errorMsg),
   
   
  }),
 
];

export default validations;
